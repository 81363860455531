import {
  AfterViewInit,
  Component,
  Inject,
  NgZone,
  OnChanges,
  OnDestroy,
  OnInit,
  PLATFORM_ID,
  SimpleChanges,
} from '@angular/core';
import { MenuItem } from 'primeng/api';

// amCharts imports
import * as am5 from '@amcharts/amcharts5';
import * as am5percent from '@amcharts/amcharts5/percent';
import am5themesAnimated from '@amcharts/amcharts5/themes/Animated';
import am5themesMaterial from '@amcharts/amcharts5/themes/Kelly';
import { isPlatformBrowser } from '@angular/common';
import { Select } from '@ngxs/store';
import { Observable } from 'rxjs';
import { RootState } from 'src/app/store/root.state';

@Component({
  selector: 'tausi-online-offline-chart',
  templateUrl: './online-offline-chart.component.html',
  styleUrls: ['./online-offline-chart.component.scss'],
})
export class OnlineOfflineChartComponent
  implements AfterViewInit, OnInit, OnChanges, OnDestroy
{
  // @Input() public data!: any[];

  @Select(RootState.onlineOffline)
  public onlineOffline$!: Observable<any>;

  public onlineOffline: any;
  private root!: am5.Root;
  public items!: MenuItem[];

  constructor(
    @Inject(PLATFORM_ID) private _platformId: Object,
    private _zone: NgZone
  ) {
    this.onlineOffline$.subscribe((value) => {
      this.onlineOffline = value;
    });
  }

  browserOnly(f: () => void) {
    if (isPlatformBrowser(this._platformId)) {
      this._zone.runOutsideAngular(() => {
        f();
      });
    }
  }

  ngOnInit(): void {}

  ngAfterViewInit() {
    this.browserOnly(() => {
      this.chartRenderer(this.onlineOffline);
    });
  }

  ngOnDestroy() {
    this.browserOnly(() => {
      if (this.root) {
        this.root.dispose();
      }
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.data.currentValue) {
      this.chartRenderer(changes.data.currentValue);
    }
  }

  disposeRoot(divId: any) {
    am5.array.each(am5.registry.rootElements, (root) => {
      if (root.dom.id === divId) {
        root.dispose();
      }
    });
  }

  chartRenderer(data: any) {
    let root = am5.Root.new('onlineoffline');
    root.setThemes([am5themesAnimated.new(root), am5themesMaterial.new(root)]);
    let chart = root.container.children.push(
      am5percent.PieChart.new(root, {
        tooltip: am5.Tooltip.new(root, {
          labelText: '{description}: {value}',
        }),
        innerRadius: am5.percent(50),
        layout: root.verticalLayout,
      })
    );

    let series = chart.series.push(
      am5percent.PieSeries.new(root, {
        name: 'Series',
        categoryField: 'description',
        valueField: 'number',
        alignLabels: false,
      })
    );

    series.labels.template.setAll({
      textType: 'circular',
      centerX: 0,
      centerY: 0,
      fontFamily: 'Inter',
      fontSize: '1.0em',
    });

    series.ticks.template.set('forceHidden', true);
    series.slices.template.setAll({
      draggable: false,
      cornerRadius: 5,
    });
    series.data.setAll(data);

    let legend = chart.children.push(
      am5.Legend.new(root, {
        centerX: am5.percent(50),
        x: am5.percent(50),
        marginTop: 15,
        marginBottom: 15,
        layout: root.horizontalLayout,
      })
    );

    legend.data.setAll(series.dataItems);
    legend.labels.template.setAll({
      fontFamily: 'Inter',
      fontSize: '1em',
      marginLeft: 10,
    });

    legend.valueLabels.template.setAll({ fontSize: '1em' });

    legend.markers.template.setAll({
      width: 15,
      height: 15,
    });

    this.root = root;
  }
}
