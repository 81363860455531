import {
  Component,
  Inject,
  Input,
  NgZone,
  OnChanges,
  OnDestroy,
  OnInit,
  PLATFORM_ID,
} from '@angular/core';
import { MenuItem } from 'primeng/api';

// amCharts imports
import * as am5 from '@amcharts/amcharts5';
import am5themesAnimated from '@amcharts/amcharts5/themes/Animated';
import * as am5xy from '@amcharts/amcharts5/xy';
import { isPlatformBrowser } from '@angular/common';
import CustomTheme from '../utils/chart.themes';

@Component({
  selector: 'tausi-line-chart',
  templateUrl: './line-chart.component.html',
  styleUrls: ['./line-chart.component.scss'],
})
export class LineChartComponent implements OnInit, OnDestroy, OnChanges {
  private root!: am5.Root;
  public items!: MenuItem[];

  @Input() data: any;
  @Input() title: any;
  @Input() height: string = '360px';

  constructor(
    @Inject(PLATFORM_ID) private _platformId: Object,
    private _zone: NgZone
  ) {}

  browserOnly(f: () => void) {
    if (isPlatformBrowser(this._platformId)) {
      this._zone.runOutsideAngular(() => {
        f();
      });
    }
  }

  ngOnInit(): void {}

  ngOnChanges() {
    this.browserOnly(() => {
      if (this.root) {
        this.root.dispose();
      }
      this.chartRenderer();
    });
  }

  ngOnDestroy() {
    this.browserOnly(() => {
      if (this.root) {
        this.root.dispose();
      }
    });
  }

  chartRenderer(): void {
    let data = this.data.data;
    let root = am5.Root.new('linechart');
    root.setThemes([am5themesAnimated.new(root), CustomTheme.new(root)]);
    let chart = root.container.children.push(
      am5xy.XYChart.new(root, {
        panY: false,
        wheelY: 'zoomX',
        layout: root.verticalLayout,
        maxTooltipDistance: 0,
      })
    );

    let yAxis = chart.yAxes.push(
      am5xy.ValueAxis.new(root, {
        extraTooltipPrecision: 1,
        renderer: am5xy.AxisRendererY.new(root, {}),
      })
    );

    // Create X-Axis
    let xAxis = chart.xAxes.push(
      am5xy.DateAxis.new(root, {
        baseInterval: { timeUnit: this.data.timeUnit, count: 1 },
        renderer: am5xy.AxisRendererX.new(root, {
          minGridDistance: 10,
        }),
      })
    );

    this.createSeries(
      chart,
      root,
      this.data.title,
      'value',
      data,
      xAxis,
      yAxis
    );

    // Add cursor
    chart.set(
      'cursor',
      am5xy.XYCursor.new(root, {
        behavior: 'zoomXY',
        xAxis: xAxis,
      })
    );

    xAxis.set(
      'tooltip',
      am5.Tooltip.new(root, {
        themeTags: ['axis'],
      })
    );

    yAxis.set(
      'tooltip',
      am5.Tooltip.new(root, {
        themeTags: ['axis'],
      })
    );

    this.root = root;
  }

  // Create series
  createSeries(
    chart: any,
    root: any,
    name: any,
    field: any,
    data: any,
    xAxis: any,
    yAxis: any
  ) {
    let series = chart.series.push(
      am5xy.SmoothedXLineSeries.new(root, {
        name: name,
        xAxis: xAxis,
        yAxis: yAxis,
        valueYField: field,
        valueXField: 'date',
        tooltip: am5.Tooltip.new(root, {}),
        location: 0,
        calculateAggregates: true,
        legendLabelText: '[{stroke}]{name}[/]: [bold #888]{categoryX}[/]',
        legendRangeLabelText: '[{stroke}]{name}[/]',
        legendValueText: '[bold {stroke}]{valueY}[/]',
        legendRangeValueText: '[{stroke}]{valueYClose}[/]',
      })
    );

    let legend = chart.children.push(am5.Legend.new(root, {}));
    legend.data.setAll(chart.series.values);

    series
      .get('tooltip')
      .label.set('text', '[bold]{name}[/]\n{valueX.formatDate()}: {valueY}');
    series.data.setAll(data);
  }
}
