import 'moment-duration-format';

import * as moment from 'moment';

import { ChangeDetectorRef, Component } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  FormGroupDirective,
  NgForm,
  Validators,
} from '@angular/forms';
import { OnlineStatusService, OnlineStatusType } from 'ngx-online-status';
import { BehaviorSubject, Subscription } from 'rxjs';
import {
  LockScreenStorage,
  TausiHttpError,
} from './shared/models/auth.interface';

import { HttpErrorResponse } from '@angular/common/http';
import { ErrorStateMatcher } from '@angular/material/core';
import { Router } from '@angular/router';
import { NgxPermissionsService } from 'ngx-permissions';
import { PrimeNGConfig } from 'primeng/api';
import { environment } from 'src/environments/environment';
import { AuthService } from './shared/services/auth.service';
import { TausiMessagingService } from './shared/services/tausi-messaging.service';
import { ToastService } from './shared/services/toast.service';
import { TranslateConfigService } from './shared/services/translate.service';
import { UserIdleService } from './shared/services/user-idle.service';
import { UserStorageService } from './shared/services/user-storage.service';
import { PasswordPolicy } from './user-management/models/password-and-lockout-policy.interface';
import { PasswordAndLockoutPolicyService } from './user-management/services/password-and-lockout-policy.service';
import { UserUaaService } from './user-management/services/user-uaa.service';
import { CustomValidators } from './user-management/validator/custom-validators';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';

export class OauthChangePassErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(
    control: FormControl | null,
    form: FormGroupDirective | NgForm | null
  ): boolean {
    const invalidCtrl = !!(
      control &&
      control.invalid &&
      control?.parent?.dirty
    );
    const invalidParent = !!(
      control &&
      control.parent &&
      control.parent.invalid &&
      control.parent.dirty
    );

    return invalidCtrl || invalidParent;
  }
}
@Component({
  selector: 'tausi-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  providers: [DynamicDialogRef],
})
export class AppComponent {
  title = 'tausi-core';
  public testVersion: boolean = environment.testVersion;

  public displayTimeoutDialog: boolean = false;
  public displayChangePasswordDialog: boolean = false;
  public changePasswordForm!: FormGroup;

  public matcher = new OauthChangePassErrorStateMatcher();

  public countTimeout = environment.watchingUserActivity.timeout;
  public countDown!: any;
  public countDownWitTime!: any;
  public couterDifferenceProgress!: any;

  public idleNew = environment.watchingUserActivity.idleMillisec;
  public timeoutNew = environment.watchingUserActivity.timeout;
  public pingNew = environment.watchingUserActivity.pingMillisec;

  private _pingSubscription!: Subscription;
  private _onTimerStartSubscription!: Subscription;
  private _onTimeoutSubscription!: Subscription;

  public tausiHttpError!: TausiHttpError;
  public displayTausiHttpError: boolean = false;
  public displayTausiHttpErrorCustomMessage: boolean = false;
  public tausiHttpErrorCustomMessageHeader: any;
  public tausiHttpErrorCustomMessageBody: any;

  public reinitiateFetchingUserDetails$ = new BehaviorSubject<boolean>(false);
  public isLoadingWaitingUserData = false;
  public passwordPolicyResponse!: PasswordPolicy;
  public minLength: any;
  public maxLength: any;

  public hideNewPassword = true;

  get newPasswordInput() {
    return this.changePasswordForm.get('newPassword');
  }

  public status: OnlineStatusType = this.onlineStatusService.getStatus();
  public isOnline!: boolean;

  constructor(
    private _translateSvc: TranslateConfigService,
    private _primengConfigSvc: PrimeNGConfig,
    private _userIdle: UserIdleService,
    private _authService: AuthService,
    private _router: Router,
    private _userStorageService: UserStorageService,
    private _permissionsService: NgxPermissionsService,
    private _formBuilder: FormBuilder,
    private _userUaaService: UserUaaService,
    private _toastService: ToastService,
    private _passwordAndLockoutPolicyService: PasswordAndLockoutPolicyService,
    private _tausiMessagingService: TausiMessagingService,
    private cdr: ChangeDetectorRef,
    private onlineStatusService: OnlineStatusService,
    private _dialogSvc: DialogService
  ) {
    this.isOnline = false;
    this._primengConfigSvc.ripple = true;
    this._translateSvc.initializeLanguage();
    this.displayTausiHttpError = false;
    this.onlineStatusService.status.subscribe((status: OnlineStatusType) => {
      this.status = status;
      this.status === 0 ? (this.isOnline = true) : (this.isOnline = false);
    });
  }

  checkPasswords(group: FormGroup) {
    let pass = group.controls.newPassword.value;
    let confirmPass = group.controls.confirmNewPassword.value;
    return pass === confirmPass ? null : { notSame: true };
  }

  checkIfHttpErrorIsKnown(tausiHttpError: TausiHttpError): boolean {
    if (tausiHttpError?.errorReponse?.error?.error === 'invalid_token') {
      this.displayTausiHttpErrorCustomMessage = true;
      this.tausiHttpErrorCustomMessageBody = 'Sorry, Your Session Has Expired.';
      this.tausiHttpErrorCustomMessageHeader = 'SESSION EXPIRED';
      return true;
    }

    this.displayTausiHttpErrorCustomMessage = false;
    this.tausiHttpErrorCustomMessageBody = undefined;

    return false;
  }

  ngAfterViewInit() {
    this._tausiMessagingService.caughtTausiHttpErrorBehaviorSubject$.subscribe(
      (tausiHttpError: TausiHttpError) => {
        if (tausiHttpError) {
          this.tausiHttpError = tausiHttpError;
          this.displayTausiHttpError = true;

          if (this.checkIfHttpErrorIsKnown(tausiHttpError)) {
            console.log('checkIfHttpErrorIsKnown returned True');
          }
        }
      }
    );

    this._authService.isLogoutBtnClicked$.subscribe(
      ($isLogoutBtnClickedFlag: boolean) => {
        if ($isLogoutBtnClickedFlag) {
          this.stopWatchingUserActivity();
        } else {
        }
      }
    );

    this.reinitiateFetchingUserDetails$.subscribe((flag: boolean) => {
      if (flag && !this._userStorageService.checkIfUserDetailsIsAvailable()) {
        this.retryFetachingUserInfo();
        this.reinitiateFetchingUserDetails$.next(false);
      }
    });

    this._authService.isLoggedIn$.subscribe(($Flag: boolean) => {
      if ($Flag) {
        this.startWatchingUserActivity();
        this.checkIfAlluserDataAreWellSet();
      } else {
        this.stopWatchingUserActivity();
      }
    });

    setTimeout(() => {}, 0);
    this.cdr.detectChanges();
  }

  ngOnInit() {
    this.initChangePasswordForm(this.passwordPolicyResponse);
  }

  retryFetachingUserInfo() {
    this._authService.getUserPrincipleDetails(true);
  }

  checkIfAlluserDataAreWellSet() {
    this._authService.isEverythingLoaded$.subscribe((flag: any) => {
      if (flag) {
        this._authService.isLoggedIn$.subscribe(($Flag: boolean) => {
          if ($Flag) {
            // this._userStorageService.permissionsInStorageChanged$.subscribe((permissionsInStorageChanged: boolean) => {

            //   if (permissionsInStorageChanged) {

            if (this._userStorageService.getPermissions()) {
              let userPermissions = this._userStorageService.getPermissions();
              this._permissionsService.loadPermissions(userPermissions);
            }

            //   }

            // });

            let userDetails = this._userStorageService.getUserDetails();
            // if (userDetails?.accountFirstTimeLogin === true) {
            //   this.getPasswordPolicy();
            //   this.displayChangePasswordDialog = true;
            // }
          } else {
            this.displayChangePasswordDialog = false;
          }
        });
        this.isLoadingWaitingUserData = false;
      } else {
        this.isLoadingWaitingUserData = true;

        setTimeout(() => {
          this.reinitiateFetchingUserDetails$.next(true);
          this.isLoadingWaitingUserData = false;
        }, 60000);
      }
    });
  }

  updateColorOnTimeout(progress: number) {
    if (progress > 75) {
      return 'primary';
    } else if (progress < 50) {
      return 'accent';
    } else if (progress < 25) {
      return 'warn';
    } else {
      return 'accent';
    }
  }

  startWatchingUserActivity() {
    const _this = this;
    this._userIdle.startWatching();
    this._pingSubscription = this._userIdle.ping$.subscribe(() => {
      if (this._authService.isTokenHasExpireOrSoon()) {
        this._authService.refreshToken();
      }
    });

    this._onTimerStartSubscription = this._userIdle
      .onTimerStart()
      .subscribe((count: any) => {
        // _this.countDown = count;
        let couterDifference = this.countTimeout - count;
        let duration = moment.duration(couterDifference, 'seconds');
        let durationFormatedTimeOnly = moment
          .duration(couterDifference, 'seconds')
          .format('HH:mm:ss');
        let durationFormated = moment
          .duration(couterDifference, 'seconds')
          .format('h [hours], m [minutes],  s [seconds]');
        const rawTimeFraction = couterDifference / this.timeoutNew;
        _this.countDown = durationFormated;
        _this.countDownWitTime = durationFormatedTimeOnly;
        _this.couterDifferenceProgress = rawTimeFraction * 100;
        this.showTimeoutDialog();
      });

    this._userIdle.onTimeout().subscribe(() => {
      let user = this._userStorageService.getUserDetails();

      let lockScreenObje: LockScreenStorage = {
        username: user?.username,
        photo: user?.nindetail.photo,
        fullName:
          user?.nindetail?.firstName +
          ' ' +
          user?.nindetail?.middleName +
          ' ' +
          user?.nindetail?.surname,
      };

      this._userStorageService.saveLockScreen(lockScreenObje);

      this.logout();
    });
  }

  stopWatchingUserActivity() {
    this._userIdle.stopWatching();
    this._userIdle.stopTimer();
    this.displayTimeoutDialog = false;
    this.displayChangePasswordDialog = false;
    if (this._pingSubscription) {
      this._pingSubscription.unsubscribe();
    }
  }

  showTimeoutDialog() {
    this.displayTimeoutDialog = true;
  }

  continueResetTimeout() {
    this.stopWatchingUserActivity();
    this.startWatchingUserActivity();
  }

  logout() {
    this._authService.logout().subscribe(
      (res: any) => {
        this._authService.clearAuthStorage();
        this._router.navigateByUrl('/signin');
        this.stopWatchingUserActivity();
        this.closeAllDialogs();
      },
      (err: HttpErrorResponse) => {
        this._authService.clearAuthStorage();
        this._router.navigateByUrl('/signin');
        this.stopWatchingUserActivity();
      }
    );
  }

  closeAllDialogs() {
    this._dialogSvc.dialogComponentRefMap.forEach((dialog) => {
      dialog.destroy();
    });
  }

  changePassword(payload: any) {
    const changePasswordPayload = {
      currentUsername: this._userStorageService.getUserDetails()?.username,
      currentPassword: payload?.password,
      changedPassword: payload?.newPassword,
    };

    this._userUaaService.changeAccountpassword(changePasswordPayload).subscribe(
      (response: any) => {
        if (response?.statusCode === '21000') {
          this._userStorageService.signOut();
          this.displayChangePasswordDialog = false;
          this.logout();
        } else if (response?.statusCode === '210100') {
          this._toastService.error('Invalid Old Password');
        } else {
          this._toastService.error('Account Switched  Error');
        }
      },
      (error: any) => {}
    );
  }

  getPasswordPolicy() {
    this._passwordAndLockoutPolicyService.getPasswordPolicy().subscribe(
      (res: any) => {
        console.log('res: ', res);
        this.passwordPolicyResponse = res.data.content[0];

        this.initChangePasswordForm(this.passwordPolicyResponse);
      },
      (err: HttpErrorResponse) => {}
    );
  }

  getRegexnumber(number: any): number {
    let newNumber;

    if (number) {
      newNumber = number;
    } else {
      newNumber = 0;
    }

    return Math.abs(newNumber);
  }

  initChangePasswordForm(passwordPolicyResponse: PasswordPolicy) {
    let hasCapitalCaseRegex = new RegExp('[a-z]', 'g');
    let hasSmallCaseRegex = new RegExp('[A-Z]', 'g');
    let hasSpecialCharactersRegex = new RegExp(
      '[!@#$%^&*()_+\\-=\\[\\]{};\':"\\|,.<>/?]',
      'g'
    );
    this.changePasswordForm = this._formBuilder.group(
      {
        password: ['', [Validators.required]],
        newPassword: [
          '',
          [
            Validators.compose([
              Validators.required,
              CustomValidators.patternValidator(/\d/, { hasNumber: true }),
              CustomValidators.patternValidator(/[A-Z]{2,}/, {
                hasCapitalCase: true,
              }),
              CustomValidators.patternValidator(/[a-z]/, {
                hasSmallCase: true,
              }),
              Validators.minLength(8),
            ]),
          ],
        ],
        confirmNewPassword: ['', [Validators.required]],
      },
      { validator: this.checkPasswords }
    );
  }
}
