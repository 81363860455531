export class wardCollection {
  static readonly type = '[collection] Load total collections by ward';
}

export class unpaidBills {
  static readonly type = '[bills] Load total unpaid bills';
}

export class registeredBuilding {
  static readonly type = '[building] Load registered buildings';
}

export class monthlyCollections {
  static readonly type = '[collection] Load monthly collections';
}

export class currentMonthCollection {
  static readonly type = '[collection] Load current month collection';
}

export class totalCollection {
  static readonly type = '[collection] Load total collection';
}

export class buildingsByType {
  static readonly type = '[collection] Load buildings by type';
}

export class clearState {
  static readonly type = '[state] Clear state';
}
