import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import {
  DialogService,
  DynamicDialogConfig,
  DynamicDialogRef,
} from 'primeng/dynamicdialog';
import { TaxPayer } from 'src/app/agent-management/models/taxpayer';
import { PosManagementService } from '../../services/pos-management.service';
import { TaxpayerManagementService } from '../../services/taxpayer-management.service';
import { ToastService } from '../../services/toast.service';
import { UserService } from '../../services/user.service';
import { SearchResultsComponent } from '../search-results/search-results.component';

@Component({
  selector: 'tausi-search-collector-dialog',
  templateUrl: './search-collector-dialog.component.html',
  styleUrls: ['./search-collector-dialog.component.scss'],
})
export class SearchCollectorDialogComponent implements OnInit {
  public taxpayer_categories: any[] = [];
  public validateform!: FormGroup;

  public isQuestion!: boolean;

  public isIndividual!: boolean;
  public isLegal!: boolean;

  public rqcode!: string;
  public sw_nidaQuestion!: string;
  public en_nidaQuestion!: string;
  public nida!: any;
  public taxpayer_category!: any;
  public answeredQuestions: any[] = [];
  public prevanswcode!: string;
  public isRegistering!: boolean;

  public agent!: any;

  constructor(
    private _formBuilder: FormBuilder,
    private _refDialog: DynamicDialogRef,
    private _config: DynamicDialogConfig,
    private _userSvc: UserService,
    private _dialogSvc: DialogService,
    private _toastSvc: ToastService,
    private _taxpayerSvc: TaxpayerManagementService,
    private _posSvc: PosManagementService
  ) {
    this.isLegal = false;
    this.isIndividual = true;
    this.isQuestion = false;
    this.isRegistering = false;
    this.agent = this._config.data;
  }

  ngOnInit(): void {
    this.validateform = this.initform();

    setTimeout(() => {
      this.fetchAllTaxpayerCategories();
    }, 0);

    this.validateform
      .get('taxpayer_category')
      ?.valueChanges.subscribe((value) => {
        if (value === 'T100200300') {
          this.taxpayer_categories.map((item) => {
            if (item.code === 'T100200300') {
              this.taxpayer_category = item;
            }
          });
          this.isLegal = true;
          this.isIndividual = false;
        } else {
          this.taxpayer_categories.map((item) => {
            if (item.code === 'T100200400') {
              this.taxpayer_category = item;
            }
          });
          this.isLegal = false;
          this.isIndividual = true;
        }
      });
  }

  /**
   *
   */
  answerQuestion(): void {
    this.isQuestion = true;
  }

  searchCollector(nin: any) {
    this._posSvc.searchCollectorByAgentAndNIN(nin).subscribe((response) => {
      if (response.statusCode === '21000') {
        this.searchByNIN(nin, response.data.item);
      } else {
        const payload = {
          status: false,
          response: response,
        };
        this._refDialog.close(payload);
      }
    });
  }

  /**
   * initialize choice form between NIN and TIN
   * @returns
   */
  initform(): FormGroup {
    return this._formBuilder.group({
      taxpayer_category: ['T100200400', Validators.required],
      tinNumber: [''],
      ninNumber: ['', Validators.required],
    });
  }

  /**
   * answer response question form
   * @returns
   */
  initQuestionform(): FormGroup {
    return this._formBuilder.group({
      answerDetail: ['', Validators.required],
    });
  }

  /**
   * search method
   */
  search(): void {
    const type = this.validateform.get('taxpayer_category')?.value;
    const tin = this.validateform.get('tinNumber')?.value.split('-').join('');
    const nin = this.validateform.get('ninNumber')?.value.split('-').join('');

    switch (type) {
      case 'T100200400':
        this.searchCollector(nin);
        break;
      case 'T100200300':
        this.searchByTIN(tin);
        break;
      default:
        break;
    }
  }

  /**
   * search by tin
   * @param tin
   */
  searchByTIN(tin: string): void {
    this._taxpayerSvc.searchTaxpayerByTIN(tin).subscribe((response) => {
      if (response.statusCode === '21000') {
        const payload = {
          nida: response.data.item,
          taxpayer: response.data.item,
          extras: this.taxpayer_category,
        };
        this._refDialog.close(payload);
      } else if (response.statusCode === '21003') {
        this._toastSvc.warning(response.description);
        this.searchHandler(tin);
      } else {
        this._toastSvc.error(response.description);
      }
    });
  }

  /**
   * search by nin
   * @param nin
   */
  searchByNIN(nin: string, collector: any): void {
    this._taxpayerSvc.searchTaxpayerByNIN(nin).subscribe((response) => {
      if (response.statusCode === '21000') {
        const payload = {
          status: true,
          nida: response.data.item,
          collector: collector,
          taxpayer: response.data.item,
          extras: this.taxpayer_category,
        };
        this._refDialog.close(payload);
      } else if (response.statusCode === '21003') {
        this._toastSvc.warning(response.description);
        this.searchHandler(nin);
      } else {
        this._toastSvc.error(response.description);
      }
    });
  }

  /**
   * handles search results
   * @param nin
   */
  searchHandler(nin: string) {
    const result = {
      nin: nin,
      type: 'nin',
      status: false,
    };
    const _ref = this._dialogSvc.open(SearchResultsComponent, {
      showHeader: false,
      width: '30%',
      data: result,
    });
    _ref.onClose.subscribe((response) => {
      if (response.status) {
        const payload = {
          nin: nin,
          answerCode: null,
          answer: null,
        };
        this.validateTaxpayer(payload);
      }
    });
  }

  /**
   * check if taxpayer has a valid nin
   * @param payload
   */
  validateTaxpayer(payload: any): void {
    this._userSvc.validateTaxPayer(payload).subscribe((response) => {
      if (response.statusCode === 21000) {
        if (response.data.code === '00') {
          this.nida = response.data.data.nidaResponse;
          this.storeTaxpayer(this.nida);
        } else if (response.data.code === '120') {
          this.nida = response.data.data.nidaResponse;
          this.sw_nidaQuestion = this.nida.sw;
          this.en_nidaQuestion = this.nida.en;
          this.prevanswcode = this.nida.prevanswcode;
          this.isQuestion = true;

          if (this.prevanswcode !== null) {
            let payload: any;
            if (this.prevanswcode === '123') {
              payload = {
                answer: this.sw_nidaQuestion,
                status: true,
              };
            } else {
              payload = {
                answer: this.sw_nidaQuestion,
                status: false,
              };
            }
            this.answeredQuestions.push(payload);
            this.answeredQuestions = [...this.answeredQuestions];
          }
        } else if (response.data.code === '122') {
          this._toastSvc.warning(`${response.data.message}`);
        } else if (response.data.code === '132') {
          this._toastSvc.warning(`${response.data.message}`);
        } else if (response.data.code === '130') {
          this._toastSvc.warning(`${response.data.message}`);
        } else {
          this._toastSvc.error(`${response.data.message}`);
        }
      } else {
        this._toastSvc.warning(`${response.description}`);
      }
    });
  }

  /**
   * a class method to store taxpayer
   * @param payload
   */
  storeTaxpayer(payload: any): void {
    const newTaxpayer: TaxPayer = {
      tin: '',
      tradingname: '',
      firstname: payload.firstname,
      middlename: payload.middlename,
      surname: payload.surname,
      othernames: payload.othernames,
      sex: payload.sex,
      dateofbirth: payload.dateofbirth,
      placeofbirth: payload.placeofbirth,
      residentregion: payload.residentregion,
      residentdistrict: payload.residentdistrict,
      residentward: payload.residentward,
      residentvillage: payload.residentvillage,
      residentstreet: payload.residentstreet,
      residenthouseno: payload.residenthouseno,
      residentpostaladdress: payload.residentpostaladdress,
      residentpostcode: payload.residentpostcode,
      birthcountry: payload.birthcountry,
      birthregion: payload.birthregion,
      birthdistrict: payload.birthdistrict,
      birthward: payload.birthward,
      nationality: payload.nationality,
      photo: payload.photo,
      mobilenumber: payload.phonenumber === null ? '' : payload.phonenumber,
      telephonenumber: payload.phonenumber === null ? '' : payload.phonenumber,
      email: payload.email,
      nin: payload.nin,
      taxpayerCategoryId: this.taxpayer_category.id,
    };
    this._taxpayerSvc.storeTaxpayer(newTaxpayer).subscribe((response) => {
      if (response.statusCode === '21000') {
        this._toastSvc.success(response.description);
        const payload = {
          nida: this.nida,
          collector: response.data.item,
          taxpayer: response.data.item,
          extras: this.taxpayer_category,
        };
        setTimeout(() => {
          this._refDialog.close(payload);
        }, 2000);
      } else {
        this._toastSvc.warning(response.description);
      }
    });
  }

  /**
   * submit an answer to the question
   * @param item
   */
  questionResponse(item: any): void {
    const payload = {
      nin: this.nida.nin,
      answerCode: this.nida.rqcode,
      answer: item.answerDetail,
    };
    this.validateTaxpayer(payload);
  }

  /**
   * close dialog/switch views
   */
  close(): void {
    if (this.isQuestion) {
      this.isQuestion = false;
    }
  }

  fetchAllTaxpayerCategories(): void {
    this._taxpayerSvc.fetchTaxpayerCategories().subscribe((response) => {
      if (response.statusCode === '21000') {
        this.taxpayer_categories = response.data.itemList;
        this.taxpayer_categories.map((item) => {
          if (item.code === 'T100200400') {
            this.taxpayer_category = item;
          }
        });
      }
    });
  }

  ngOnDestroy(): void {
    if (this._refDialog) {
      this._refDialog.close();
    }
  }
}
