<div>
  <div class="row mb-3">
    <div class="col-md-12">
      <div class="top-section">
        <div class="heading">
          <p class="section-title">Basic Information</p>
        </div>
        <div class="section-content">
          <div class="details bg">
            <table>
              <tr>
                <td class="text-600">NIN Number</td>
                <td class="text-500"><span>:</span>{{ nida?.nin }}</td>
              </tr>
              <tr>
                <td class="text-600">First Name</td>
                <td class="text-500"><span>:</span>{{ nida?.firstname }}</td>
              </tr>
              <tr>
                <td class="text-600">Middle Name</td>
                <td class="text-500"><span>:</span>{{ nida?.middlename }}</td>
              </tr>
              <tr>
                <td class="text-600">Last Name</td>
                <td class="text-500"><span>:</span> {{ nida?.surname }}</td>
              </tr>
              <tr>
                <td class="text-600">Birth Date</td>
                <td class="text-500"><span>:</span> {{ nida?.dateofbirth }}</td>
              </tr>
              <tr>
                <td class="text-600">Gender</td>
                <td class="text-500"><span>:</span> {{ nida?.sex }}</td>
              </tr>
            </table>
          </div>

          <div class="profile-img">
            <img
              *ngIf="nida != null"
              src="data:image/png;base64,{{ nida?.photo }}"
              alt=""
            />
            <img
              *ngIf="nida == null"
              src="assets/imgs/placeholder.jpg"
              alt=""
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
