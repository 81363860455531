<!-- <p-panel header="{{ options.title.text }}" [toggleable]="true">
  <ng-template pTemplate="icons">
    <button
      mat-icon-button
      class="p-panel-header-icon"
      (click)="menu.toggle($event)"
    >
      <mat-icon class="pi pi-ellipsis-h"></mat-icon>
    </button>
    <p-menu
      appendTo="body"
      #menu
      id="config_menu"
      [model]="items"
      [popup]="true"
    ></p-menu>
  </ng-template>
  <div class="p-p-4">
    <p-chart
      type="bar"
      [options]="options"
      [data]="data"
      width="100%"
      [responsive]="true"
      height="{{ height }}"
    ></p-chart>
  </div>
</p-panel> -->

<p-panel header="{{ title }}" [toggleable]="true">
  <div class="p-p-0">
    <div id="chartdiv" class="" [ngStyle]="{ height: height }"></div>
  </div>
</p-panel>
