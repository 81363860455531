import { Component, OnInit } from '@angular/core';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';

@Component({
  selector: 'tausi-search-results',
  templateUrl: './search-results.component.html',
  styleUrls: ['./search-results.component.scss'],
})
export class SearchResultsComponent implements OnInit {
  public payload!: any;
  constructor(
    private _dialogRef: DynamicDialogRef,
    private _dialogConf: DynamicDialogConfig
  ) {
    this.payload = this._dialogConf.data;
  }

  ngOnInit(): void {}

  cancel() {
    this._dialogRef.close({ status: false });
  }
  continue() {
    this._dialogRef.close({ status: true });
  }
}
