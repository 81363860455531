import {
  HttpContextToken,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { InteractionService } from '../shared/services/interaction.service';

export const BYPASS_LOADER = new HttpContextToken(() => false);

@Injectable()
export class LoaderInterceptor implements HttpInterceptor {
  constructor(private _interSvc: InteractionService) {}
  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    if (req.context.get(BYPASS_LOADER) === true) {
      return next.handle(req);
    } else {
      this._interSvc.showloader();
      return next.handle(req).pipe(
        tap(
          (req) => {
            if (req instanceof HttpResponse) {
              this._interSvc.hideloader();
            }
          },
          (err) => this._interSvc.hideloader()
        )
      );
    }
  }
}
