import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { tap } from 'rxjs/operators';
import { ApiService } from 'src/app/shared/services/api.service';
import { Login, Logout } from './auth.actions';
import { AuthStateModel, Token } from './auth.models';

@State<AuthStateModel>({
  name: 'auth',
  defaults: {
    token: {},
    username: null,
  },
})
@Injectable({
  providedIn: 'root',
})
export class AuthState {
  @Selector()
  static token(state: AuthStateModel): Token | null {
    return state.token;
  }

  @Selector()
  static isAuthenticated(state: AuthStateModel): boolean {
    return !!state.token;
  }

  constructor(private authService: ApiService) {}

  @Action(Login)
  login(ctx: StateContext<AuthStateModel>, { payload }: Login) {
    return this.authService.login(payload).pipe(
      tap((result: Token) => {
        ctx.patchState({
          token: result,
          username: payload.username,
        });
      })
    );
  }

  @Action(Logout)
  logout(ctx: StateContext<AuthStateModel>, { username }: Logout) {
    const state = ctx.getState();
    return this.authService.logout(username).pipe(
      tap(() => {
        ctx.setState({
          token: {},
          username: null,
        });
      })
    );
  }
}
