<div class="container-fluid">
  <div class="default-page">
    <div class="icon-wrapper">
      <i class="pi pi-globe"></i>
    </div>
    <div class="message">
      <h3>{{ title }}</h3>
      <h4>
        {{ description }}
      </h4>
    </div>
    <div class="p-mt-4">
      <button
        (click)="navigate()"
        pButton
        class="p-button"
        icon="pi pi-arrow-left"
        iconPos="left"
        label="Home"
      ></button>
    </div>
  </div>
</div>
