import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { OnlineStatusService, OnlineStatusType } from 'ngx-online-status';

import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { AppComponent } from 'src/app/app.component';
import { MegaMenu } from '../../models/mega.menu.model';
import { AuthService } from '../../services/auth.service';
import { NavigationService } from '../../services/navigation.service';
import { UserStorageService } from '../../services/user-storage.service';

@Component({
  providers: [AppComponent],
  selector: 'tausi-main-toolbar',
  templateUrl: './toolbar.component.html',
  styleUrls: ['./toolbar.component.scss'],
})
export class ToolbarComponent implements OnInit {
  public menuItems!: MegaMenu[];
  public items!: any[];

  public nida: any;
  public user: any;
  public avatar: any;
  public fullName!: string;

  public status: OnlineStatusType = this.onlineStatusService.getStatus();

  constructor(
    public _navigationSvc: NavigationService,
    private _authService: AuthService,
    private _appComponent: AppComponent,
    public _userStorageService: UserStorageService,
    private cdr: ChangeDetectorRef,
    private sanitizer: DomSanitizer,
    private _routerSvc: Router,
    private onlineStatusService: OnlineStatusService
  ) {
    this.onlineStatusService.status.subscribe((status: OnlineStatusType) => {
      this.status = status;
    });
  }

  transform(base64Image: any) {
    let newbase64Image =
      'data:image/png;base64,' +
      (this.sanitizer.bypassSecurityTrustResourceUrl(base64Image) as any)
        .changingThisBreaksApplicationSecurity;

    return this.sanitizer.bypassSecurityTrustResourceUrl(newbase64Image);
  }

  ngAfterViewInit() {
    this._authService.isEverythingLoaded$.subscribe((flag: any) => {
      if (flag) {
        this._authService.isLoggedIn$.subscribe(($Flag: boolean) => {
          if ($Flag) {
            this._userStorageService.storageChanged$.subscribe(
              (userDetailsInStorageChanged: boolean) => {
                if (userDetailsInStorageChanged) {
                  this.loadUserInfo();
                }
              }
            );
          }
        });
      }
    });
    setTimeout(() => {
      this.loadUserInfo();
    }, 0);
    this.cdr.detectChanges();
  }

  loadUserInfo() {
    let userDeatilsFromStorage: any;

    this.user = this._userStorageService.userInfo();
    userDeatilsFromStorage = this._userStorageService.getUserDetails();

    let nin: any = userDeatilsFromStorage?.nindetail;

    this.fullName = `${nin?.firstName} ${nin?.middleName.charAt(0)}. ${
      nin?.surname
    }`;
  }

  ngOnInit() {
    this.loadUserInfo();
    this.items = [
      {
        label: 'Dashboard',
        icon: 'pi pi-th-large',
        command: () => {},
      },
      {
        label: 'User Profile',
        icon: 'pi pi-user',
        command: () => {
          this._routerSvc.navigateByUrl('main/user-management/user-profile');
        },
      },
      {
        label: 'Settings',
        icon: 'pi pi-cog',
        command: () => {},
      },
      {
        label: 'Signout',
        icon: 'pi pi-lock',
        command: () => {
          this.logout();
        },
      },
    ];
  }

  toggleDrawer() {
    this._navigationSvc.drawerToggle();
  }

  logout() {
    this._appComponent.stopWatchingUserActivity();
    this._appComponent.displayTimeoutDialog = false;
    this._appComponent.displayChangePasswordDialog = false;

    this._authService.isLogoutBtnClicked$.next(true);

    this._appComponent.logout();
  }
}
