<div class="not_found-container">
  <div class="container-fluid">
    <div class="row">
      <div class="col-md-12">
        <div class="top-section mb-5">
          <tausi-logo width="200" imageName="tausi"></tausi-logo>
          <h1 class="ml-3 title mb-1 text-700" translate>Resource Not Found</h1>
          <p class="subtitle">
            The Resource you are looking for is not available, it is either
            removed or moved to a different location.
          </p>
        </div>
        <div class="footer-section mt-5">
          <tausi-footer></tausi-footer>
        </div>
      </div>
    </div>
  </div>
</div>
