import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { NgxPermissionsGuard } from 'ngx-permissions';
import { NotFoundComponent } from './layouts/not-found/not-found.component';
import { PrivateComponent } from './layouts/private/private.component';
import { PublicComponent } from './layouts/public/public.component';
import { AuthGuardService } from './shared/services/auth-guard.service';
import { AuthLazyGuardService } from './shared/services/auth-lazy-guard.service';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'main',
    pathMatch: 'full',
    data: {
      breadcrumb: {
        info: 'home',
        disable: true,
      },
    },
  },
  // all public routes will be included in this section

  {
    path: '',
    component: PublicComponent,
    loadChildren: () =>
      import('./authentication/authentication.module').then(
        (module) => module.AuthenticationModule
      ),
  },

  // all protected routes will be included in this section
  {
    path: 'main',
    component: PrivateComponent,
    data: { breadcrumb: 'Home' },
    canActivate: [AuthGuardService],
    children: [
      {
        path: '',
        data: { breadcrumb: 'Dashboard' },
        canLoad: [AuthLazyGuardService],
        loadChildren: () =>
          import('./dashboard/dashboard.module').then(
            (module) => module.DashboardModule
          ),
      },
      {
        path: 'council-management',
        data: { breadcrumb: 'Council Management' },
        canLoad: [AuthLazyGuardService],

        loadChildren: () =>
          import('./council-management/council-management.module').then(
            (module) => module.CouncilManagementModule
          ),
      },
      {
        path: 'float-management',
        data: { breadcrumb: 'Float Management' },
        canLoad: [AuthLazyGuardService],

        loadChildren: () =>
          import('./float-management/float-management.module').then(
            (module) => module.FloatManagementModule
          ),
      },
      {
        path: 'user-management',
        data: {
          breadcrumb: 'User Management',
          permissions: {
            only: [
              'UAAS_LIST_USER',
              'UAAS_LIST_GROUP',
              'UAAS_LIST_ROLE',
              'UAAS_UPDATE_PASSWORD_POLICY',
              'UAAS_UPDATE_LOCKOUT_POLICY',
            ],
            redirectTo: '/404-error',
          },
        },
        canLoad: [AuthLazyGuardService, NgxPermissionsGuard],
        canActivate: [NgxPermissionsGuard],
        loadChildren: () =>
          import('./user-management/user-management.module').then(
            (module) => module.UserManagementModule
          ),
      },
      {
        path: 'system-configuration',
        data: { breadcrumb: 'System Configurations' },
        canLoad: [AuthLazyGuardService],
        loadChildren: () =>
          import('./settings/settings.module').then(
            (module) => module.SettingsModule
          ),
      },
      {
        path: 'agent-management',
        data: { breadcrumb: 'Agent Management' },
        canLoad: [AuthLazyGuardService],
        loadChildren: () =>
          import('./agent-management/agent-management.module').then(
            (module) => module.AgentManagementModule
          ),
      },
      {
        path: 'collector-management',
        data: { breadcrumb: 'Collector Management' },
        canLoad: [AuthLazyGuardService],
        loadChildren: () =>
          import('./collector-management/collector-management.module').then(
            (module) => module.CollectorManagementModule
          ),
      },
      {
        path: 'pos-management',
        data: { breadcrumb: 'POS Management' },
        canLoad: [AuthLazyGuardService],
        loadChildren: () =>
          import('./pos-management/pos-management.module').then(
            (module) => module.PosManagementModule
          ),
      },
      {
        path: 'accounting',
        data: { breadcrumb: 'Accounting' },
        canLoad: [AuthLazyGuardService],
        loadChildren: () =>
          import('./accounting/accounting.module').then(
            (module) => module.AccountingModule
          ),
      },
      {
        path: 'business-license',
        data: { breadcrumb: 'Business License' },
        canLoad: [AuthLazyGuardService],
        loadChildren: () =>
          import('./business-license/business-license.module').then(
            (module) => module.BusinessLicenseModule
          ),
      },
      {
        path: 'hotel-leavy',
        data: { breadcrumb: 'Hotel Leavy' },
        canLoad: [AuthLazyGuardService],
        loadChildren: () =>
          import('./hotel-leavy/hotel-leavy.module').then(
            (module) => module.HotelLeavyModule
          ),
      },
      {
        path: 'fishing-license',
        data: { breadcrumb: 'Fishing License' },
        canLoad: [AuthLazyGuardService],

        loadChildren: () =>
          import('./fishing-license/fishing-license.module').then(
            (module) => module.FishingLicenseModule
          ),
      },
      {
        path: 'building-permit',
        data: { breadcrumb: 'Building Permit' },
        canLoad: [AuthLazyGuardService],

        loadChildren: () =>
          import('./building-permit/building-permit.module').then(
            (module) => module.BuildingPermitModule
          ),
      },
      {
        path: 'bus-stand',
        data: { breadcrumb: 'Bus Stand' },
        canLoad: [AuthLazyGuardService],

        loadChildren: () =>
          import('./bus-stand/bus-stand.module').then(
            (module) => module.BusStandModule
          ),
      },
      {
        path: 'service-levy',
        data: { breadcrumb: 'Service Levy' },
        canLoad: [AuthLazyGuardService],
        loadChildren: () =>
          import('./service-levy/service-levy.module').then(
            (module) => module.ServiceLevyModule
          ),
      },
      {
        path: 'solid-waste',
        data: { breadcrumb: 'Solid Waste Management' },
        canLoad: [AuthLazyGuardService],
        loadChildren: () =>
          import('./solid-waste/solid-waste.module').then(
            (module) => module.SolidWasteModule
          ),
      },
      {
        path: 'house-rent',
        data: { breadcrumb: 'House Rent' },
        canLoad: [AuthLazyGuardService],
        loadChildren: () =>
          import('./house-rent/house-rent.module').then(
            (module) => module.HouseRentModule
          ),
      },
      {
        path: 'projects',
        data: { breadcrumb: 'Projects' },
        canLoad: [AuthLazyGuardService],
        loadChildren: () =>
          import('./land-sales/land-sales.module').then(
            (module) => module.LandSalesModule
          ),
      },
      {
        path: 'setup',
        data: { breadcrumb: 'Land Sales Setup' },
        canLoad: [AuthLazyGuardService],
        loadChildren: () =>
          import('./land-sales/land-sales.module').then(
            (module) => module.LandSalesModule
          ),
      },
      {
        path: '',
        canLoad: [AuthLazyGuardService],
        loadChildren: () =>
          import('./land-sales/land-sales.module').then(
            (module) => module.LandSalesModule
          ),
      },
      {
        path: 'auctioning',
        canLoad: [AuthLazyGuardService],
        loadChildren: () =>
          import('./auctioning/auctioning.module').then(
            (module) => module.AuctioningModule
          ),
      },
      {
        path: 'animal-movement',
        canLoad: [AuthLazyGuardService],
        loadChildren: () =>
          import('./animal-movement/animal-movement.module').then(
            (module) => module.AnimalMovementModule
          ),
      },
      {
        path: 'parking',
        canLoad: [AuthLazyGuardService],
        loadChildren: () =>
          import('./parking/parking.module').then(
            (module) => module.ParkingModule
          ),
      },
      {
        path: 'billboard',
        canLoad: [AuthLazyGuardService],
        loadChildren: () =>
          import('./billboard/billboard.module').then(
            (module) => module.BillboardModule
          ),
      },
      {
        path: 'land-rent',
        canLoad: [AuthLazyGuardService],
        loadChildren: () =>
          import('./land-rent/land-rent.module').then(
            (module) => module.LandRentModule
          ),
      },
      {
        path: 'property-tax',
        canLoad: [AuthLazyGuardService],
        loadChildren: () =>
          import('./property-tax/property-tax.module').then(
            (module) => module.PropertyTaxModule
          ),
      },
    ],
  },
  {
    path: '**',
    pathMatch: 'full',
    component: NotFoundComponent,
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      useHash: true,
      preloadingStrategy: PreloadAllModules,
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
