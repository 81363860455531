import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'tausi-collector-chart',
  templateUrl: './collector-chart.component.html',
  styleUrls: ['./collector-chart.component.scss']
})
export class CollectorChartComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
