import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class ApiService {
  private endPoints = environment.endPoints;
  private env = environment.authConfig;
  private apiUri: string = environment.apiUrl + this.endPoints.uaa_service;

  constructor(private _http: HttpClient) {}

  login(user: any): Observable<any> {
    const encoUsername = encodeURIComponent(user.username);
    const encoPassword = encodeURIComponent(user.password);

    const payload = `username=${encoUsername}&password=${encoPassword}&grant_type=password`;
    const headers = new HttpHeaders({
      'Content-Type': 'application/x-www-form-urlencoded',
      Accept: 'application/json',
      Authorization:
        'Basic ' + btoa(this.env.clientId + ':' + this.env.clientSecret),
      skip: 'skip',
    });

    return this._http.post<any>(`${this.apiUri}/oauth/token`, payload, {
      headers,
    });
  }

  public logout(username: string) {
    let payload: any = {
      clientId: this.env.clientId,
      username: username,
    };

    return this._http.post<any[]>(`${this.apiUri}/api/v1/user/logout`, payload);
  }
}
