import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { environment } from 'src/environments/environment';
import {
  LockScreenStorage,
  UserSwitchingAccount,
  UserSwitchingAccountArray,
} from '../models/auth.interface';

@Injectable({
  providedIn: 'root',
})
export class UserStorageService {
  private switchingAccounts: string =
    environment.userPrincipleDetails.switchingAccounts;
  private currentSwitchedAccount: string =
    environment.userPrincipleDetails.currentSwitchedAccount;
  private userDetails: string = environment.userDetails;
  private userPermissions: string = environment.userPermissions;
  private lockScreen: string = environment.lockScreen;

  public storageChanged$ = new BehaviorSubject(false);
  public permissionsInStorageChanged$ = new BehaviorSubject(false);

  constructor() {}

  signOut() {
    // localStorage.removeItem(this.switchingAccounts);
    // localStorage.removeItem(this.currentSwitchedAccount);
    // localStorage.removeItem(this.userDetails);
    // localStorage.removeItem(this.userPermissions);
    localStorage.clear();
  }

  checkIfStorageHasUserData(): boolean {
    if (localStorage.getItem(this.userDetails) === null) {
      return false;
    }

    return true;
  }

  // userDetails

  public saveUserDetails(userDetailsObject: any) {
    // 1st remove
    localStorage.removeItem(this.userDetails);
    // 2nd rove unwanted object

    const newUserDetailsObject = { ...userDetailsObject };
    newUserDetailsObject.userRoleAdministrativeAreaAccountType = [];
    newUserDetailsObject.userSwitchingAdministrativeAreaList = [];
    newUserDetailsObject.userGroupAdministrativeAreaAreaLevel = [];

    const userCurrentSwitchedAccountWebRoleIdObj = {
      ...userDetailsObject.userCurrentSwitchedAccountWeb,
    };
    userCurrentSwitchedAccountWebRoleIdObj.roleId = null;

    newUserDetailsObject.userCurrentSwitchedAccountWeb =
      userCurrentSwitchedAccountWebRoleIdObj;

    localStorage.setItem(
      this.userDetails,
      JSON.stringify(newUserDetailsObject)
    );
    this.storageChanged$.next(true);

    // try {

    //   }
    //   catch (e) {
    //       // console.log("Local Storage is full, Please empty data");
    //       this.confirm(
    //         'Failed to save Data.',
    //         'pi-exclamation-triangle warning'
    //       );

    //   }
  }

  public getUserDetails(): any {
    let retrievedUserSwitchingAccountArray = localStorage.getItem(
      this.userDetails
    )!;
    return JSON.parse(retrievedUserSwitchingAccountArray);
  }

  public checkIfUserDetailsIsAvailable(): boolean {
    if (localStorage.getItem(this.userDetails) == null) return false;
    return true;
  }

  public removeUserDetails(): boolean {
    if (localStorage.getItem(this.userDetails) == null) return false;
    localStorage.removeItem(this.userDetails);
    return true;
  }

  userInfo(): any {
    const encoded = localStorage.getItem('userDetails');
    if (encoded) {
      const decode = JSON.parse(`${encoded}`);
      const user = {
        administrativeAreaId:
          decode.userCurrentSwitchedAccountWeb.administrativeAreaId.id,
        nida: decode.nindetail,
        avatar: decode.nindetail.photo,
        web: decode.userCurrentSwitchedAccountWeb,
        email: decode.email,
        username: decode.username,
        currentFinancialYear: decode.currentFinancialYear,
        fullName: `${decode.nindetail.firstName} ${decode.nindetail.middleName} ${decode.nindetail.lastName}`,
        area: `${decode.userCurrentSwitchedAccountWeb.administrativeAreaId.name} ${decode.userCurrentSwitchedAccountWeb.administrativeAreaId.areaType.name}`,
      };
      return user;
    }
  }

  // switchingAccountsArray
  public saveSwitchingAccounts(
    switchingAccountsArray: UserSwitchingAccountArray
  ) {
    localStorage.removeItem(this.switchingAccounts);
    localStorage.setItem(
      this.switchingAccounts,
      JSON.stringify(switchingAccountsArray)
    );
  }

  public getSwitchingAccounts(): UserSwitchingAccountArray {
    let retrievedUserSwitchingAccountArray = localStorage.getItem(
      this.switchingAccounts
    )!;
    return JSON.parse(retrievedUserSwitchingAccountArray);
  }

  public removeSwitchingAccounts(): boolean {
    if (localStorage.getItem(this.switchingAccounts) === null) return false;
    localStorage.removeItem(this.switchingAccounts);
    return true;
  }

  // Current Switched Account
  public saveCurrentSwitchedAccount(
    currentSwitchedAccountObject: UserSwitchingAccount
  ) {
    localStorage.removeItem(this.currentSwitchedAccount);
    localStorage.setItem(
      this.currentSwitchedAccount,
      JSON.stringify(currentSwitchedAccountObject)
    );
  }

  public getCurrentSwitchedAccount(): UserSwitchingAccount {
    let retrievedUserSwitchingAccountArray = localStorage.getItem(
      this.currentSwitchedAccount
    )!;
    return JSON.parse(retrievedUserSwitchingAccountArray);
  }

  public removeCurrentSwitchedAccount(): boolean {
    if (localStorage.getItem(this.currentSwitchedAccount) === null)
      return false;
    localStorage.removeItem(this.currentSwitchedAccount);
    return true;
  }

  // permissions
  public savePermissions(userPermissionsObject: any) {
    localStorage.removeItem(this.userPermissions);
    localStorage.setItem(
      this.userPermissions,
      JSON.stringify(userPermissionsObject)
    );
    this.permissionsInStorageChanged$.next(true);
  }

  public getPermissions(): any {
    let retrieveduserPermissions = localStorage.getItem(this.userPermissions)!;
    return JSON.parse(retrieveduserPermissions);
  }

  public removePermissions(): boolean {
    if (localStorage.getItem(this.userPermissions) === null) return false;
    localStorage.removeItem(this.userPermissions);
    return true;
  }

  // LockScreen
  public saveLockScreen(LockScreenObject: LockScreenStorage) {
    localStorage.removeItem(this.lockScreen);
    localStorage.setItem(this.lockScreen, JSON.stringify(LockScreenObject));
  }

  public getLockScreen(): LockScreenStorage {
    let retrievedlockScreen = localStorage.getItem(this.lockScreen)!;
    return JSON.parse(retrievedlockScreen);
  }

  public checkIfToLockScreen(): boolean {
    if (localStorage.getItem(this.lockScreen) === null) return false;
    return true;
  }

  public removeLockScreen(): boolean {
    if (localStorage.getItem(this.lockScreen) === null) return false;
    localStorage.removeItem(this.lockScreen);
    return true;
  }
}
