import { AfterViewInit, Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'tausi-table-report',
  templateUrl: './table-report.component.html',
  styleUrls: ['./table-report.component.scss']
})
export class TableReportComponent implements OnInit {

  @Input() data: any[] = [];
  @Input() summary: any[] = [];
  @Input() cols: any[] = [];
  @Input() rowFooter: any[] = [];
  @Input() subTitle : any[] = [];
  @Input() startDate!: Date;
  @Input() endDate!: Date;
  @Input() reportTitle!: string;

  constructor() { }

  ngOnInit(): void {
  }

}
