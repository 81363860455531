import { Component, Input, OnInit } from '@angular/core';
import { UserStorageService } from 'src/app/shared/services/user-storage.service';

@Component({
  selector: 'tausi-page-title',
  templateUrl: './page-title.component.html',
  styleUrls: ['./page-title.component.scss'],
})
export class PageTitleComponent implements OnInit {
  @Input() title?: string;
  public user: any;
  constructor(private _storageSvc: UserStorageService) {}

  ngOnInit(): void {
    setTimeout(() => {
      this.user = this._storageSvc.userInfo();
    }, 0);
  }
}
