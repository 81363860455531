import { Component, OnInit } from '@angular/core';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { InteractionService } from 'src/app/shared/services/interaction.service';
import { TaxpayerManagementService } from '../../services/taxpayer-management.service';

@Component({
  selector: 'tausi-taxpayer-info',
  templateUrl: './taxpayer-info.component.html',
  styleUrls: ['./taxpayer-info.component.scss'],
})
export class TaxpayerInfoComponent implements OnInit {
  public nida!: any;
  public status: any;

  constructor(
    private _config: DynamicDialogConfig,
    private _ref: DynamicDialogRef,
    private _taxpayerSvc: TaxpayerManagementService,
    private _interactionSvc: InteractionService
  ) {
    this.nida = this._config.data.nida;
    this.status = this._config.data.status;
  }

  ngOnInit(): void {}

  close(): void {
    this.ngOnDestroy();
  }

  submit(): void {
    this.save(this.nida);
  }

  /**
   * a class method to store taxpayer
   * @param payload
   */
  save(payload: any): void {
    const taxpayer: any = {
      tin: '',
      tradingname: '',
      firstname: payload.firstname,
      middlename: payload.middlename,
      surname: payload.surname,
      othernames: payload.othernames,
      sex: payload.sex,
      dateofbirth: payload.dateofbirth,
      placeofbirth: payload.placeofbirth,
      residentregion: payload.residentregion,
      residentdistrict: payload.residentdistrict,
      residentward: payload.residentward,
      residentvillage: payload.residentvillage,
      residentstreet: payload.residentstreet,
      residenthouseno: payload.residenthouseno,
      residentpostaladdress: payload.residentpostaladdress,
      residentpostcode: payload.residentpostcode,
      birthcountry: payload.birthcountry,
      birthregion: payload.birthregion,
      birthdistrict: payload.birthdistrict,
      birthward: payload.birthward,
      nationality: payload.nationality,
      photo: payload.photo,
      mobilenumber: payload.phonenumber === null ? '' : payload.phonenumber,
      telephonenumber: payload.phonenumber === null ? '' : payload.phonenumber,
      email: payload.email,
      nin: payload.nin,
      taxpayerCategoryId: '7c4c5815491d11ec9a041130862ee7e9',
    };
    this._taxpayerSvc.storeTaxpayer(taxpayer).subscribe((response) => {
      if (response.statusCode === '21000') {
        this._ref.close();
        this._interactionSvc.dialog(
          'You have successfully created your account',
          'pi-info-circle success'
        );
      } else {
        this._ref.close();
        this._interactionSvc.dialog(
          response.description,
          'pi-exclamation-triangle warning'
        );
      }
    });
  }

  ngOnDestroy(): void {
    if (this._ref) {
      this._ref.close();
    }
  }
}
