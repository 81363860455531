import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { AuthService } from '../services/auth.service';
import { TokenStorageService } from '../services/token-storage.service';
import { SignInOauthToken } from '../models/auth.interface';
import { environment } from 'src/environments/environment';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  private korongoClient: string = environment.korongoConfig.korongoClient;

  constructor(
    private _authService: AuthService,
    private _tokenStorageService: TokenStorageService
  ) {}

  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    let token: SignInOauthToken = this._tokenStorageService.getTokenObject();
    const isSkipHeader = request.headers.has('skip');

    if (isSkipHeader) {
      // Remove unnecessary header & proceed
      request = request.clone({
        headers: request.headers.delete('skip'),
      });
    } else {
      if (token) {
        request = request.clone({
          setHeaders: {
            Authorization: `Bearer ${token.accessToken}`,
            'Korongo-Client': `${this.korongoClient}`,
          },
        });
      } else {
        request = request.clone();
      }
    }

    return next.handle(request);
  }
}
