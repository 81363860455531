import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'tausi-avatar',
  templateUrl: './avatar.component.html',
  styleUrls: ['./avatar.component.scss'],
})
export class AvatarComponent implements OnInit {
  @Input() name?: string;
  @Input() width?: string;
  @Input() height?: string;
  constructor() {}

  ngOnInit(): void {}
}
