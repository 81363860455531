import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { CouncilManagementService } from '../../services/council-management.service';
import { ToastService } from '../../services/toast.service';

@Component({
  selector: 'tausi-tin-search',
  templateUrl: './tin-search.component.html',
  styleUrls: ['./tin-search.component.scss'],
})
export class TinSearchComponent implements OnInit {
  public user!: any;
  public tinSearch!: FormGroup;

  constructor(
    private _toastSvc: ToastService,
    private _councilManagementSvc: CouncilManagementService,
    private _formBuilder: FormBuilder,
    public ref: DynamicDialogRef,
    public config: DynamicDialogConfig
  ) {
    this.user = this.config.data;
  }

  ngOnInit() {
    this.tinSearch = this.initForm();
  }

  initForm(): FormGroup {
    return this._formBuilder.group({
      tinNumber: ['', Validators.required],
    });
  }

  verifyTin(item: any) {
    this._councilManagementSvc.getCouncilSignatory(item.tinNumber).subscribe(
      (response) => {
        if (response.statusCode === '21000') {
          this.ref.close(response);
        } else if (response.statusCode === '21003') {
          this.ref.close();
          this._toastSvc.warning(
            `Could not find any records matching this TIN`
          );
        } else {
          this.ref.close();
          this._toastSvc.warning(response.description);
        }
      },
      (err) => {
        this.ref.close();
        this._toastSvc.error(err.message);
      }
    );
  }
}
