import {Component, Input, OnInit} from '@angular/core';
import {MenuItem} from "primeng/api";
import {Router} from "@angular/router";

@Component({
  selector: 'tausi-dashboard-data',
  templateUrl: './dashboard-data.component.html',
  styleUrls: ['./dashboard-data.component.scss']
})
export class DashboardDataComponent implements OnInit {
  @Input() data: any[] = [];
  @Input() cols: any[] = [];
  @Input() category: string = '';
  @Input() header: string = 'Tausi Header';
  @Input() height: string = '470px';

  public items!: MenuItem[];
  public nColumns: any;

  constructor(private _routerSvc: Router) {}

  ngOnInit() {
    this.items = [
      {
        label: 'Chart Options',
        items: [
          {
            label: 'Download',
            icon: 'pi pi-download',
          },
          {
            label: 'Share',
            icon: 'pi pi-share-alt',
          },
        ],
      },
    ];
    this.nColumns = this.cols.length + 1;
  }

  viewAllReport(): void {
    this._routerSvc.navigate([
      `/main/pos-management/list-pos-report`,
      this.category,
    ]);
  }

}
