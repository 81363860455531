import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Language } from '../models/language.model';

@Injectable({
  providedIn: 'root',
})
export class TranslateConfigService {
  private language: any;
  private default: string = 'en';

  public languageList: Language[] = [
    {
      title: 'Kiswahili',
      flagName: 'sw.svg',
      countryCode: 'sw',
      active: true,
    },
    {
      title: 'English',
      flagName: 'en.svg',
      countryCode: 'en',
      active: true,
    },
  ];

  constructor(private _translateSvc: TranslateService) {
    this.language = _translateSvc.setDefaultLang(this.default);
    _translateSvc.use(this.language);
  }

  public initializeLanguage() {
    const lang: any = this.getLanguage();
    this.setLanguage(lang);
    this._translateSvc.use(lang);
  }

  public changeLanguage(type: string) {
    localStorage.setItem('user-language', type);
    this._translateSvc.use(type);
  }

  public isEnglish() {
    return this.language === 'en';
  }

  private setLanguage(language: string) {
    this.language = language;
    localStorage.setItem('user-language', language);
  }

  public getLanguage() {
    if (localStorage.getItem('user-language')) {
      return (this.language = localStorage.getItem('user-language'));
    } else {
      return (this.language = 'en');
    }
  }
}
