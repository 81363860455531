<div class="px-2 py-2">
  <div class="my-3">
    <form [formGroup]="tinSearch" novalidate autocomplete="off">
      <div class="p-fluid p-formgrid p-grid">
        <div class="p-field p-col-12">
          <span class="p-float-label">
            <p-inputMask
              name="tinNumber"
              class="p-inputtext-lg"
              id="tin-input"
              mask="999-999-999"
              formControlName="tinNumber"
            ></p-inputMask>
            <label for="tin-input">Provide TIN</label>
          </span>
        </div>
      </div>
    </form>
  </div>
  <div class="" fxLayout="row" fxLayoutAlign="end center">
    <p-button
      (click)="verifyTin(tinSearch.value)"
      label="Search TIN"
      icon="pi pi-arrow-right"
      iconPos="right"
      *ngIf="tinSearch.valid"
      styleClass="p-button"
    ></p-button>
  </div>
</div>
