import {
  Component,
  Inject,
  Input,
  NgZone,
  OnChanges,
  OnDestroy,
  OnInit,
  PLATFORM_ID,
  SimpleChanges,
} from '@angular/core';
// amCharts imports
import * as am5 from '@amcharts/amcharts5';
import am5themesAnimated from '@amcharts/amcharts5/themes/Animated';
import * as am5xy from '@amcharts/amcharts5/xy';
import { isPlatformBrowser } from '@angular/common';

@Component({
  selector: 'tausi-bar-chart',
  templateUrl: './bar-chart.component.html',
  styleUrls: ['./bar-chart.component.scss'],
})
export class BarChartComponent implements OnInit, OnDestroy, OnChanges {
  @Input() data = [
    {
      item: 'Label',
      value: 10,
    },
  ];
  @Input() options: any;
  @Input() title: any;
  @Input() height = '470px';

  private root!: am5.Root;

  constructor(
    @Inject(PLATFORM_ID) private _platformId: Object,
    private _zone: NgZone
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    this.browserOnly(() => {
      if (this.root) {
        this.root.dispose();
      }
      this.chartRenderer();
    });
  }

  ngOnInit(): void {
    this.browserOnly(() => {
      if (this.root) {
        this.root.dispose();
      }
      this.chartRenderer();
    });
  }

  browserOnly(f: () => void) {
    if (isPlatformBrowser(this._platformId)) {
      this._zone.runOutsideAngular(() => {
        f();
      });
    }
  }

  ngOnDestroy() {
    this.browserOnly(() => {
      if (this.root) {
        this.root.dispose();
      }
    });
  }

  chartRenderer(): void {
    let data = this.data;
    let root = am5.Root.new('chartdiv');
    root.setThemes([am5themesAnimated.new(root)]);
    let chart = root.container.children.push(
      am5xy.XYChart.new(root, {
        panX: true,
        panY: true,
        wheelX: 'panX',
        wheelY: 'zoomX',
        pinchZoomX: true,
        paddingLeft: 0,
        paddingRight: 1,
      })
    );
    let cursor = chart.set('cursor', am5xy.XYCursor.new(root, {}));
    cursor.lineY.set('visible', false);

    let xRenderer = am5xy.AxisRendererX.new(root, {
      minGridDistance: 30,
    });
    xRenderer.labels.template.setAll({
      rotation: -45,
      centerY: am5.p50,
      centerX: am5.p100,
      paddingRight: 15,
      fontSize: 12,
    });

    xRenderer.grid.template.setAll({
      location: 1,
    });

    let xAxis = chart.xAxes.push(
      am5xy.CategoryAxis.new(root, {
        maxDeviation: 0.3,
        categoryField: this.options.xField,
        renderer: xRenderer,
        tooltip: am5.Tooltip.new(root, {}),
      })
    );

    let yRenderer = am5xy.AxisRendererY.new(root, {
      strokeOpacity: 0.1,
    });

    let yAxis = chart.yAxes.push(
      am5xy.ValueAxis.new(root, {
        maxDeviation: 0.3,
        renderer: yRenderer,
      })
    );

    // Create series
    let series = chart.series.push(
      am5xy.ColumnSeries.new(root, {
        name: this.options?.title,
        xAxis: xAxis,
        yAxis: yAxis,
        valueYField: this.options.yField,
        sequencedInterpolation: true,
        categoryXField: this.options.xField,
        tooltip: am5.Tooltip.new(root, {
          labelText: '{valueY}',
        }),
      })
    );

    series.columns.template.setAll({
      cornerRadiusTL: 5,
      cornerRadiusTR: 5,
      strokeOpacity: 0,
    });

    xAxis.data.setAll(data);
    series.data.setAll(data);

    series.appear(1000);
    chart.appear(1000, 100);
    this.root = root;
  }
}
