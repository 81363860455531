<div class="dialog-wrapper">
  <h1>Welcome Back!</h1>
  <p class="text-muted">
    We're working on improving your experience and security on our system. To
    help us do that, we'd like to verify your mobile phone number.
  </p>
  <div class="mt-3 mb-4" fxLayout="row" fxLayoutAlign="end center">
    <button
      type="button"
      pButton
      label="Verify Later"
      class="p-button-text p-button-raised mx-3"
      (click)="close()"
    ></button>
    <button
      type="button"
      pButton
      label="Verify Now"
      class="p-button"
      (click)="submit()"
    ></button>
  </div>
</div>
