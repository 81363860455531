<section class="breadcrumb">
  <div class="wrapper-fluid">
    <div class="row">
      <div class="col-md-12">
        <xng-breadcrumb [separator]="iconTemplate">
          <ng-container *xngBreadcrumbItem="let breadcrumb; let info = info">
            <mat-icon *ngIf="info">{{ info }}</mat-icon>
            <ng-container>{{ breadcrumb }}</ng-container>
          </ng-container>
        </xng-breadcrumb>
        <ng-template #iconTemplate>
          <mat-icon>chevron_right</mat-icon>
        </ng-template>
      </div>
    </div>
  </div>
</section>
