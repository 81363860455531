import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { UserUaaService } from 'src/app/user-management/services/user-uaa.service';
import { OTP } from '../../models/user.model';

@Component({
  selector: 'tausi-update-notification-number',
  templateUrl: './update-notification-number.component.html',
  styleUrls: ['./update-notification-number.component.scss'],
})
export class UpdateNotificationNumberComponent implements OnInit {
  otpForm!: FormGroup;
  isValid!: boolean;
  errorMessage: any;
  submitting: boolean = false;
  data: any;
  header: string = 'Update Phone Number';
  title: string =
    'Please enter your NIDA number and a new mobile number registered with that NIDA number.';
  otpVerification: boolean = false;
  otp!: string;

  constructor(
    private _uaaSvc: UserUaaService,
    private _formBuilder: FormBuilder,
    private _ref: DynamicDialogRef,
    private _config: DynamicDialogConfig
  ) {
    this.isValid = false;
    this.data = this._config.data;
  }

  ngOnInit(): void {
    this.otpForm = this.initForm();
  }

  initForm(): FormGroup {
    return this._formBuilder.group({
      phoneNumber: [
        this.data?.phoneNumber,
        Validators.compose([
          Validators.pattern('^0{1}[6-7]{1}[0-9]{8}$'),
          Validators.required,
        ]),
      ],
      nin: [this.data?.nin, Validators.required],
    });
  }

  /**
   *
   * @param item
   */
  submitNotification(item: any): void {
    this.submitting = true;
    this.errorMessage = '';
    const payload = {
      nin: item?.nin.split('-').join(''),
      phoneNumber: item?.phoneNumber,
    };
    this.getOTP(payload);
  }

  ngOnDestroy(): void {
    if (this._ref) {
      this._ref.close();
    }
  }

  close(): void {
    this._ref.close();
  }

  handleChange(event: any): void {
    if (event?.length === 6) {
      this.otp = event;
      this.isValid = true;
    } else {
      this.isValid = false;
    }
  }

  getOTP(item: OTP): void {
    const payload = {
      nin: item?.nin ?? '',
      phoneNumber: item?.phoneNumber ?? '',
      otp: '',
      token: '',
    };

    this._uaaSvc.getOTP(payload).subscribe((response) => {
      if (response.statusCode == '21000') {
        this.data = response.data;
        this.otpVerification = true;
        this.isValid = false;
        this.header = 'Verification';
        this.title = `We have sent a verification code to your new phone number, ${this.data.phoneNumber}. Please enter the verification code to continue.`;
      } else {
        this.errorMessage = response?.description;
      }
      this.submitting = false;
    });
  }

  getNinDetails(): void {
    const payload = {
      nin: this.data.nin,
      phoneNumber: this.data.phoneNumber,
      otp: this.otp,
      token: this.data.token,
    };

    this._uaaSvc.verifyOTP(payload).subscribe((response) => {
      if (response.statusCode == '21000') {
        this._ref.close(response.description);
      } else {
        this.errorMessage = response.description;
        this.submitting = false;
      }
    });
  }

  submitOtp() {
    this.submitting = true;
    this.errorMessage = '';
    this.getNinDetails();
  }

  resend() {
    this.errorMessage = '';
    this.getOTP(this.data);
  }
}
