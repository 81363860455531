import { Component, OnInit } from '@angular/core';
import { DynamicDialogRef } from 'primeng/dynamicdialog';

import Map from 'ol/Map';
import View from 'ol/View';
import TileLayer from 'ol/layer/Tile';
import OSM from 'ol/source/OSM';
import TileWMS from 'ol/source/TileWMS';
import Tile from 'ol/layer/Tile';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'tausi-pos-locations-map',
  templateUrl: './pos-locations-map.component.html',
  styleUrls: ['./pos-locations-map.component.scss'],
})
export class PosLocationsMapComponent implements OnInit {
  public baseLayer: any;
  public featureLayer: any;
  public wmsFeatureLayer: any;
  public selectedCenter: any[] = [36.719, -3.379];

  constructor(private _dialogRef: DynamicDialogRef) {}

  map!: Map;

  ngOnInit(): void {
    this.initializeLayers();
    this.initializeMap();
  }

  initializeMap() {
    this.map = new Map({
      layers: [this.baseLayer, this.wmsFeatureLayer],
      target: 'ol-map',
      view: new View({
        center: this.selectedCenter,
        zoom: 13,
        projection: 'EPSG:4326',
      }),
    });
  }

  initializeLayers() {
    this.wmsFeatureLayer = new Tile({
      source: new TileWMS({
        url: `${environment.gis.geoserver_daraja}`,
        params: {
          LAYERS: `${environment.gis.layers.tausi_gis_layer}`,
          tiled: true,
        },
        serverType: 'geoserver',
      }),
    });

    this.baseLayer = new TileLayer({
      source: new OSM({
        attributions: 'TAUSI GIS Application',
        wrapX: true,
      }),
    });
  }

  cancel() {
    this._dialogRef.close();
  }
}
