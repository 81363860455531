import { HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';

const config = environment.authConfig;

export const AuthHeaders = new HttpHeaders({
  'Content-Type': 'application/x-www-form-urlencoded',
  Accept: 'application/json',
  Authorization: 'Basic ' + btoa(config.clientId + ':' + config.clientSecret),
  skip: 'skip',
});
