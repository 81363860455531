<div class="p-px-4 p-pt-3">
  <div class="row">
    <div class="col-md-4 col-12">
      <div class="nin-otp">
        <img src="assets/icons/otp.png" />
      </div>
    </div>
    <div class="col-md-8 col-12" *ngIf="showOTP">
      <div class="p-col-12 p-mb-5">
        <h5 class="text-muted" translate>pri.otp.otp_info</h5>
      </div>
      <div class="p-grid p-fluid">
        <div class="p-col-12">
          <ng-otp-input
            (onInputChange)="handleChange($event)"
            [config]="{ length: 6 }"
          ></ng-otp-input>
        </div>
        <div class="p-mt-5">
          <div class="p-d-flex p-flex-row p-jc-start p-ai-center">
            <div class="p-p-2 p-jc-center p-ai-center">
              <i class="pi pi-sync"></i>
            </div>
            <div class="text-muted p-ml-2" translate>pri.otp.btn_request</div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-md-8 col-12" *ngIf="!showOTP">
      <div class="">
        <div class="col-12">
          <h5 class="text-muted" translate>pri.otp.info</h5>
        </div>
        <div class="nin-otp-form p-mt-5">
          <form [formGroup]="otpform" autocomplete="off" novalidate>
            <div class="p-fluid p-formgrid p-grid">
              <div class="p-field p-col-12">
                <span class="p-float-label">
                  <p-inputMask
                    formControlName="nin"
                    [required]="true"
                    mask="99999999-99999-99999-99"
                  >
                  </p-inputMask>
                  <label for="inputUsername"
                    >National Identification Number</label
                  >
                </span>
              </div>
            </div>
            <div class="p-fluid p-formgrid p-grid">
              <div class="p-field p-col-12">
                <p-inputMask
                  name="phoneNumber"
                  id="phoneNumber"
                  mask="9999999999"
                  formControlName="phoneNumber"
                  placeholder="0715XXXXXX"
                ></p-inputMask>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>

  <div class="p-fluid p-grid p-jc-between p-ai-center p-px-2 p-py-2 p-mt-4">
    <div class="">
      <button
        (click)="close()"
        class="p-button-outlined"
        label="{{ 'pri.otp.btn_close' | translate }}"
        iconPos="left"
        icon="pi pi-times"
        pButton
      ></button>
    </div>

    <div class="" *ngIf="!showOTP">
      <button
        (click)="search(otpform.value)"
        [disabled]="otpform.invalid"
        class="p-button"
        label="{{ 'pri.otp.btn_save' | translate }}"
        iconPos="right"
        icon="pi pi-arrow-right"
        pButton
      ></button>
    </div>

    <div class="" *ngIf="showOTP">
      <!-- <button
              [disabled]="!isValid"
              (click)="getNinDetails()"
              class="p-button p-py-3"
              label="{{ 'pri.otp.btn_save' | translate }}"
              iconPos="left"
              pButton
            ></button> -->
    </div>
  </div>
</div>
