<div>
  <router-outlet></router-outlet>
  <tausi-loader></tausi-loader>
</div>
<p-toast key="toast"></p-toast>
<p-dialog
  header="Session Timeout"
  [(visible)]="displayTimeoutDialog"
  [style]="{ width: '50vw' }"
  [modal]="true"
  [closable]="false"
  [baseZIndex]="1000000000000"
>
  <!-- <p-header>
    <i class="pi pi-info-circle" style="font-size: 3rem"> </i>
    <span style="position: relative; top: -15px; left: 10px">
      Session Timeout
    </span>
  </p-header> -->

  <div class="row p-2">
    <div class="col-12">
      <div class="" fxLayout="column" fxLayoutAlign="space-between center">
        <div>
          <mat-progress-spinner
            mode="determinate"
            [color]="updateColorOnTimeout(couterDifferenceProgress)"
            diameter="120"
            [value]="couterDifferenceProgress"
            aria-label="Rating"
          >
          </mat-progress-spinner>
          <div class="count-timer-container">
            <span class="count-timer-inner">
              {{ countDownWitTime }}
            </span>
          </div>
        </div>
        <p>
          You will be logged out in<b> {{ countDown }} </b>
        </p>
      </div>
    </div>

    <div fxLayout="row" fxLayoutAlign="space-between" class="col-12 mt-3">
      <button
        pButton
        label="Logout"
        icon="pi pi-lock"
        iconPos="left"
        (click)="logout()"
        class="p-button-text p-button-danger px-2"
      ></button>
      <button
        pButton
        class="p-button-outlined p-button-success"
        label="Continue "
        icon="pi pi-arrow-right"
        iconPos="right"
        (click)="continueResetTimeout()"
      ></button>
    </div>
  </div>
</p-dialog>

<!-- <div *ngxPermissionsOnly="['TAUSI_VIEW_USER_PERMISSION', 'TAUSI_CREATE_USER_PERMISSION', 'TAUSI_LIST_USER_PERMISSION', 'TAUSI_SUPER_ADMINISTRATOR_CREATE_ROLE_PERMISSION']">
    <div>You can see this text congrats</div>
</div> -->

<p-dialog
  header="Change Password"
  [(visible)]="displayChangePasswordDialog"
  [style]="{ width: '60vw' }"
  [modal]="true"
  [closable]="false"
  [baseZIndex]="10000000"
>
  <div class="row" fxLayout="row" fxLayoutAlign="center center">
    <div class="col-md-6 mt-3 mb-3">
      <div class="inner-content-section">
        <div fxFlex="100%" class="form-section p-4 bg-white">
          <div class="form-content">
            <form [formGroup]="changePasswordForm" autocomplete="disabled">
              <div class="row">
                <div class="col-12">
                  <div class="p-fluid p-formgrid p-grid">
                    <div class="p-field p-col-12">
                      <mat-form-field class="p-button-sm" appearance="outline">
                        <mat-label>Old Password</mat-label>
                        <input
                          type="password"
                          matInput
                          placeholder="Old Password"
                          formControlName="password"
                          pTooltip="User Password"
                          tooltipPosition="top"
                          required
                          tooltipEvent="focus"
                          autocomplete="disabled"
                        />
                      </mat-form-field>
                    </div>
                  </div>
                </div>

                <div class="col-12">
                  <div class="p-fluid p-formgrid p-grid">
                    <div class="p-field p-col-12">
                      <mat-form-field class="p-button-sm" appearance="outline">
                        <mat-label>New Password</mat-label>
                        <input
                          type="password"
                          matInput
                          placeholder="New Password"
                          formControlName="newPassword"
                          pTooltip="User New Password"
                          tooltipPosition="top"
                          required
                          tooltipEvent="focus"
                          autocomplete="disabled"
                          [type]="hideNewPassword ? 'password' : 'text'"
                        />

                        <mat-icon
                          matSuffix
                          (click)="hideNewPassword = !hideNewPassword"
                          >{{
                            hideNewPassword ? "visibility_off" : "visibility"
                          }}</mat-icon
                        >
                        <mat-hint *ngIf="!newPasswordInput?.value"
                          >Enter your New password</mat-hint
                        >

                        <!-- end -->
                      </mat-form-field>

                      <mat-error
                        *ngIf="
                          changePasswordForm.controls['newPassword'].hasError(
                            'hasNumber'
                          )
                        "
                      >
                        <p><mat-icon>info</mat-icon> Passwords has no Number</p>
                      </mat-error>

                      <mat-error
                        *ngIf="
                          changePasswordForm.controls['newPassword'].hasError(
                            'hasCapitalCase'
                          )
                        "
                      >
                        <p>
                          <mat-icon>info</mat-icon> Passwords has no CapitalCase
                        </p>
                      </mat-error>

                      <mat-error
                        *ngIf="
                          changePasswordForm.controls['newPassword'].hasError(
                            'hasSmallCase'
                          )
                        "
                      >
                        <mat-icon>info</mat-icon> Passwords has no SmallCase
                      </mat-error>

                      <mat-error
                        *ngIf="
                          changePasswordForm.controls['newPassword'].hasError(
                            'hasSpecialCharacters'
                          )
                        "
                      >
                        <mat-icon>info</mat-icon> Passwords has no
                        SpecialCharacters
                      </mat-error>

                      <mat-error
                        *ngIf="
                          changePasswordForm.controls['newPassword'].hasError(
                            'minlength'
                          )
                        "
                      >
                        <mat-icon>info</mat-icon> Passwords has no minLength 8
                      </mat-error>

                      <mat-error
                        *ngIf="
                          changePasswordForm.controls['newPassword'].hasError(
                            'maxlength'
                          )
                        "
                      >
                        <mat-icon>info</mat-icon> Passwords has no maxlength 20
                      </mat-error>
                    </div>
                  </div>
                </div>

                <div class="col-12">
                  <div class="p-fluid p-formgrid p-grid">
                    <div class="p-field p-col-12">
                      <mat-form-field class="p-button-sm" appearance="outline">
                        <mat-label>Confirm New Password</mat-label>
                        <input
                          type="password"
                          matInput
                          placeholder="Confirm New Password"
                          formControlName="confirmNewPassword"
                          pTooltip="User Confirm New Password"
                          tooltipPosition="top"
                          required
                          tooltipEvent="focus"
                          [errorStateMatcher]="matcher"
                          autocomplete="disabled"
                        />
                      </mat-form-field>

                      <mat-error *ngIf="changePasswordForm.hasError('notSame')">
                        New Passwords do not match
                      </mat-error>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-12">
                  <button
                    pButton
                    class="w-100 text-large"
                    [disabled]="changePasswordForm.invalid"
                    (click)="changePassword(changePasswordForm.value)"
                    label="Change Passowrd"
                  ></button>
                  <button mat-flat-button color="primary"></button>
                </div>
              </div>
            </form>

            <div
              class="btn-container d-flex flex-column justify-content-center align-items-center"
            >
              <button
                pButton
                pRipple
                type="button"
                icon="pi pi-power-off"
                class="p-button-rounded p-button-danger mb-3"
                (click)="logout()"
              ></button>

              <p>Logout</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</p-dialog>
<!--
<p-dialog
  header="{{
    displayTausiHttpErrorCustomMessage === true
      ? tausiHttpErrorCustomMessageHeader
      : tausiHttpError?.errorReponse?.statusText
  }}"
  [(visible)]="displayTausiHttpError"
  [style]="{ width: '50vw' }"
  [modal]="true"
>
  <div class="centerDiv mx-2 my-3">
    <div class="p-my-3">
      <i class="pi pi-exclamation-triangle warning" style="font-size: 6rem">
      </i>
    </div>

    <div>
      <p>
        {{
          displayTausiHttpErrorCustomMessage === true
            ? tausiHttpErrorCustomMessageBody
            : tausiHttpError?.message
        }}
      </p>
    </div>
  </div>

  <ng-template pTemplate="footer">
    <p-button
      icon="pi pi-check"
      (click)="displayTausiHttpError = false"
      label="Ok"
      styleClass="p-button-text"
    ></p-button>
  </ng-template>
</p-dialog> -->

<p-confirmDialog
  key="confirmation"
  [style]="{ width: '40vw' }"
  [baseZIndex]="1000000000"
  [rejectVisible]="false"
  acceptLabel="Ok"
  acceptButtonStyleClass="p-button-text"
></p-confirmDialog>

<div id="testEnv" *ngIf="testVersion">
  <div class="container">
    <img src="./../assets/imgs/experiment.svg" />

    <span> TEST&nbsp;&nbsp;VERSION</span>
  </div>
  <div class="bg"></div>
</div>

<p-dialog
  header="Internet Problem"
  [(visible)]="isOnline"
  [modal]="true"
  [style]="{ width: '40vw' }"
  [draggable]="false"
  [resizable]="false"
  [closable]="false"
  [showHeader]="false"
>
  <div class="p-pt-5 p-px-3 d-flex flex-row p-jc-between p-ai-center">
    <i class="pi pi-globe warning p-mr-4" style="font-size: 3.5rem"></i>
    TAUSI application detected that your computer may have lost internet
    connection, please check that you are connected and you can access the
    internet, try accessing a website you know like www.tamisemi.go.tz to verify
    if you are connected. This may also be due to a problem with your network
    card, please check everything related to network before you proceed.
  </div>
  <ng-template pTemplate="footer">
    <p-button
      icon="pi pi-check"
      (click)="isOnline = false"
      label="Ok"
      styleClass="p-button-text"
    ></p-button>
  </ng-template>
</p-dialog>
