<p-panel header="{{ header }}" [toggleable]="true">
  <ng-template pTemplate="icons">
    <button
      mat-icon-button
      class="p-panel-header-icon"
      (click)="menu.toggle($event)"
    >
      <mat-icon class="pi pi-ellipsis-h"></mat-icon>
    </button>
    <p-menu
      appendTo="body"
      #menu
      id="config_menu"
      [model]="items"
      [popup]="true"
    ></p-menu>
  </ng-template>
  <div class="p-p-0" [ngStyle]="{ height: height }">
    <p-table
      [columns]="cols"
      [value]="data"
      responsiveLayout="scroll"
      styleClass="p-datatable-striped"
    >
      <ng-template pTemplate="header" let-columns>
        <tr>
          <th style="width: 3%"></th>
          <th *ngFor="let column of columns" class="{{ column.align }}">
            {{ column.header }}
          </th>
        </tr>
      </ng-template>
      <ng-template
        pTemplate="body"
        let-rowData
        let-columns="columns"
        let-index="rowIndex"
      >
        <tr>
          <td style="width: 3%">{{ index + 1 }}</td>
          <td *ngFor="let column of columns" class="{{ column.align }}">
            <span *ngIf="column?.type == 'number'">{{
              rowData[column.field] | number : "1.0"
              }}</span>
            <span *ngIf="column?.type != 'number'">
              {{
              column.data
                ? (rowData[column.field] | date : column.format)
                : rowData[column.field]
              }}
            </span>
          </td>
        </tr>
      </ng-template>
      <ng-template
        pTemplate="footer"
        let-columns="columns"
        *ngIf="category != ''"
      >
        <tr>
          <td [attr.colspan]="nColumns">
            <button
              pButton
              class="p-button-text p-button-sm p-py-0"
              label="View All"
              icon="pi pi-eye"
              iconPos="right"
              (click)="viewAllReport()"
            ></button>
          </td>
        </tr>
      </ng-template>
    </p-table>
  </div>
</p-panel>
