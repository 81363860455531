<div class="dynamic-dialog">
  <div class="p-0">
    <div class="mt-3 mb-4 submit" fxLayout="row" fxLayoutAlign="space-between">
      <div
        class="flex-grow-1 flex justify-content-start flex-wrap py-3 border-round"
      >
        <span class="mt-2"
          >Title : <b>{{ scan?.title }}</b></span
        >
        <b class="m-2">|</b>
        <span class="mt-2"
          >Date : <b>{{ scan?.date | date : "medium" }}</b></span
        >
      </div>
      <div style="cursor: pointer">
        <i class="pi pi-times pointer" (click)="close()">Close</i>
      </div>
    </div>
  </div>
  <div class="dialog-content">
    <div class="map" id="map"></div>
  </div>
</div>
