import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Input,
  ElementRef,
  ViewChild,
} from '@angular/core';
import Map from 'ol/Map';

@Component({
  selector: 'tausi-map',
  templateUrl: './map.component.html',
  styleUrls: ['./map.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MapComponent implements OnInit {
  @ViewChild('mapContent', { static: true }) mapContent!: ElementRef;
  @Input() map!: Map;

  constructor() {}

  ngOnInit() {
    this.map.setTarget(this.mapContent.nativeElement);
  }
}
