import { Component, OnInit } from '@angular/core';
import {FormBuilder, Validators} from "@angular/forms";
import {TranslateConfigService} from "../../../shared/services/translate.service";
import {TranslateService} from "@ngx-translate/core";
import {LandSalesService} from "../../../shared/services/land-sales.service";
import {SidenavService} from "../../service/side-nav-service";
import {UserStorageService} from "../../../shared/services/user-storage.service";
import {ActivatedRoute} from "@angular/router";

@Component({
  selector: 'tausi-map-filter',
  templateUrl: './map-filter.component.html',
  styleUrls: ['./map-filter.component.scss']
})
export class MapFilterComponent implements OnInit {
  public councils!:any;
  public projects:any[]=[];
  public lotUses:any[]=[];
  public councilCode?: string;
  public projectId?: string;
  public geoJson:any[]=[];
  private project!:any;
  private projectTypeCode!:string;

  clonedFilter: { [s: string]: any; } = {};

  //Search form
  public filterForm = this._formBuilder.group({
    region_id: [''],
    council_id: [''],
    project_id: ['', Validators.required],
    mini_price: [''],
    max_price: [''],
    mini_area: [''],
    max_area: [''],
    lot_use: [''],
  });
  constructor(
    private _translateSvc: TranslateConfigService,
    private _formBuilder: FormBuilder,
    private _translate: TranslateService,
    private _storageSvc: UserStorageService,
    private _landSalesSvc: LandSalesService,
    private sidenavService: SidenavService
  ) {
    this.sidenavService.selectedAdminCode$.subscribe((value) => {
      this.councilCode = value;
    });
    this.sidenavService.selectedProjectId$.subscribe((value) => {
      this.projectId = value;
    });
    this.sidenavService.selectedProjectTypeCode$.subscribe((value) => {
      this.projectTypeCode = value;
    });
    this.clonedFilter['form'] = {...this.filterForm.value};
  }

  ngOnInit(): void {
    setTimeout(() => {
      this. fetchCouncilSummary(0,100);
      this.fetchCouncilProject(this.councilCode!,0,100);
      this.fetchLotUse();
    }, 0);
  }


  filterHandler(): void {
    if(this.clonedFilter['form'] !== this.filterForm.value){
      this.searchOpenLandProjectMap(0,1000);
      this.clonedFilter['form'] = this.filterForm.value;
    }
  }


  changeAdminArea(administrativeAreaCode:any){
    this.fetchCouncilProject(administrativeAreaCode,0,1000);
  }

  /**
   * get Council With Projects
   */
  fetchCouncilProject(adminCode:string,pageNo:number,pageSize:number): void {
    this._landSalesSvc.getCouncilProjects(adminCode,pageNo,pageSize).subscribe((response) => {
      if (response.statusCode === '21000') {
        this.projects = response.data.itemList;
      }
    });
  }

  /**
   * get all Lot Use
   */
  fetchLotUse(): void {
    this._landSalesSvc.getLotUse().subscribe((response) => {
      if (response.statusCode === '21000') {
        this.lotUses = response.data.itemList;
      }
    });
  }

  toggleFilter(){
    let filter = this.sidenavService.toggleLeft();
    this.sidenavService.setFilter(filter);
  }

  /**
   * Search Projects Map
   */
  searchOpenLandProjectMap(pageNo:number,pageSize:number): void {
    let payload = {
      projectId: this.projectId,
      minPrice: this.filterForm.value.minPrice?this.filterForm.value.minPrice:0,
      maxPrice: this.filterForm.value.max_price?this.filterForm.value.max_price:0,
      minLegalArea: this.filterForm.value.mini_area?this.filterForm.value.mini_area:0,
      maxLegalArea: this.filterForm.value.max_area?this.filterForm.value.max_area:0,
      lotUse: this.filterForm.value.lot_use
    }
    if(this.projectTypeCode === 'LR'){
      this._landSalesSvc.regularizationLandProjectMap(payload,pageNo,pageSize).subscribe((response) => {
        if (response.statusCode === '21000') {
          this.sidenavService.setGeoJson(
            {
              geoJson:response.data.features,
              center:[response.data.center.x,response.data.center.x]
            }
          );
        }else if (response.statusCode ==='21003'){
          this.sidenavService.setGeoJson(response.data.item);
        }
      });
    }else if(this.projectTypeCode === 'CLS'){
      this._landSalesSvc.privateLandProjectMap(payload,pageNo,pageSize).subscribe((response) => {
        if (response.statusCode === '21000') {
          this.sidenavService.setGeoJson(
            {
              geoJson:response.data.features,
              center:[response.data.center.x,response.data.center.x]
            }
          );
        }else if (response.statusCode ==='21003'){
          this.sidenavService.setGeoJson(response.data.item);
        }
      });
    }else{
      this.sidenavService.setGeoJson([]);
    }
  }

  getProject(projectId:number){
    setTimeout(() => {
      if(this.projects.length > 0){
        this.project = this.projects.find(p => p.projectId = projectId);
        this.sidenavService.setProjectInfo(this.project);
      }
    }, 1000);
  }

  /**
   * get Council With Projects
   */
  fetchCouncilSummary(pageNo:number,pageSize:number): void {
    this._landSalesSvc.getCouncilSummary(pageNo,pageSize).subscribe((response) => {
      if (response.statusCode === '21000') {
        this.councils = response.data.itemList;
      }
    });
  }

}
