import { Component, OnInit } from '@angular/core';
import * as L from 'leaflet';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';

@Component({
  selector: 'tausi-location-on-map',
  templateUrl: './location-on-map.component.html',
  styleUrls: ['./location-on-map.component.scss'],
})
export class LocationOnMapComponent implements OnInit {
  options: any;
  overlays: any[] = [];
  center: any;
  scan: any;
  map?: any;
  constructor(
    public _ref: DynamicDialogRef,
    private _config: DynamicDialogConfig
  ) {
    this.scan = this._config.data;
    const coordinates = this.scan.coordinatePoint;
    this.center = [coordinates!.x, coordinates!.y];
    console.log(this.center);
  }

  ngOnInit(): void {
    this.loadParkingArea();
  }

  addTileLayer() {
    L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
      maxZoom: 100,
      minZoom: 0,
      attribution: 'TAUSI GIS Application Map',
    }).addTo(this.map);
    this.map.attributionControl.setPrefix(false);
  }

  loadParkingArea() {
    const carIcon = L.icon({
      iconUrl: this.scan?.icon,
      iconSize: [30, 30],
      iconAnchor: [22, 94],
      shadowAnchor: [4, 62],
      popupAnchor: [-3, -76],
    });
    this.map = L.map('map', {
      center: this.center,
      zoom: 19,
    });
    this.addTileLayer();
    L.marker(this.center, { icon: carIcon }).addTo(this.map);
  }

  close() {
    this._ref.close();
  }
}
