import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { User } from '../models/user.model';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  private readonly _baseUrl: string = `${environment.apiUrl}`;
  private readonly _serviceUrl: string =
    this._baseUrl + `${environment.endPoints.tausi_integration}`;
  constructor(private _httpClient: HttpClient) {}

  /**
   * Service method for creating a user account.
   * @param payload
   */
  public createAccount(payload: User): Observable<any> {
    return this._httpClient.post<User>(`${this._serviceUrl}/user`, payload);
  }

  /**
   * Service method to fetch all users
   */
  public fetchAll(): Observable<any> {
    return this._httpClient.get<User[]>(`${this._serviceUrl}/user`);
  }

  public sendWelcomeEmail(payload: any): Observable<any> {
    return this._httpClient.post(`${this._serviceUrl}/mail/sendmail`, payload);
  }

  public validateTaxPayer(payload: any): Observable<any> {
    return this._httpClient.post(`${this._serviceUrl}/api/v1/nin`, payload);
  }

  public taxpayerByTin(payload: any): Observable<any> {
    return this._httpClient.post(`${this._serviceUrl}/api/v1/tin`, payload);
  }

  public syncTaxClearance(taxClearanceNumber: number): Observable<any> {
    return this._httpClient.get(`${this._serviceUrl}/api/v1/certificate/cert-no/${taxClearanceNumber}`);
  }

}
