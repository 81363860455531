<div class="sidenav_container">
  <div class="sub-section">
    <div class="header-action">
      <span>Advance Filter</span>
      <button pButton pRipple type="button" (click)="toggleFilter()" icon="pi pi-times" class="p-button-rounded p-button-text p-button-plain"></button>
    </div>
    <form [formGroup]="filterForm" autocomplete="off" novalidate>
      <div class="p-fluid p-formgrid p-grid">
        <div class="p-field p-col-12">
            <span class="p-float-label">
              <p-dropdown
                appendTo="body"
                formControlName="council_id"
                [options]="councils"
                (ngModelChange)="changeAdminArea($event)"
                [(ngModel)]="councilCode"
                optionLabel="administrativeAreaName"
                optionValue="administrativeAreaCode"
                [autoDisplayFirst]="false"
                id="council_id"
              ></p-dropdown>
              <label for="council_id"
              >Council</label
              >
            </span>
        </div>
      </div>
      <div class="p-fluid p-formgrid p-grid">
        <div class="p-field p-col-12">
            <span class="p-float-label">
              <p-dropdown
                appendTo="body"
                formControlName="project_id"
                [options]="projects"
                optionLabel="projectName"
                optionValue="projectId"
                [ngModel]="projectId"
                (ngModelChange)="getProject($event)"
                [filter]="true"
                [autoDisplayFirst]="false"
                id="project_id"
              ></p-dropdown>
              <label for="project_id"
              >Projects</label
              >
            </span>
        </div>
      </div>
      <h5>Price Range</h5>
      <div class="p-fluid p-formgrid p-grid">
        <div class="p-field p-col-6">
              <span class="p-float-label">
                <input
                  id="mini_price"
                  type="number"
                  formControlName="mini_price"
                  placeholder="Min"
                  pInputText
                />
              </span>
        </div>
        <div class="p-field p-col-6">
                <span class="p-float-label">
                  <input
                    id="max_price"
                    type="number"
                    formControlName="max_price"
                    placeholder="Max"
                    pInputText
                  />
                </span>
        </div>
      </div>
      <h5>Area Size</h5>
      <div class="p-fluid p-formgrid p-grid">
        <div class="p-field p-col-6">
              <span class="p-float-label">
                <input
                  id="mini_area"
                  type="number"
                  formControlName="mini_area"
                  placeholder="Min"
                  pInputText
                />
              </span>
        </div>
        <div class="p-field p-col-6">
                <span class="p-float-label">
                  <input
                    id="max_area"
                    type="number"
                    formControlName="max_area"
                    placeholder="Max"
                    pInputText
                  />
                </span>
        </div>
      </div>
      <div class="p-fluid p-formgrid p-grid">
        <div class="p-field p-col-12">
            <span class="p-float-label">
              <p-dropdown
                class="p-float-label"
                appendTo="body"
                [showClear]="true"
                formControlName="lot_use"
                [options]="lotUses"
                [filter]="true"
                placeholder="Select Lot Use"
                optionLabel="name"
                optionValue="code"
                [autoDisplayFirst]="false"
                inputId="lot_use"
                id="lot_use"
              ></p-dropdown>
              <label for="lot_use"
              >Lot Use</label
              >
            </span>
        </div>
      </div>
      <div class="p-fluid p-formgrid p-grid">
        <div class="p-field p-col-12 action-btn">
          <button  pButton (click)="filterHandler()" [disabled]="!this.filterForm.valid" pRipple type="button" label="Search"></button>
        </div>
      </div>
    </form>
  </div>
</div>
