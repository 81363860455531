import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'tausi-agent-min-details',
  templateUrl: './agent-min-details.component.html',
  styleUrls: ['./agent-min-details.component.scss'],
})
export class AgentMinDetailsComponent implements OnInit {
  @Input() nida!: any;
  constructor() {}

  ngOnInit(): void {}
}
