<!-- <mat-toolbar class="mat-elevation-z1">
  <mat-toolbar-row>
    <button mat-icon-button id="menu" (click)="toggleDrawer()">
      <mat-icon>menu</mat-icon>
    </button>
    <span class="title">
      <tausi-logo class="p-mx-2" width="40" imageName="tausi_logo"></tausi-logo>
      TAUSI
    </span>
    <span class="spacer"></span> -->

<button mat-icon-button class="" aria-label="network">
  <mat-icon matBadgeColor="warn">{{
    status == 1 ? "wifi_on" : "wifi_off"
  }}</mat-icon>
</button>
<button mat-icon-button aria-label="Dashboard">
  <mat-icon>apps</mat-icon>
</button>
<button mat-icon-button class="" aria-label="notifications">
  <mat-icon matBadge="6" matBadgeColor="warn">notifications</mat-icon>
</button>
<span class="username p-mx-3" *ngIf="user">{{ fullName | titlecase }}</span>

<span>
  <div class="profile_image">
    <div class="" style="width: 50px; height: 50px">
      <img
        [src]="transform(user?.avatar)"
        *ngIf="user?.avatar"
        alt=""
        (click)="menu.toggle($event)"
      />
    </div>
  </div>
</span>
<button mat-icon-button class="my-5" (click)="menu.toggle($event)">
  <mat-icon>more_vert</mat-icon>
</button>
<!-- </mat-toolbar-row>
</mat-toolbar> -->

<p-menu appendTo="body" #menu [popup]="true" [model]="items"></p-menu>
