import { Component, OnInit } from '@angular/core';
import { TausiService } from 'src/app/core/tausi.service';
import { TranslateConfigService } from '../../services/translate.service';
import { DialogService } from 'primeng/dynamicdialog';
import { StepperFormComponent } from '../stepper-form/stepper-form.component';

@Component({
  selector: 'tausi-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent implements OnInit {
  public configuration: any;
  public version?: string;
  public owner?: string;
  public copyright?: string;
  public year?: string;
  public selectedItem?: string;

  public languages: any;

  constructor(
    private _dialogSvc: DialogService,
    private _translateSvc: TranslateConfigService,
    private _config: TausiService
  ) {
    this.configuration = this._config.loadConfiguration();
  }

  ngOnInit() {
    this.languages = this._translateSvc.languageList;
    this.version = this.configuration.version;
    this.owner = this.configuration.owner;
    this.year = this.configuration.year;
    this.copyright = this.configuration.copyright;
  }

  public changeLang(item: any) {
    this.selectedItem = item.title;
    this._translateSvc.changeLanguage(item.countryCode);
  }

  whyTausi() {
    const ref = this._dialogSvc.open(StepperFormComponent, {
      header: 'Password Reset ',
      showHeader: false,
      width: '30%',
      closable: true,
    });
  }
}
