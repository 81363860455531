<div class="sidenav_container">
  <div class="sub-section">
    <div class="header-action">
      <button pButton pRipple type="button" (click)="toggleInfo()" icon="pi pi-times" class="p-button-rounded p-button-text p-button-plain"></button>
      <span class="bar-tittle">Plot Details</span>
    </div>
    <p-divider></p-divider>
    <div class="bar-content">
      <div class="row" *ngFor="let feature of featureToDisplay; index as i;">
        <div class="col-6"><b>{{feature?.name}}</b></div>
        <div *ngIf="feature?.code!=='plotStatus'" class="col-6">{{selectedPlotInfo?.[feature?.code]}}</div>
        <div *ngIf="feature?.code =='plotStatus'" class="col-6">{{(selectedPlotInfo?.[feature?.code]==3 || selectedPlotInfo?.[feature?.code]==7)?'Available':'Sold-Out'}}</div>
      </div>
      <div class="row" *ngIf="(selectedPlotInfo?.plotStatus==3 || selectedPlotInfo?.plotStatus==7)">
        <div class="col-12">
          <button  pButton pRipple type="button" (click)="buyPlot()"  label="Assign Plot"></button>
        </div>
      </div>
    </div>
  </div>
</div>
<p-confirmDialog appendTo="body" [style]="{width: '60%'}" key="plot"  [baseZIndex]="10000" rejectButtonStyleClass="p-button-outlined"></p-confirmDialog>

