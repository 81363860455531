import { Injectable } from '@angular/core';
import { Action, NgxsOnInit, Selector, State, StateContext } from '@ngxs/store';
import { dashboardData } from './parking-dashboard.actions';
import { ParkingDashboardStateModel } from './parking-dashboard.model';
import { ParkingService } from 'src/app/shared/services/parking.service';

@State<ParkingDashboardStateModel>({
  name: 'parkingDashboard',
  defaults: {
    data: {
      data: {
        badScanByCollectors: [],
        nonVerifiedScan: null,
        todayCollection: null,
        monthlyCollection: [],
        collectionBySource: [],
        pendingReserved: null,
        currentYearCollection: null,
      },
      lastUpdate: '',
    },
    loading: false,
  },
})
@Injectable({
  providedIn: 'root',
})
export class ParkingDashboardState implements NgxsOnInit {
  constructor(private _parkingSvc: ParkingService) {}

  @Selector()
  static loadDashboardData(state: ParkingDashboardStateModel) {
    return state.data;
  }

  @Action(dashboardData)
  loadDashboardData(
    ctx: StateContext<ParkingDashboardStateModel>,
    action: dashboardData
  ) {
    const state = ctx.getState();

    this._parkingSvc.getDashboardData().subscribe((response) => {
      if (response.statusCode === '21000') {
        const data = response.data.item;
        ctx.patchState({
          data: { data: data, lastUpdate: dashboardData?.type },
        });
      }
    });
  }

  ngxsOnInit(ctx: StateContext<any>): void {}
}
