import * as moment from 'moment';

import { BehaviorSubject, Observable } from 'rxjs';
import {
  LogoutOauthToken,
  SignInOauthToken,
  UserSwitchingAccount,
  UserSwitchingAccountArray,
} from 'src/app/shared/models/auth.interface';
import { LoadCurrentUser, LogoutCurrentUser } from 'src/app/store/root.actions';

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';
import { Store } from '@ngxs/store';
import { DialogService } from 'primeng/dynamicdialog';
import { map } from 'rxjs/operators';
import { TokenStorageService } from 'src/app/shared/services/token-storage.service';
import { UserUaaService } from 'src/app/user-management/services/user-uaa.service';
import { environment } from 'src/environments/environment';
import { AuthHeaders } from '../utils/auth.headers';
import { UserStorageService } from './user-storage.service';

export const jwtHelper = new JwtHelperService();

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private apiUrl: string =
    environment.apiUrl + environment.endPoints.uaa_service;

  oauthLogin$!: Observable<any[]>;
  oauthRefreshToken$!: Observable<any[]>;
  oauthLogout$!: Observable<any[]>;

  refreshTokenTimeout!: ReturnType<typeof setTimeout>;

  isLoggedIn$ = new BehaviorSubject<boolean>(this.isAuthenticated());
  isLogoutBtnClicked$ = new BehaviorSubject<boolean>(false);

  currentUser$ = new BehaviorSubject<any>(this.getCurrentUser());

  activeShwitchedAccount$ = new BehaviorSubject<UserSwitchingAccount>(
    this.getSwitchedAccount()
  );

  listOfSwitchingAccounts$ = new BehaviorSubject<UserSwitchingAccountArray>(
    this.getSwitchingAccounts()
  );

  listOfPermissions$ = new BehaviorSubject<any>(this.getPermissions());

  isEverythingLoaded$ = new BehaviorSubject<boolean>(
    this.getIfIsEverythingLoadedOrNo()
  );

  constructor(
    private _store: Store,
    public _dialogService: DialogService,
    private _httpClient: HttpClient,
    private _tokenStorageService: TokenStorageService,
    private _router: Router,
    private _userUaaService: UserUaaService,
    private _userStorageService: UserStorageService
  ) {}

  refreshToken() {
    const token: any = this._tokenStorageService.getTokenObject();
    const _refresh_token = encodeURIComponent(token.refreshToken);
    const body = `refresh_token=${_refresh_token}&grant_type=refresh_token`;
    const headers = AuthHeaders;

    this.oauthRefreshToken$ = this._httpClient.post<any>(
      `${this.apiUrl}/oauth/token`,
      body,
      { headers }
    );
    this.oauthRefreshToken$.subscribe(
      (refreshTokenResponse: any) => {
        let signInOauthTokenObj: SignInOauthToken = {
          accessToken: refreshTokenResponse?.access_token,
          refreshToken: refreshTokenResponse?.refresh_token,
          expiresIn: refreshTokenResponse?.expires_in,
          jti: refreshTokenResponse?.jti,
          scope: refreshTokenResponse?.scope,
          tokenType: refreshTokenResponse?.token_type,
        };
        this._tokenStorageService.saveTokenObject(signInOauthTokenObj);
        this.isLoggedIn$.next(this.isAuthenticated());
        this.getUserPrincipleDetails();
        this.startRefreshTokenTimer();
      },
      (refreshTokenError: any) => {
        this.logoutLocal();
      }
    );
  }

  /**
   * authenticate user
   * @param payload
   */
  login(payload: any, isSwitching?: boolean): Observable<any> {
    //if (isSwitching) {
    this.clearAuthStorage();
    //}

    const _username = encodeURIComponent(payload.username);
    const _password = encodeURIComponent(payload.password);

    const body = `username=${_username}&password=${_password}&grant_type=password`;
    const headers = AuthHeaders;

    this.oauthLogin$ = this._httpClient.post<any>(
      `${this.apiUrl}/oauth/token`,
      body,
      { headers }
    );
    return this.oauthLogin$.pipe(
      map((response: any) => {
        let signInOauthTokenObj: SignInOauthToken = {
          accessToken: response?.access_token,
          refreshToken: response?.refresh_token,
          expiresIn: response?.expires_in,
          jti: response?.jti,
          scope: response?.scope,
          tokenType: response?.token_type,
        };

        this._tokenStorageService.saveTokenObject(signInOauthTokenObj);
        this.isLoggedIn$.next(this.isAuthenticated());
        this.startRefreshTokenTimer();

        this.getUserPrincipleDetails();
        this._userStorageService.removeLockScreen();

        return response;
      })
    );
  }

  public logoutLocal() {
    this._store.dispatch(new LogoutCurrentUser());
    this.clearAuthStorage();
    this.stopRefreshTokenTimer();
    this.isLoggedIn$.next(this.isAuthenticated());
    this._router.navigateByUrl('/signin');
  }

  public logout() {
    let userDetailToLogout: any;
    this.currentUser$.subscribe((user) => {
      userDetailToLogout = user;
    });

    let logoutPayload: LogoutOauthToken = {
      clientId: environment.authConfig.clientId,
      username: userDetailToLogout?.username,
    };

    this.oauthLogout$ = this._httpClient.post<any[]>(
      `${this.apiUrl}/api/v1/user/logout`,
      logoutPayload
    );

    return this.oauthLogout$.pipe(
      map((response: any) => {
        this.clearAuthStorage();
        this.stopRefreshTokenTimer();
        this.isLoggedIn$.next(this.isAuthenticated());
        this._store.dispatch(new LogoutCurrentUser());
        this._router.navigateByUrl('/signin');
        return response;
      })
    );
  }

  getSwitchingAccounts(): UserSwitchingAccountArray {
    return this._userStorageService.getSwitchingAccounts();
  }

  saveSwitchingAccounts(accounts: UserSwitchingAccountArray) {
    this._userStorageService.saveSwitchingAccounts(accounts);
    this.listOfSwitchingAccounts$.next(accounts);
  }

  /**
   * user details
   * @returns
   */
  getCurrentUser(): any {
    return this._userStorageService.getUserDetails();
  }

  getSwitchedAccount(): UserSwitchingAccount {
    return this._userStorageService.getCurrentSwitchedAccount();
  }

  saveSwitchedAccount(account: UserSwitchingAccount) {
    this._userStorageService.saveCurrentSwitchedAccount(account);
    this.activeShwitchedAccount$.next(account);
  }

  getPermissions() {
    return this._userStorageService.getPermissions();
  }

  savePermissions(authorities: any) {
    let permissions = authorities.map((a: any) => a.authority);

    this._userStorageService.savePermissions(permissions);
    this.listOfPermissions$.next(permissions);
  }

  getIfIsEverythingLoadedOrNo() {
    // check if all Storage is available
    return this._userStorageService.checkIfStorageHasUserData();
  }

  setIfIsEverythingLoadedOrNo(flag: boolean) {
    this.isEverythingLoaded$.next(flag);
  }

  public clearAuthStorage() {
    this._tokenStorageService.signOut();
    this._userStorageService.signOut();
  }

  public isAuthenticated(): boolean {
    const tokenObj: SignInOauthToken =
      this._tokenStorageService.getTokenObject();

    if (tokenObj === null || tokenObj.accessToken === null) {
      return false;
    }

    return !jwtHelper.isTokenExpired(tokenObj.accessToken);
  }

  isTokenHasExpireOrSoon(): boolean {
    const tokenObj: SignInOauthToken =
      this._tokenStorageService.getTokenObject();

    if (tokenObj === null || tokenObj.accessToken === null) {
      return false;
    }

    // change remove ! ( leave ! for testing)
    if (jwtHelper.isTokenExpired(tokenObj.accessToken)) {
      // token expired
      const expirationDate = jwtHelper.getTokenExpirationDate(
        tokenObj.accessToken
      );
      return true;
    } else {
      // token valid
      return false;
    }
  }

  setSitchedAccoutUponFetchingUserPrinciple(account: UserSwitchingAccount) {
    this.saveSwitchedAccount(account);
  }

  // user information

  // for auth
  getUserPrincipleDetails(refetchingWhenNoInStorage?: boolean) {
    if (this.isAuthenticated()) {
      if (!refetchingWhenNoInStorage) {
        this.setIfIsEverythingLoadedOrNo(false);
      }

      this._userUaaService
        .getUserPrincipleDetails()
        .subscribe((response: any) => {
          let userDetailsRes = response?.principal;
          this._store.dispatch(new LoadCurrentUser(userDetailsRes));
          let userRoleAdministrativeAreaAccountType =
            response?.principal?.userRoleAdministrativeAreaAccountType;

          let userSwitchingAdministrativeAreaList =
            response?.principal?.userSwitchingAdministrativeAreaList;

          let userCurrentSwitchedAccountWeb =
            response?.principal?.userCurrentSwitchedAccountWeb;

          let userAuthorityRes = response?.authorities;

          this._userStorageService.saveUserDetails(userDetailsRes);
          this.saveSwitchingAccounts(userSwitchingAdministrativeAreaList);

          this.setSitchedAccoutUponFetchingUserPrinciple(
            userCurrentSwitchedAccountWeb
          );
          this.savePermissions(userAuthorityRes);

          if (!refetchingWhenNoInStorage) {
            this.setIfIsEverythingLoadedOrNo(true);
          }
        });
    }
  }

  // helper methods

  private startRefreshTokenTimer() {
    const tokenObj: SignInOauthToken =
      this._tokenStorageService.getTokenObject();

    const decodedToken = jwtHelper.decodeToken(tokenObj.accessToken);
    const expirationDate = jwtHelper.getTokenExpirationDate(
      tokenObj.accessToken
    );
    const isExpired = jwtHelper.isTokenExpired(tokenObj.accessToken);

    const now = moment(Date.now());
    const expiration = moment(expirationDate);

    // get the difference between the moments
    const diff = expiration.diff(now);

    //express as a duration
    const diffDuration = moment.duration(diff);
    const expirationTimeout = diffDuration.subtract(1, 'minute');

    this.refreshTokenTimeout = setTimeout(() => {
      this.refreshToken();
    }, expirationTimeout.asMilliseconds());
  }

  private stopRefreshTokenTimer() {
    clearTimeout(this.refreshTokenTimeout);
  }
}
