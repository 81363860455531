import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { attachmentType } from 'src/app/property-tax/models/attachment-type.model';
import {
  building,
  buildingReview,
  ownerDetails,
  verifyMeter,
} from 'src/app/property-tax/models/building-data.model';
import { buildingStructure } from 'src/app/property-tax/models/building-structure.model';
import { buildingType } from 'src/app/property-tax/models/building-type.model';
import {
  changeStatusRequest,
  changeStatusReview,
} from 'src/app/property-tax/models/change-status.model';
import {
  chargeRate,
  chargeRateReview,
} from 'src/app/property-tax/models/charge-rate.model';
import { exemptionRequest } from 'src/app/property-tax/models/exemption.model';
import { paymentMode } from 'src/app/property-tax/models/payment-mode.model';
import { useType } from 'src/app/property-tax/models/use-type.model';
import { environment } from 'src/environments/environment';

// @ts-ignore
@Injectable({
  providedIn: 'root',
})
export class PropertyTaxService {
  private base_uri: string = `${environment.apiUrl}${environment.endPoints.property_tax_service}`;
  private odkConfig = environment.odkConfig;
  private odkHeaders = new HttpHeaders()
    .set('Access-Control-Allow-Origin', '*')
    .set('skip', 'true')
    .set(
      'Access-Control-Allow-Headers',
      'Origin, X-Requested-With, Content-Type, Accept, Authorization'
    );
  constructor(private _httpSvc: HttpClient) {}

  /*** building use type */
  getUseTypes(payload: any): Observable<any> {
    return this._httpSvc.get(`${this.base_uri}/api/v1/use-type`, {
      params: payload,
    });
  }

  createUseType(payload: useType): Observable<any> {
    return this._httpSvc.post(`${this.base_uri}/api/v1/use-type`, payload);
  }

  updateUseType(payload: useType): Observable<any> {
    return this._httpSvc.put(
      `${this.base_uri}/api/v1/use-type/id/${payload?.id}`,
      payload
    );
  }

  deleteUseType(id: string): Observable<any> {
    return this._httpSvc.delete(`${this.base_uri}/api/v1/use-type/id/${id}`);
  }

  /** payment mode */
  getPaymentMode(payload: any): Observable<any> {
    return this._httpSvc.get(`${this.base_uri}/api/v1/payment-mode`, {
      params: payload,
    });
  }

  createPaymentMode(payload: paymentMode): Observable<any> {
    return this._httpSvc.post(`${this.base_uri}/api/v1/payment-mode`, payload);
  }

  updatePaymentMode(payload: paymentMode): Observable<any> {
    return this._httpSvc.put(
      `${this.base_uri}/api/v1/payment-mode/id/${payload?.id}`,
      payload
    );
  }

  deletePaymentMode(id: string): Observable<any> {
    return this._httpSvc.delete(
      `${this.base_uri}/api/v1/payment-mode/id/${id}`
    );
  }
  /*** building type */
  getBuildingType(payload: any): Observable<any> {
    return this._httpSvc.get(`${this.base_uri}/api/v1/building-type`, {
      params: payload,
    });
  }

  createBuildingType(payload: buildingType): Observable<any> {
    return this._httpSvc.post(`${this.base_uri}/api/v1/building-type`, payload);
  }

  updateBuildingType(payload: buildingType): Observable<any> {
    return this._httpSvc.put(
      `${this.base_uri}/api/v1/building-type/id/${payload?.id}`,
      payload
    );
  }

  deleteBuildingType(id: string): Observable<any> {
    return this._httpSvc.delete(
      `${this.base_uri}/api/v1/building-type/id/${id}`
    );
  }
  /** building structure */
  getBuildingStructure(payload: any): Observable<any> {
    return this._httpSvc.get(`${this.base_uri}/api/v1/building-structure`, {
      params: payload,
    });
  }

  createBuildingStructure(payload: buildingStructure): Observable<any> {
    return this._httpSvc.post(
      `${this.base_uri}/api/v1/building-structure`,
      payload
    );
  }

  updateBuildingStructure(payload: buildingStructure): Observable<any> {
    return this._httpSvc.put(
      `${this.base_uri}/api/v1/building-structure/id/${payload?.id}`,
      payload
    );
  }

  deleteBuildingStructure(id: string): Observable<any> {
    return this._httpSvc.delete(
      `${this.base_uri}/api/v1/building-structure/id/${id}`
    );
  }

  /*** attachment type */
  getAttachmentType(payload: any): Observable<any> {
    return this._httpSvc.get(`${this.base_uri}/api/v1/attachment-type`, {
      params: payload,
    });
  }

  createAttachmentType(payload: attachmentType): Observable<any> {
    return this._httpSvc.post(
      `${this.base_uri}/api/v1/attachment-type`,
      payload
    );
  }

  updateAttachmentType(payload: attachmentType): Observable<any> {
    return this._httpSvc.put(
      `${this.base_uri}/api/v1/attachment-type/id/${payload?.id}`,
      payload
    );
  }

  deleteAttachmentType(id: string): Observable<any> {
    return this._httpSvc.delete(
      `${this.base_uri}/api/v1/attachment-type/id/${id}`
    );
  }

  /** register buildings */
  getBuildings(payload: any): Observable<any> {
    return this._httpSvc.get(`${this.base_uri}/api/v1/building`, {
      params: payload,
    });
  }

  createBuilding(payload: building): Observable<any> {
    return this._httpSvc.post(`${this.base_uri}/api/v1/building`, payload);
  }

  updateBuilding(payload: building): Observable<any> {
    return this._httpSvc.put(
      `${this.base_uri}/api/v1/building/id/${payload?.id}`,
      payload
    );
  }

  deleteBuilding(id: string): Observable<any> {
    return this._httpSvc.delete(`${this.base_uri}/api/v1/building/id/${id}`);
  }

  findBuilding(id: string): Observable<any> {
    return this._httpSvc.get(`${this.base_uri}/api/v1/building/id/${id}`);
  }

  /**  charge rates */
  getChargeRates(payload: any): Observable<any> {
    return this._httpSvc.get(`${this.base_uri}/api/v1/charge-rate`, {
      params: payload,
    });
  }

  createChargeRates(payload: chargeRate): Observable<any> {
    return this._httpSvc.post(`${this.base_uri}/api/v1/charge-rate`, payload);
  }

  updateChargeRates(payload: chargeRate): Observable<any> {
    return this._httpSvc.put(
      `${this.base_uri}/api/v1/charge-rate/id/${payload?.id}`,
      payload
    );
  }

  deleteChargeRate(id: string): Observable<any> {
    return this._httpSvc.delete(`${this.base_uri}/api/v1/charge-rate/id/${id}`);
  }

  /*** review charge rate */
  reviewChargeRates(payload: chargeRateReview): Observable<any> {
    return this._httpSvc.post(
      `${this.base_uri}/api/v1/charge-rate-review`,
      payload
    );
  }

  /** review building  unit*/

  reviewUnit(payload: buildingReview): Observable<any> {
    return this._httpSvc.post(
      `${this.base_uri}/api/v1/building-unit-review`,
      payload
    );
  }

  /** configure gfs codes */
  getGfsCodes(payload: any): Observable<any> {
    return this._httpSvc.get(`${this.base_uri}/api/v1/gfsCode`, {
      params: payload,
    });
  }

  /** post gfs codes */
  createGfsCodes(payload: chargeRate): Observable<any> {
    return this._httpSvc.post(`${this.base_uri}/api/v1/gfsCode`, payload);
  }

  /** return list of charges */
  getBuildingCharges(id: string, payload: any): Observable<any> {
    return this._httpSvc.get(
      `${this.base_uri}/api/v1/building-unit/buildingId/${id}`,
      { params: payload }
    );
  }

  /** exemption request */
  createExemption(payload: exemptionRequest): Observable<any> {
    return this._httpSvc.post(
      `${this.base_uri}/api/v1/exception-request`,
      payload
    );
  }

  getExemptions(payload: any): Observable<any> {
    return this._httpSvc.get(`${this.base_uri}/api/v1/exception-request`, {
      params: payload,
    });
  }

  getExemptionAttachment(id: string): Observable<any> {
    return this._httpSvc.get(
      `${this.base_uri}/api/v1/get-exception-request-attachment/id/${id}`
    );
  }

  /** review exemption  */
  reviewExemption(payload: exemptionRequest): Observable<any> {
    return this._httpSvc.post(
      `${this.base_uri}/api/v1/exception-request-review`,
      payload
    );
  }

  /** financial year */
  getFinancialYear(): Observable<any> {
    return this._httpSvc.get(`${this.base_uri}/api/v1/financial-years`);
  }

  /** financial year */
  getPreviousFinancialYear(): Observable<any> {
    return this._httpSvc.get(
      `${this.base_uri}/api/v1/previous-financial-years`
    );
  }

  /** payment history */
  getPayments(payload: any): Observable<any> {
    return this._httpSvc.get(`${this.base_uri}/api/v1/payment-history`, {
      params: payload,
    });
  }

  /** demand note */
  getDemandNote(buildingUnitId: string): Observable<any> {
    return this._httpSvc.get(
      `${this.base_uri}/api/v1/get-demand-note/buildingUnitId/${buildingUnitId}`
    );
  }

  /** mass demand note */
  getMassDemandNote(wardCode: string): Observable<any> {
    return this._httpSvc.get(
      `${this.base_uri}/api/v1/get-demand-note/wardCode/${wardCode}`
    );
  }

  /** change status request */
  changeStatusRequest(payload: changeStatusRequest): Observable<any> {
    return this._httpSvc.post(
      `${this.base_uri}/api/v1/building-status-change-request`,
      payload
    );
  }

  /** change status requests */
  getStatusChange(payload: any): Observable<any> {
    return this._httpSvc.get(
      `${this.base_uri}/api/v1/building-status-change-request`,
      { params: payload }
    );
  }

  /** status change attachment */
  getStatusChangeAttachment(id: string): Observable<any> {
    return this._httpSvc.get(
      `${this.base_uri}/api/v1/building-status-change-request-attachment/id/${id}`
    );
  }

  /** status change review */
  changeStatusReview(payload: changeStatusReview): Observable<any> {
    return this._httpSvc.post(
      `${this.base_uri}/api/v1/building-status-change-request-review`,
      payload
    );
  }

  /** dashboard APIs */
  getWardCollections(): Observable<any> {
    return this._httpSvc.get(`${this.base_uri}/api/v1/ward-collections`);
  }

  getUnPaidBills(): Observable<any> {
    return this._httpSvc.get(`${this.base_uri}/api/v1/unpaid-amount`);
  }

  getRegisteredBuildings(): Observable<any> {
    return this._httpSvc.get(
      `${this.base_uri}/api/v1/registered-building-details`
    );
  }

  getMonthlyCollections(): Observable<any> {
    return this._httpSvc.get(`${this.base_uri}/api/v1/monthly-collections`);
  }

  getCurrentMonthCollection(): Observable<any> {
    return this._httpSvc.get(
      `${this.base_uri}/api/v1/current-month-collection`
    );
  }

  getTotalCollection(): Observable<any> {
    return this._httpSvc.get(
      `${this.base_uri}/api/v1/current-financial-year-collection`
    );
  }

  getAllRegisteredBuilding(): Observable<any> {
    return this._httpSvc.get(`${this.base_uri}/api/v1/all-registered-building`);
  }

  /** exemption reason */
  getExemptionReason(payload: any): Observable<any> {
    return this._httpSvc.get(`${this.base_uri}/api/v1/exception-reason`, {
      params: payload,
    });
  }

  createExemptionReason(payload: chargeRate): Observable<any> {
    return this._httpSvc.post(
      `${this.base_uri}/api/v1/exception-reason`,
      payload
    );
  }

  updateExemptionReason(payload: chargeRate): Observable<any> {
    return this._httpSvc.put(
      `${this.base_uri}/api/v1/exception-reason/id/${payload?.id}`,
      payload
    );
  }

  deleteExemptionReason(id: string): Observable<any> {
    return this._httpSvc.delete(
      `${this.base_uri}/api/v1/exception-reason/id/${id}`
    );
  }

  /** attachment type exception reason */
  getAttachmentTypeReason(payload: any): Observable<any> {
    return this._httpSvc.get(
      `${this.base_uri}/api/v1/attachment-type-exception-reason`,
      {
        params: payload,
      }
    );
  }

  createAttachmentTypeReason(payload: any): Observable<any> {
    return this._httpSvc.post(
      `${this.base_uri}/api/v1/attachment-type-exception-reason`,
      payload
    );
  }

  /** return list of building unit charges */
  getBuildingUnitCharges(id: string, payload: any): Observable<any> {
    return this._httpSvc.get(
      `${this.base_uri}/api/v1/current-building-unit-charges/id/${id}`,
      { params: payload }
    );
  }

  /** verify meter number */
  verifyMeterNumber(payload: verifyMeter): Observable<any> {
    return this._httpSvc.post(
      `${this.base_uri}/api/v1/building-unit-meter-verify`,
      payload
    );
  }

  /** update building unit */
  updateBuildingUnit(payload: ownerDetails, id: string): Observable<any> {
    return this._httpSvc.put(
      `${this.base_uri}/api/v1/building-unit/id/${id}`,
      payload
    );
  }

  /** return building images */
  getBuildingImages(buildingImageId: string): Observable<any> {
    return this._httpSvc.get(
      `${this.base_uri}/api/v1/building/buildingImageId/${buildingImageId}`
    );
  }
  /*** approved building */
  getApprovedBuildings(payload: any): Observable<any> {
    return this._httpSvc.get(`${this.base_uri}/api/v1/approved-building`, {
      params: payload,
    });
  }

  /** get files */
  getUploadedFiles(payload: any): Observable<any> {
    return this._httpSvc.get(
      `${this.base_uri}/api/v1/get-csv-import-new-meter-summary`,
      {
        params: payload,
      }
    );
  }
  /*** import new meter data */
  importNewMeterFile(payload: any): Observable<any> {
    return this._httpSvc.post(
      `${this.base_uri}/api/v1/csv-import-new-meter`,
      payload
    );
  }

  /** import meter deductions  */
  importMeterDeductionFile(payload: any): Observable<any> {
    return this._httpSvc.post(
      `${this.base_uri}/api/v1/csv-import-deduction`,
      payload
    );
  }

  /** get data import summary */
  getImportSummary(id: string): Observable<any> {
    return this._httpSvc.get(
      `${this.base_uri}/api/v1/get-csv-import-new-meter-summary/${id}  `
    );
  }

  /** get data collectors */
  getOdkForm(): Observable<any> {
    const project = this.odkConfig.property_project;
    return this._httpSvc.get(
      `${this.base_uri}/api/v1/get-council-odk-form?projectNumber=${project}`
    );
  }

  /** get data collector */
  getDataCollector(projectId: number, formId: string): Observable<any> {
    return this._httpSvc.get(
      `/v1/projects/${projectId}/forms/${formId}/assignments/2`,
      { headers: this.odkHeaders }
    );
  }

  /** create data collector */
  createDataCollector(projectId: number, payload: any): Observable<any> {
    return this._httpSvc.post(`/v1/projects/${projectId}/app-users`, payload, {
      headers: this.odkHeaders,
    });
  }

  /** assign collector */
  assignDataCollector(
    projectId: number,
    formId: number,
    collectorId: number
  ): Observable<any> {
    return this._httpSvc.post(
      `/v1/projects/${projectId}/forms/${formId}/assignments/2/${collectorId}`,
      {},
      {
        headers: this.odkHeaders,
      }
    );
  }
  /** get user token */
  getUserToken(param: any): Observable<any> {
    return this._httpSvc.get(`${this.base_uri}/api/v1/get-app-users`, {
      params: param,
    });
  }

  /** revoke collector */
  revokeDataCollector(token: string): Observable<any> {
    return this._httpSvc.delete(`/v1/sessions/${token}`, {
      headers: this.odkHeaders,
    });
  }
  /** unassign data collector */
  unassignDataCollector(
    projectId: number,
    formId: string,
    roleId: number,
    userId: number
  ): Observable<any> {
    return this._httpSvc.delete(
      `/v1/projects/${projectId}/forms/${formId}/assignments/${roleId}/${userId}`,
      {
        headers: this.odkHeaders,
      }
    );
  }
  /**
   *
   * @returns report list
   */
  getReportList(): Observable<any> {
    return this._httpSvc.get(`${this.base_uri}/api/v1/setup`);
  }
  /**generate report */
  generateReport(payload: any): Observable<any> {
    return this._httpSvc.post(
      `${environment.apiUrl}${payload.url}`,
      payload.data
    );
  }

  //get institution
  getInstitutionType(payload: any): Observable<any> {
    return this._httpSvc.get(`${this.base_uri}/api/v1/institution-type`, {
      params: payload,
    });
  }

  //create allocation
  createAllocation(payload: any): Observable<any> {
    return this._httpSvc.post(
      `${this.base_uri}/api/v1/revenue_allocation_ratio`,
      payload
    );
  }

  getAllocation(payload: any): Observable<any> {
    return this._httpSvc.get(
      `${this.base_uri}/api/v1/revenue_allocation_ratio`,
      {
        params: payload,
      }
    );
  }

  //review allocation
  reviewAllocation(payload: any): Observable<any> {
    return this._httpSvc.post(
      `${this.base_uri}/api/v1/revenue_allocation_ratio_review`,
      payload
    );
  }

  /** get previous charges */
  getPreviousBuildingCharge(id: string, payload: any): Observable<any> {
    return this._httpSvc.get(
      `${this.base_uri}/api/v1/building-unit-charges/id/${id}`,
      { params: payload }
    );
  }

  /** get charge rate by structure */
  getChargeRateStructure(payload: any): Observable<any> {
    return this._httpSvc.get(`${this.base_uri}/api/v1/find-charge-rate`, {
      params: payload,
    });
  }

  /** create previous charges */
  createPreviousCharge(payload: any): Observable<any> {
    return this._httpSvc.post(
      `${this.base_uri}/api/v1/create-previous-building-unit-charge`,
      payload
    );
  }
}
