import { Component, Input, OnInit } from '@angular/core';
import { MenuItem } from 'primeng/api';

@Component({
  selector: 'tausi-pie-chart',
  templateUrl: './pie-chart.component.html',
  styleUrls: ['./pie-chart.component.scss'],
})
export class PieChartComponent implements OnInit {
  @Input() data = {};
  @Input() options = { title: { text: '' }, legend: '' };
  @Input() height = '30vh';

  public items!: MenuItem[];

  constructor() {}

  ngOnInit(): void {
    this.items = [
      {
        label: 'Chart Options',
        items: [
          {
            label: 'Change Data',
            icon: 'pi pi-refresh',
          },
          {
            label: 'Refresh View',
            icon: 'pi pi-times',
          },
        ],
      },
    ];
  }
}
