import { Component, OnInit } from '@angular/core';
import { animateText, onSideNavChange } from 'src/app/animations/animations';
import { InteractionService } from '../../services/interaction.service';

interface Page {
  link: string;
  name: string;
  icon: string;
}

@Component({
  selector: 'tausi-sidebar-menu',
  templateUrl: './sidebar-menu.component.html',
  styleUrls: ['./sidebar-menu.component.scss'],
  animations: [onSideNavChange, animateText],
})
export class SidebarMenuComponent implements OnInit {
  public sideNavState: boolean = false;
  public linkText: boolean = false;

  public pages!: Page[];

  constructor(private _interactionSvc: InteractionService) {}

  ngOnInit() {
    this.pages = [
      { name: 'Inbox', link: 'some-link', icon: 'inbox' },
      { name: 'Starred', link: 'some-link', icon: 'star' },
      { name: 'Send email', link: 'some-link', icon: 'send' },
    ];
  }

  onSinenavToggle() {
    this.sideNavState = !this.sideNavState;

    setTimeout(() => {
      this.linkText = this.sideNavState;
    }, 200);
    this._interactionSvc.sideNavState$.next(this.sideNavState);
  }
}
