// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  testVersion: true,
  isProduction: false,
  apiUrl: 'kivuko/',
  endPoints: {
    council_management_service: 'tausi-council-management-service',
    billboard_service: 'tausi-billboard-service',
    property_tax_service: 'tausi-property-tax-service',
    notification_service: 'tausi-notification-service',
    business_license_service: 'tausi-business-license',
    uaa_service: 'tausi-uaa-service',
    tax_payer_service: 'tausi-tax-payer-service',
    pos_service: 'tausi-pos-management-service',
    tausi_integration: 'tausi-integration-service',
    accounting: 'tausi-billing-service',
    gis: 'tausi-gis-service',
    float_management: 'tausi-float-management-service',
    report: 'tausi-report-service',
    fishing_license_service: 'tausi-fishing',
    service_levy_service: 'tausi-levy',
    building_permit_service: 'tausi-building-permit-service',
    land_sales_service: 'tausi-landsales-service',
    land_rent_service: 'tausi-landrent-service',
    bus_stand_service: 'tausi-bus-stand-service',
    house_rent_service: 'tausi-house-rent',
    solid_waste_service: 'tausi-solid-waste-service',
    auction_service: 'tausi-auction-management-service',
    external_sources_service: 'tausi-external-sources-service',
    parking: 'tausi-parking-service',
    tamisemi_report: 'tamisemi-report-service',
  },
  authConfig: {
    clientId: 'tausi-web-client',
    clientSecret: 'tausi-web-secret',
  },
  mapbox: {
    accessToken:
      'pk.eyJ1IjoiYnJhc2thbSIsImEiOiJja3NqcXBzbWoyZ3ZvMm5ybzA4N2dzaDR6In0.RUAYJFnNgOnn80wXkrV9ZA',
  },
  authStorageConfig: {
    tokenKey: 'oauthToken',
    userKey: 'oauthUser',
    rootState: 'root',
  },
  korongoConfig: {
    korongoClient: 'tausi-core-web',
  },
  userPrincipleDetails: {
    switchingAccounts: 'switchingAccounts',
    currentSwitchedAccount: 'currentSwitchedAccount',
  },
  watchingUserActivity: {
    idleMillisec: 10 * 10000, // 3 minutes  180000 sec
    idleSensitivityMillisec: 1 * 1000, // 1 Sec
    timeout: 10 * 60, // 90 seconds note: (2 * idleMillisec)
    pingMillisec: 1000, // 30 second - 30000 mil
  },
  userDetails: 'userDetails',
  userPermissions: 'userPermissions',
  lockScreen: 'lockScreen',
  gis: {
    geoserver_daraja: 'geoserver-daraja/geoserver/TausiGIS/wms',
    layers: {
      tausi_gis_layer: 'TausiGIS:Point Geometries',
    },
  },
  odkConfig:{
    server:'https://odktest.tamisemi.go.tz',
    property_project:4
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
