import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { TausiHttpError } from '../models/auth.interface';

@Injectable({
  providedIn: 'root',
})
export class TausiMessagingService {
  _tausiHttpError!: TausiHttpError;
  private tausiHttpErrorBehaviorSubject$ = new BehaviorSubject<TausiHttpError>(
    this._tausiHttpError
  );
  caughtTausiHttpErrorBehaviorSubject$ =
    this.tausiHttpErrorBehaviorSubject$.asObservable();
  changeTausiHttpError(tausiHttpError: TausiHttpError) {
    this.tausiHttpErrorBehaviorSubject$.next(tausiHttpError);
  }

  constructor() {}
}
