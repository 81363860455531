import { Injectable } from '@angular/core';
import { Action, NgxsOnInit, Selector, State, StateContext } from '@ngxs/store';
import { PosManagementService } from 'src/app/shared/services/pos-management.service';
import { Dashboard } from './dashboard.actions';
import { DashboardStateModel } from './dashboard.model';

@State<DashboardStateModel>({
  name: 'dashboard',
  defaults: {
    adminArea: null,
    loading: false,
    cashOnHand: {},
    lastSeen: [],
    collectorCashOnHand: {},
    posRegistration: {},
    posUsageSummary: [],
    onlineOffline: [],
    posCollection: {},
  },
})
@Injectable({
  providedIn: 'root',
})
export class DashboardState implements NgxsOnInit {
  public payload: any;

  constructor(private _posSvc: PosManagementService) {}

  @Selector()
  static posRegistration(state: DashboardStateModel) {
    return state.posRegistration;
  }

  @Selector()
  static lastSeen(state: DashboardStateModel) {
    return state.lastSeen;
  }

  @Selector()
  static onlineOffline(state: DashboardStateModel) {
    return state.onlineOffline;
  }

  @Selector()
  static cashOnHand(state: DashboardStateModel) {
    return state.cashOnHand;
  }

  @Selector()
  static posCollection(state: DashboardStateModel) {
    return state.posCollection;
  }

  @Selector()
  static posUsageSummary(state: DashboardStateModel) {
    return state.posUsageSummary;
  }

  @Selector()
  static collectorCashOnHand(state: DashboardStateModel) {
    return state.collectorCashOnHand;
  }

  @Action(Dashboard.LoadPosCollection)
  loadPosCollection(ctx: StateContext<DashboardStateModel>) {
    this._posSvc.fetchTotalPoSCollection(this.payload).subscribe((response) => {
      if (response.statusCode === '21000') {
        const state = ctx.getState();
        const data = response.data.item;
        ctx.patchState({
          posCollection: data.data.totalAmount,
          cashOnHand: data.cashOnHand,
        });
      }
    });
  }

  ngxsOnInit(ctx: StateContext<any>): void {}
}
