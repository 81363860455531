import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class LandSalesService {
  private readonly baseUrl: string = `${environment.apiUrl}${environment.endPoints.land_sales_service}`;

  constructor(private httpClient: HttpClient) {}

  public getProjectTypes() {
    return this.httpClient.get<any>(
      `${this.baseUrl}/api/v1/setup/land-project-types`
    );
  }

  public getLotUse() {
    return this.httpClient.get<any>(
      `${this.baseUrl}/api/v1/setup/lot-use`
    );
  }

  public getLandSaleRate(page:number,pageSize:number) {
    return this.httpClient.get<any>(
      `${this.baseUrl}/api/v1/land-sales-config/rate?pageNo=${page}&pageSize=${pageSize}`
    );
  }

  public getLandSaleGracePeriod(page:number,pageSize:number) {
    return this.httpClient.get<any>(
      `${this.baseUrl}/api/v1/land-sales-config/grace-period?pageNo=${page}&pageSize=${pageSize}`
    );
  }

  public getSourceType() {
    return this.httpClient.get<any>(
      `${this.baseUrl}/api/v1/setup/source-types`
    );
  }


  public getProjectGeoJson(landProjectId:string) {
      return this.httpClient.get<any>(
        `${this.baseUrl}/api/v1/land-project/geo-database?landProjectId=${landProjectId}`
      );
    }

  public getProjects(status:number,page:number,pageSize:number) {
    return this.httpClient.get<any>(
      `${this.baseUrl}/api/v1/land-project?status=${status}&pageNo=${page}&pageSize=${pageSize}`
    );
  }


  public getLandSaleConfiguration(query:any) {
    return this.httpClient.get<any>(
      `${this.baseUrl}/api/v1/land-sales-config?pageNo=${query.page}&pageSize=${query.pageSize}`
    );
  }

  /**
   * Search Project
   * @param payload
   * @returns
   */
  public searchOpenLandProjectMap(payload: any,pageNo:number,pageSize:number): Observable<any> {
    return this.httpClient.post(
      `${this.baseUrl}/api/v1/land-open-project/search?pageNo=${pageNo}&pageSize=${pageSize}`,
      payload
    );
  }

  /**
   * Create Extension Request
   * @param payload
   * @returns
   */
  public extensionRequest(payload: any): Observable<any> {
    return this.httpClient.post(
      `${this.baseUrl}/api/v1/land-project-extension`,
      payload
    );
  }

  public getExtensionRequest(status:any,page:number,pageSize:number) {
    return this.httpClient.get<any>(
      `${this.baseUrl}/api/v1/land-project-extension/${status}?pageNo=${page}&pageSize=${pageSize}`
    );
  }

  public getProjectExtensionAttachment(projectExtensionId:string) {
    return this.httpClient.get<any>(
      `${this.baseUrl}/api/v1/land-project-extension/${projectExtensionId}/attachment`
    );
  }

  public getProjectLotUse(projectId:string) {
    return this.httpClient.get<any>(
      `${this.baseUrl}/api/v1/land-project-extension/${projectId}/lot-use`
    );
  }


  /**
   * Get Payment Summary Report
   * @param payload
   * @returns
   */
  public paymentSummaryReport(payload: any): Observable<any> {
    return this.httpClient.post(
      `${this.baseUrl}/api/v1/land-sales/report/payment-summary`,
      payload
    );
  }

  /**
   * Review Project Extension
   * @param payload
   * @returns
   */
  public projectExtensionReview(payload: any): Observable<any> {
    return this.httpClient.post(
      `${this.baseUrl}/api/v1/land-project-extension/review`,
      payload
    );
  }

  public getExtensionDetails(requestCode:string,pageNo:number,pageSize:number) {
    return this.httpClient.get<any>(
      `${this.baseUrl}/api/v1/land-project-extension/status?requestCode=${requestCode}&pageNo=${pageNo}&pageSize=${pageSize}`
    );
  }

  /**
   * Get Default Spacial Data  4326 crs
   * @param payload
   * @returns
   */
  public defaultSpacial(payload: any): Observable<any> {
    return this.httpClient.post(
      `${this.baseUrl}/api/v1/land-project/spacial`,
      payload
    );
  }


  /**
   * Search Project
   * @param payload
   * @returns
   */
  public regularizationLandProjectMap(payload: any,pageNo:number,pageSize:number): Observable<any> {
    return this.httpClient.post(
      `${this.baseUrl}/api/v1/land-project/regularization-search?pageNo=${pageNo}&pageSize=${pageSize}`,
      payload
    );
  }


  /**
   * return base 64 file
   * @param billId
   * @returns
   */

  printLandInvoice(applicationChargeId: string): Observable<any> {
    return this.httpClient.get(
      `${this.baseUrl}/api/v1/land-sales/application-charges/${applicationChargeId}/invoice`
    );
  }


  /**
   * Search Project
   * @param payload
   * @returns
   */
  public privateLandProjectMap(payload: any,pageNo:number,pageSize:number): Observable<any> {
    return this.httpClient.post(
      `${this.baseUrl}/api/v1/land-project/search?pageNo=${pageNo}&pageSize=${pageSize}`,
      payload
    );
  }

  public getCouncilProjects(administrativeAreaCode:string,pageNo:number,pageSize:number) {
    return this.httpClient.get<any>(
      `${this.baseUrl}/api/v1/land-open-project/council-project?administrativeAreaCode=${administrativeAreaCode}&pageNo=${pageNo}&pageSize=${pageSize}`
    );
  }


  public getCouncilSummary(pageNo:number,pageSize:number) {
    return this.httpClient.get<any>(
      `${this.baseUrl}/api/v1/land-open-project/council-summary?pageNo=${pageNo}&pageSize=${pageSize}`
    );
  }

  public getPlotRates(landProjectId:string,plotStatus:number,pageNo:number,pageSize:number) {
    return this.httpClient.get<any>(
      `${this.baseUrl}/api/v1/land-project/land-plot-rates?landProjectId=${landProjectId}&plotStatus=${plotStatus}&pageNo=${pageNo}&pageSize=${pageSize}`
    );
  }

  public getPlotsByBlock(landProjectId:string,block:string,pageNo:number,pageSize:number) {
    return this.httpClient.get<any>(
      `${this.baseUrl}/api/v1/land-project/land-plots-by-block?landProjectId=${landProjectId}&block=${block}&pageNo=${pageNo}&pageSize=${pageSize}`
    );
  }

  public getOccupyTypes() {
    return this.httpClient.get<any>(
      `${this.baseUrl}/api/v1/setup/occupier-types`
    );
  }

  /**
   * Create Land Project Cost Rates
   * @param payload
   * @returns
   */
  public createProjectCostRate(payload: any,projectId:number): Observable<any> {
    return this.httpClient.post(
      `${this.baseUrl}/api/v1/land-project/${projectId}/charges`,
      payload
    );
  }

  /**
   * Create Land Projects
   * @param payload
   * @returns
   */
  public createLandProject(payload: any): Observable<any> {
    return this.httpClient.post(
      `${this.baseUrl}/api/v1/land-project`,
      payload
    );
  }

  /**
   * Assign Lot Use
   * @param payload
   * @returns
   */
  public assignLotUse(payload: any): Observable<any> {
    return this.httpClient.post(
      `${this.baseUrl}/api/v1/land-project/lot-use`,
      payload
    );
  }

  /**
   * Assign Plot rate
   * @param payload
   * @returns
   */
  public assignPlotRate(payload: any): Observable<any> {
    return this.httpClient.post(
      `${this.baseUrl}/api/v1/land-project/land-plot-rate`,
      payload
    );
  }

  /**
   * Approve Plot rate
   * @param payload
   * @returns
   */
  public approvePlotRates(payload: any): Observable<any> {
    return this.httpClient.put(`${this.baseUrl}/api/v1/land-project/land-plot-rate`, payload);
  }

  /**
   * Publish For Sale
   * @param payload
   * @returns
   */
  public publishForSale(payload: any): Observable<any> {
    return this.httpClient.post(`${this.baseUrl}/api/v1/land-project/sale-land-plot`, payload);
  }


  /**
   * Set Plot For Private Use
   * @param payload
   * @returns
   */
  public setForPrivateUse(payload: any): Observable<any> {
    return this.httpClient.post(`${this.baseUrl}/api/v1/land-project/private`, payload);
  }


  /**
   * Create land Sale Configuration
   * @param payload
   * @returns
   */
  public createLandSaleConfiguration(payload: any): Observable<any> {
    return this.httpClient.post(`${this.baseUrl}/api/v1/land-sales-config`, payload);
  }

  /**
   * Update land Sale Configuration
   * @param payload
   * @returns
   */
  public UpdateLandSaleConfiguration(payload: any,id:any): Observable<any> {
    return this.httpClient.put(`${this.baseUrl}/api/v1/land-sales-config/${id}`, payload);
  }

  /**
   * Create land Sales Rates
   * @param payload
   * @returns
   */
  public CreateLandSalesRates(payload: any): Observable<any> {
    return this.httpClient.post(`${this.baseUrl}/api/v1/land-sales-config/rate`, payload);
  }

  /**
   * Update land Sales Rates
   * @param payload
   * @returns
   */
  public UpdateLandSalesRates(payload: any,id:any): Observable<any> {
    return this.httpClient.put(`${this.baseUrl}/api/v1/land-sales-config/rate/${id}`, payload);
  }

  /**
   * Create land Sales Grace Period
   * @param payload
   * @returns
   */
  public CreateGracePeriod(payload: any): Observable<any> {
    return this.httpClient.post(`${this.baseUrl}/api/v1/land-sales-config/grace-period`, payload);
  }

  /**
   * Update land Sales Grace Period
   * @param payload,id
   * @returns
   */
  public UpdateGracePeriod(payload: any,id:any): Observable<any> {
    return this.httpClient.put(`${this.baseUrl}/api/v1/land-sales-config/grace-period/${id}`, payload);
  }

  public getApplications(pageNo:number,pageSize:number) {
    return this.httpClient.get<any>(
      `${this.baseUrl}/api/v1/land-sales/application?pageNo=${pageNo}&pageSize=${pageSize}`
    );
  }

  public getApplicationCharge(applicationId:string,pageNo:number,pageSize:number) {
    return this.httpClient.get<any>(
      `${this.baseUrl}/api/v1/land-sales/application-charges/me?applicationId=${applicationId}&pageNo=${pageNo}&pageSize=${pageSize}`
    );
  }

  public getProjectCharge(landProjectId:string,pageNo:number,pageSize:number) {
    return this.httpClient.get<any>(
      `${this.baseUrl}/api/v1/land-project/charges?landProjectId=${landProjectId}&pageNo=${pageNo}&pageSize=${pageSize}`
    );
  }

  /**
   * Save land Application
   * @param payload
   * @returns
   */
  public createLandApplication(payload: any): Observable<any> {
    return this.httpClient.post(`${this.baseUrl}/api/v1/land-sales/application/internal`, payload);
  }

}
