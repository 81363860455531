// import { Component, OnInit } from '@angular/core';

// @Component({
//   selector: 'tausi-otp-reset-password',
//   templateUrl: './otp-reset-password.component.html',
//   styleUrls: ['./otp-reset-password.component.scss']
// })
// export class OtpResetPasswordComponent implements OnInit {

//   constructor() { }

//   ngOnInit(): void {
//   }

// }

import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';

import { InteractionService } from '../../services/interaction.service';
import { TaxpayerManagementService } from '../../services/taxpayer-management.service';
import { FormResetPasswordComponent } from '../form-reset-password/form-reset-password.component';
// import { OTP } from '../../models/taxpayer';
// import { TaxpayerService } from '../../services/taxpayer.service';
// import { UaaService } from '../../services/uaa.service';
import { UserUaaService } from 'src/app/user-management/services/user-uaa.service';

@Component({
  selector: 'tausi-otp-reset-password',
  templateUrl: './otp-reset-password.component.html',
  styleUrls: ['./otp-reset-password.component.scss'],
})
export class OtpResetPasswordComponent implements OnInit {
  public showOTP!: boolean;
  public otpform!: FormGroup;
  // public otp: any;
  public isValid!: boolean;
  // public phoneNumber: any;
  // public nin: any;
  public message: any;

  public requestPasswordResetResObj!: any;
  verifyRequestPasswordResetResObj!: any;

  constructor(
    private _uaaSvc: UserUaaService,
    private _taxpayerSvc: TaxpayerManagementService,
    private _formBuilder: FormBuilder,
    private _ref: DynamicDialogRef,
    private _dialogSvc: DialogService,
    private _interactionSvc: InteractionService
  ) {
    this.showOTP = false;
    this.isValid = false;
  }

  ngOnInit(): void {
    this.otpform = this.initForm();
  }

  initForm(): FormGroup {
    return this._formBuilder.group({
      phoneNumber: ['', Validators.required],
      nin: ['', Validators.required],
    });
  }

  /**
   *
   * @param item
   */
  requestPasswordReset(item: any): void {
    const payload = {
      nin: item?.nin.split('-').join(''),
      phoneNumber: item?.phoneNumber,
    };

    this._uaaSvc.requestPasswordReset(payload).subscribe((response) => {
      if (response?.statusCode === '21000') {
        this.requestPasswordResetResObj = response?.data?.item;

        const transformed = this.replaceNumbers(
          this.requestPasswordResetResObj?.phoneNumber
        );
        this.message = `We have have sent an OTP to ${transformed}. ${response?.description} `;

        // if (this.requestPasswordResetResObj?.object?.code === 21000) {
        this.showOTP = true;
        // if (this.requestPasswordResetResObj?.object?.data?.code === '060') {
        //   this.showOTP = true;
        // } else {
        //   this._interactionSvc.dialog(
        //     `Error: ${response?.data?.item?.object?.data?.message} , Failed to verify, please try again`,
        //     'pi-info-circle waring'
        //   );
        //   return;
        // }
        // } else {
        //   this._interactionSvc.dialog(
        //     `Error: ${response?.description} , Failed to verify, please try again `,
        //     'pi-info-circle waring'
        //   );
        //   return;
        // }
      } else {
        this._interactionSvc.dialog(
          `Error , ${response?.description}`,
          'pi-info-circle waring'
        );
      }
    });
  }

  verifyResetPassword(otpValueEvent: any): void {
    const payload = {
      nin: this.requestPasswordResetResObj?.nin?.split('-').join(''),
      phoneNumber: this.requestPasswordResetResObj?.phoneNumber,
      otp: otpValueEvent,
      token: this.requestPasswordResetResObj?.token,
    };

    this._uaaSvc.verifyRequestPasswordReset(payload).subscribe((response) => {
      if (response?.statusCode === '21000') {
        this.verifyRequestPasswordResetResObj = response?.data?.item;
        const payload = {
          token: this.verifyRequestPasswordResetResObj?.token,
          nin: this.verifyRequestPasswordResetResObj?.nin,
          message: 'Please enter your new password. ' + response?.description,
        };
        this.close();
        this.requestPasswordChange(payload);
        // if (this.verifyRequestPasswordResetResObj?.object?.code === 21000) {
        //   if (
        //     this.verifyRequestPasswordResetResObj?.object?.data?.code === '00'
        //   ) {
        //     const payload = {
        //       token: this.verifyRequestPasswordResetResObj?.token,
        //       nin: this.verifyRequestPasswordResetResObj?.nin,
        //       message:
        //         'Please enter your new password. ' + response?.description,
        //     };
        //     this.close();
        //     this.requestPasswordChange(payload);
        //   } else {
        //     this._interactionSvc.dialog(
        //       `Error: ${response?.data?.item?.object?.data?.message} , Failed to verify, please try again `,
        //       'pi-info-circle waring'
        //     );
        //     return;
        //   }
        // } else {
        //   this._interactionSvc.dialog(
        //     `Error: ${response?.description} , Failed to verify, please try again `,
        //     'pi-info-circle waring'
        //   );
        //   return;
        // }
      } else {
        this._interactionSvc.dialog(
          `Error , ${response?.description}`,
          'pi-info-circle waring'
        );
      }
    });
  }

  /**
   * reset password form
   * @param payload
   */
  requestPasswordChange(payload: any): void {
    const _dialog = this._dialogSvc.open(FormResetPasswordComponent, {
      header: 'Reset Password',
      width: '50%',
      data: payload,
    });
  }

  replaceNumbers(phone: any): void {
    let transformed;
    for (var i = 0; i <= 3; i++) {
      transformed = phone.replace(i, '*');
    }
    return transformed;
  }

  handleChange(otpValueEvent: any): void {
    if (otpValueEvent?.length === 6) {
      // this.otp = event;
      this.isValid = true;
      this.verifyResetPassword(otpValueEvent);
    } else {
      // this.otp = null;
      this.isValid = true;
    }
  }

  ngOnDestroy(): void {
    if (this._ref) {
      this._ref.close();
    }
  }

  close(): void {
    this._ref.close();
  }
}
