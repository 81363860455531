import { Component, OnInit } from '@angular/core';
import { DynamicDialogRef } from 'primeng/dynamicdialog';

@Component({
  selector: 'tausi-welcome-notification',
  templateUrl: './welcome-notification.component.html',
  styleUrls: ['./welcome-notification.component.scss'],
})
export class WelcomeNotificationComponent implements OnInit {
  constructor(private _ref: DynamicDialogRef) {}
  ngOnDestroy(): void {
    if (this._ref) {
      this._ref.close();
    }
  }

  ngOnInit(): void {}

  submit() {
    this._ref.close('success');
  }

  close() {
    this.ngOnDestroy();
  }
}
