<div class="mt-4" style="width: 100%">
  <div class="" fxLayout="row" fxLayoutAlign="center center">
    <div class="col-sm-12 col-md-12 col-lg-12">
      <div class="footer-content py-3 text-muted">
        <div>
          <div class="footer-brand">{{ owner }}</div>
          <span class="text-700">{{ this.configuration?.name }}</span>
          <span class="text-800 px-2">{{ version }}</span>
          <span class="text-900">&copy;</span> {{ year }}.
          <span>{{ copyright }}</span
          >.
        </div>
        <div class="other-links">
          <ul class="footer-links">
            <li><a href="#/documentation">Why Tausi</a></li>
            <li><a href="#/documentation">Documentation</a></li>
            <li><a href="#/manuals">Manuals</a></li>
            <li><a href="#/user-matrix">User Matrix</a></li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</div>
