export namespace Dashboard {
  export class LoadPosCollection {
    static readonly type = '[POS] Load pos collection, cashonhand';
    // constructor(public payload: any) {}
  }

  export class LoadAllCouncils {
    static readonly type = '[Council] Load a list of councils';
    constructor(public payload: any) {}
  }
}
