import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import {
  DialogService,
  DynamicDialogConfig,
  DynamicDialogRef,
} from 'primeng/dynamicdialog';
import { TaxPayer } from 'src/app/agent-management/models/taxpayer';
import { TIN } from 'src/app/council-management/models/entity.model';
import { TaxpayerTra } from '../../models/taxpayerTra.inteface';
import { TaxpayerManagementService } from '../../services/taxpayer-management.service';
import { ToastService } from '../../services/toast.service';
import { UserService } from '../../services/user.service';
import { SearchResultsComponent } from '../search-results/search-results.component';

@Component({
  selector: 'tausi-validate-taxpayer',
  templateUrl: './validate-taxpayer.component.html',
  styleUrls: ['./validate-taxpayer.component.scss'],
  providers: [],
})
export class ValidateTaxpayerComponent implements OnInit {
  public taxpayer_categories: any[] = [];
  public categories: any[] = [];
  public validateform!: FormGroup;
  public questionform!: FormGroup;

  public isQuestion!: boolean;
  public currentAnswer!: string;

  public isIndividual!: boolean;
  public isLegal!: boolean;

  public rqcode!: string;
  public sw_nidaQuestion!: string;
  public en_nidaQuestion!: string;
  public nida!: any;
  public taxpayer_category!: any;
  public answeredQuestions: any[] = [];
  public prevanswcode!: number;
  public isLoading!: boolean;
  public selectedCode!: string;
  public errorMessage: any[] = [];

  constructor(
    private _formBuilder: FormBuilder,
    private _refDialog: DynamicDialogRef,
    private _config: DynamicDialogConfig,
    private _userSvc: UserService,
    private _dialogSvc: DialogService,
    private _toastSvc: ToastService,
    private _taxpayerSvc: TaxpayerManagementService
  ) {
    this.isLegal = false;
    this.isIndividual = true;
    this.isQuestion = false;
    this.isLoading = false;
    this.currentAnswer = '';
    this.selectedCode = this._config.data.code;
  }

  ngOnInit(): void {
    this.validateform = this.initform();
    this.questionform = this.initQuestionform();

    setTimeout(() => {
      this.fetchAllTaxpayerCategories();
    }, 0);

    if (this.selectedCode === 'T100200300') {
      this.isLegal = true;
      this.isIndividual = false;
    } else {
      this.isLegal = false;
      this.isIndividual = true;
    }
  }

  /**
   *
   */
  answerQuestion(): void {
    this.isQuestion = true;
  }

  /**
   * initialize choice form between NIN and TIN
   * @returns
   */
  initform(): FormGroup {
    return this._formBuilder.group({
      taxpayer_category: [this.selectedCode, Validators.required],
      tinNumber: [''],
      ninNumber: [''],
    });
  }

  /**
   * answer response question form
   * @returns
   */
  initQuestionform(): FormGroup {
    return this._formBuilder.group({
      answerDetail: ['', Validators.required],
    });
  }

  /**
   * search method
   */
  search(): void {
    const type = this.validateform.get('taxpayer_category')?.value;
    const tin = this.validateform.get('tinNumber')?.value;
    const nin = this.validateform.get('ninNumber')?.value.split('-').join('');

    switch (type) {
      case 'T100200400':
        this.searchByNIN(nin);
        break;
      case 'T100200300':
        this.searchByTIN(tin);
        break;
      default:
        this.searchByNIN(nin);
        break;
    }
  }

  /**
   * search by tin
   * @param tin
   */
  searchByTIN(tin: string): void {
    // console.log("searchByTIN");

    this._taxpayerSvc.searchTaxpayerByTIN(tin).subscribe((response) => {
      if (response.statusCode === '21000') {
        const payload = {
          nida: response.data.item,
          taxpayer: response.data.item,
          extras: this.taxpayer_category,
        };
        this._refDialog.close(payload);
      } else if (response.statusCode === '21003') {
        this.searchHandler(tin, 'tin');
      } else {
        this._toastSvc.error(response.description);
      }
    });
  }

  /**
   * search by nin
   * @param nin
   */
  searchByNIN(nin: string): void {
    this._taxpayerSvc.searchTaxpayerByNIN(nin).subscribe((response) => {
      if (response.statusCode === '21000') {
        const payload = {
          nida: response.data.item,
          taxpayer: response.data.item,
          extras: this.taxpayer_category,
        };
        this._refDialog.close(payload);
      } else if (response.statusCode === '21003') {
        this._toastSvc.warning(response.description);
        this.searchHandler(nin, 'nin');
      } else {
        //this._toastSvc.error(response.description);
        this.errorMessage = [
          {
            severity: 'error',
            summary: 'Error',
            detail: 'NIDA Services unavailable. Please try again later',
          },
        ];
      }
    });
  }

  /**
   * handles search results
   * @param nin
   */
  searchHandler(id: string, type: string) {
    const result = {
      id: id,
      type: type,
      status: false,
    };

    const _ref = this._dialogSvc.open(SearchResultsComponent, {
      showHeader: true,
      header: 'NIN Search Dialog',
      width: '40%',
      data: result,
    });

    _ref.onClose.subscribe((response) => {
      if (response.status && type === 'nin') {
        const payload = {
          nin: id,
          answerCode: null,
          answer: null,
        };
        this.validateTaxpayer(payload);
      } else if (response.status && type === 'tin') {
        const payload = { tin: id };
        //search tin

        this._userSvc.taxpayerByTin(payload).subscribe((response) => {
          if (response) {
            if (response.code === 21000) {
              const item = response.data?.payload?.respData;
              const data = { tin: id };
              this.storeTIN(item, data);
            } else {
              this._toastSvc.warning(`${response.description}`);
              this._refDialog.close();
            }
          }
        });
      }
    });
  }

  /**
   * check if taxpayer has a valid nin
   * 19911225-41111-00009-29
   * @param payload
   */
  validateTaxpayer(payload: any): void {
    this._userSvc.validateTaxPayer(payload).subscribe((response) => {
      if (response.code === 21000) {
        if (response.data.code === '00') {
          this.nida = response.data.data.nidaResponse;
          this.storeNIDA(this.nida);
        } else if (JSON.parse(response.data.code) === 120) {
          this.nida = response.data.data.nidaResponse;
          this.sw_nidaQuestion = this.nida.sw;
          this.en_nidaQuestion = this.nida.en;
          this.prevanswcode = JSON.parse(this.nida.prevanswcode);
          this.isQuestion = true;

          if (this.prevanswcode !== null) {
            let payload: any;
            if (this.prevanswcode === 123) {
              payload = {
                answer: this.currentAnswer,
                status: true,
              };
            } else {
              payload = {
                answer: this.currentAnswer,
                status: false,
              };
            }
            this.answeredQuestions.push(payload);
            this.answeredQuestions = [...this.answeredQuestions];
          }
        } else if (JSON.parse(response.data.code) === 130) {
          this.answeredQuestions = [];
          this.close();
          this._toastSvc.warning(`${response.data.message}`);
        } else {
          this.errorMessage = [
            {
              severity: 'warn',
              summary: 'Message',
              detail: 'NIN not found. Please verify NIDA number!',
            },
          ];
        }
      } else {
        //this._refDialog.close();
        this.errorMessage = [
          {
            severity: 'error',
            summary: 'Message',
            detail: response.description,
          },
        ];
      }
    });
  }

  /**
   * store taxpayer details from TRA
   * @param item
   * @param data
   */
  storeTIN(item: any, data: any): void {
    // console.log("item tt ", item);

    const itemTosend: TaxpayerTra = {
      tin: data?.tin,
      tradingname: item?.tradingName ?? '',
      firstname: item?.firstName ?? '',
      middlename: item?.middleName ?? '',
      surname: item?.lastName ?? '',
      othernames: item?.lastName ?? '',
      sex: item?.gender ?? '',
      dateofbirth: item?.dateOfBirth ?? '',
      placeofbirth: '',
      residentregion: item?.region ?? '',
      residentdistrict: item?.district ?? '',
      residentward: item?.ward ?? '',
      residentvillage: item?.street ?? '',
      residentstreet: item?.street ?? '',
      residenthouseno: '',
      residentpostaladdress: '',
      residentpostcode: '',
      birthcountry: '',
      birthregion: '',
      birthdistrict: '',
      birthward: '',
      nationality: '',
      photo: '',
      mobilenumber: item?.mobile ?? '',
      telephonenumber: item?.tel1 ?? '',
      email: item?.email ?? '',
      nin: '',
      taxpayerName: item?.taxpayerName ?? '',
      traTaxpayerId: item?.taxpayerId ?? '',
      postalCity: item?.postalCity ?? '',
      telephone: '',
      numberOfEmployees: item?.numberOfEmployees ?? '',
      dateOfRegistration: item?.dateOfRegistration ?? '',
      blockNumber: item?.blockNumber ?? '',
      taxpayerCategoryId: this.taxpayer_category.id,
      person: item?.person,
    };

    this.store(itemTosend);
  }

  /**
   * a class method to store taxpayer
   * @param payload
   */
  storeNIDA(item: any): void {
    // TaxPayer

    const payload: TaxPayer = {
      tin: '',
      tradingname: '',
      firstname: item?.firstname,
      middlename: item?.middlename,
      surname: item?.surname,
      othernames: item?.othernames,
      sex: item?.sex,
      dateofbirth: item?.dateofbirth,
      placeofbirth: item?.placeofbirth,
      residentregion: item?.residentregion,
      residentdistrict: item?.residentdistrict,
      residentward: item?.residentward,
      residentvillage: item?.residentvillage,
      residentstreet: item?.residentstreet,
      residenthouseno: item?.residenthouseno,
      residentpostaladdress: item?.residentpostaladdress,
      residentpostcode: item?.residentpostcode,
      birthcountry: item?.birthcountry,
      birthregion: item?.birthregion,
      birthdistrict: item?.birthdistrict,
      birthward: item?.birthward,
      nationality: item?.nationality,
      photo: item?.photo,
      mobilenumber: item?.phonenumber === null ? '' : item?.phonenumber,
      telephonenumber: item?.phonenumber === null ? '' : item?.phonenumber,
      email: item?.email,
      nin: item?.nin,
      taxpayerCategoryId: this.taxpayer_category.id,
    };
    this.store(payload);
  }

  /**
   * store taxpayer details
   * @param payload
   */
  store(payload: any): void {
    this.isLoading = true;
    this._taxpayerSvc.storeTaxpayer(payload).subscribe((response) => {
      // console.log("response store: ", response);

      if (response.statusCode === '21000') {
        setTimeout(() => {
          this.isLoading = false;
          this._refDialog.close({
            nida: this.nida,
            collector: response.data.item,
            taxpayer: response.data.item,
            extras: this.taxpayer_category,
          });
        }, 1000);
      } else {
        this.isLoading = false;
        this._toastSvc.warning(response.description);
      }
    });
  }

  /**
   * submit an answer to the question
   * @param item
   */
  questionResponse(item: any): void {
    const payload = {
      nin: this.nida.nin,
      answerCode: this.nida.rqcode,
      answer: item.answerDetail,
    };
    this.currentAnswer = payload.answer;
    this.validateTaxpayer(payload);
    this.questionform.reset();
  }

  /**
   * close dialog/switch views
   */
  close(): void {
    if (this.isQuestion) {
      this.isQuestion = false;
    }
  }

  /**
   * get all taxpayer categories
   * returns a list
   */
  fetchAllTaxpayerCategories(): void {
    this._taxpayerSvc.fetchTaxpayerCategories().subscribe((response) => {
      if (response.statusCode === '21000') {
        this.taxpayer_categories = response.data.itemList;
        this.categories = this.taxpayer_categories.filter(
          (item) => item.code === this.selectedCode
        );
        this.taxpayer_category = this.categories[0];
      }
    });
  }

  ngOnDestroy(): void {
    if (this._refDialog) {
      this._refDialog.close();
    }
  }

  //function to change validation rule
  switchCategory(category: any) {
    if (category === 'T100200300') {
      this.validateform.controls['tinNumber'].setValidators(
        Validators.required
      );
      this.validateform.controls['ninNumber']?.clearValidators();
    } else {
      this.validateform.controls['ninNumber']?.setValidators([
        Validators.required,
      ]);
      this.validateform.controls['tinNumber']?.clearValidators();
    }
    this.validateform.controls['tinNumber']?.updateValueAndValidity();
    this.validateform.controls['ninNumber']?.updateValueAndValidity();
  }
}
