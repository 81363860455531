<mat-sidenav-container fullscreen color="primary">
  <mat-sidenav mode="side" #sidenav opened position="start">
    <div class="sidenav_container">
      <div class="sidenav_profile-section">
        <div class="inner-profile">
          <div class="profile_image">
            <div style="width: 100px; height: 102px">
              <img
                [src]="transform(user?.avatar)"
                *ngIf="user?.avatar"
                alt=""
              />
              <img
                src="../../../assets/imgs/admin/placeholder.jpg"
                *ngIf="user?.avatar == undefined"
                alt=""
              />
            </div>
          </div>

          <h2 class="p-mt-4" *ngIf="user">{{ fullName | titlecase }}</h2>
          <h3 class="text-muted" *ngIf="user">
            {{ currentUserAccount?.administrativeAreaId?.name | titlecase }}
            {{
              currentUserAccount?.administrativeAreaId?.areaType?.name
                | titlecase
            }}
            <button
              (click)="showSwitchingAccountDialog()"
              color="warn"
              mat-icon-button
              pTooltip="Switch Current working User Account"
              tooltipPosition="top"
              tooltipEvent="hover"
            >
              <!-- <mat-icon>compare_arrows</mat-icon> -->
              <img
                src="../../../assets/imgs/switch.svg"
                alt="Switch account"
                width="28"
                height="28"
              />
            </button>
          </h3>
          <mat-divider></mat-divider>
          <!-- <div class="switch-account"></div> -->
        </div>
      </div>

      <div class="sub-section">
        <div class="main-menu-section">
          <p-panelMenu
            classStyle=""
            [model]="items"
            [style]="{ width: '100%' }"
            [multiple]="false"
          ></p-panelMenu>
        </div>
        <div class="sidenav_footer-section p-py-4">
          <a href="" class="btn-support" translate>pri.sidebar.support</a>
          <div class="copyright p-mt-2">
            &copy; {{ currentYear }}.
            <span translate>pri.sidebar.version</span>
          </div>
        </div>
      </div>
    </div>
  </mat-sidenav>
  <mat-sidenav-content>
    <mat-toolbar>
      <button mat-icon-button (click)="sidenav.toggle()">
        <mat-icon>menu</mat-icon>
      </button>
      <span class="title">
        <tausi-logo
          class="p-mx-2"
          width="40"
          imageName="tausi_logo"
        ></tausi-logo>
        TAUSI
      </span>
      <span class="spacer"></span>

      <button mat-icon-button class="" aria-label="network">
        <mat-icon matBadgeColor="warn">{{
          status == 1 ? "wifi_on" : "wifi_off"
        }}</mat-icon>
      </button>
      <button mat-icon-button aria-label="Dashboard">
        <mat-icon>apps</mat-icon>
      </button>
      <button mat-icon-button class="" aria-label="notifications">
        <mat-icon matBadge="6" matBadgeColor="warn">notifications</mat-icon>
      </button>
      <span class="username p-mx-3" *ngIf="user">{{
        fullName | titlecase
      }}</span>

      <span>
        <div class="profile_image">
          <div class="" style="width: 50px; height: 50px">
            <img
              [src]="transform(user?.avatar)"
              *ngIf="user?.avatar"
              alt=""
              (click)="menu.toggle($event)"
            />
          </div>
        </div>
      </span>
      <button mat-icon-button class="my-5" (click)="menu.toggle($event)">
        <mat-icon>more_vert</mat-icon>
      </button>
    </mat-toolbar>
    <div class="main-content">
      <tausi-breadcrumb></tausi-breadcrumb>
      <router-outlet></router-outlet>
    </div>
  </mat-sidenav-content>
</mat-sidenav-container>

<!-- ----------------------------------------------------------------
  start of switching area dialog content
-------------------------------------------------------------------->
<p-dialog
  header="Switch Working Area"
  [(visible)]="displaySwitchingAccount"
  [modal]="true"
  [style]="{ width: '30vw' }"
>
  <div class="row p-px-2 p-pb-2">
    <div class="p-grid p-fluid p-mt-4">
      <div class="p-col-12">
        <div
          class="location p-grid p-fluid p-d-flex p-flex-row p-jc-start p-ai-center"
        >
          <i class="pi pi-map-marker"></i>
          <div class="p-ml-5 details">
            <h4 class="p-mb-2">Current Working Area</h4>
            <h4 class="text-muted" *ngIf="user">
              {{ currentUserAccount?.administrativeAreaId?.name | titlecase }}
              {{
                currentUserAccount?.administrativeAreaId?.areaType?.name
                  | titlecase
              }}
            </h4>
          </div>
        </div>
      </div>
    </div>

    <div class="p-grid p-fluid p-mt-3">
      <div class="p-col-12 p-md-12">
        <p-dropdown
          autoWidth="false"
          [style]="{ width: '100%' }"
          [options]="listOfMyAccounts"
          [(ngModel)]="selectedSwitchingAccount"
          optionLabel="displayName"
          [filter]="true"
          filterBy="displayName"
          [showClear]="true"
          placeholder="Select Council"
          appendTo="body"
        >
          <ng-template pTemplate="selectedItem">
            <div class="account-item-value" *ngIf="selectedSwitchingAccount">
              <div class="p-col-12">
                <div>
                  Administrative Area :
                  <span class="text-600 p-ml-1">{{
                    selectedSwitchingAccount?.name
                  }}</span>
                </div>
                <div>
                  Area Type :
                  <span class="text-600 p-ml-1">{{
                    selectedSwitchingAccount?.areaType?.name
                  }}</span>
                </div>
              </div>
            </div>
          </ng-template>
          <ng-template let-account pTemplate="item">
            <div class="p-col-12">
              <div>
                Administrative Area :
                <span class="text-600 p-ml-1">{{ account?.name }}</span>
              </div>
              <div>
                Area Type :
                <span class="text-600 p-ml-1">{{
                  account?.areaType?.name
                }}</span>
              </div>
            </div>
          </ng-template>
        </p-dropdown>
      </div>

      <div class="p-col-12 p-md-12">
        <span class="p-float-label">
          <input
            type="password"
            [feedback]="true"
            id="inputPassword"
            pTooltip="User Password"
            tooltipPosition="top"
            required
            tooltipEvent="focus"
            pPassword
            [(ngModel)]="providedPassword"
          />
          <label for="inputPassword">Your Current Password</label>
        </span>
      </div>
    </div>

    <div class="p-grid p-fluid p-d-flex p-jc-center p-ai-center p-mt-3">
      <div class="p-field">
        <button
          pButton
          class="p-button"
          iconPos="right"
          [disabled]="enableOrDisableSwitchButton()"
          (click)="switchingAccount()"
          icon="pi pi-sync"
          label="Switch  Account"
        ></button>
      </div>
    </div>
  </div>
</p-dialog>

<p-confirmDialog
  key="confirm"
  [style]="{ width: '40vw' }"
  [baseZIndex]="10000"
  [rejectVisible]="false"
  acceptLabel="Ok"
  acceptButtonStyleClass="p-button-text"
></p-confirmDialog>

<p-dialog
  [modal]="true"
  [showHeader]="false"
  header="Processing"
  [(visible)]="isLoading"
  [style]="{ width: '30vw' }"
>
  <div class="p-mt-4">
    <p class="p-pb-3 text-muted text-600">{{ message }}</p>
    <p-progressBar
      mode="indeterminate"
      [style]="{ height: '2px' }"
    ></p-progressBar>
  </div>
</p-dialog>

<!-- <p-menu appendTo="body" #menu [popup]="true" [model]="menuItems"></p-menu> -->
<p-menu #menu [model]="menuItems" [popup]="true"></p-menu>
<!-- <p-megaMenu [model]="menuItems"></p-megaMenu> -->
