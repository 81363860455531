import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import {HttpClient, HttpParams} from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class LandRentService {
  private readonly baseUrl: string = `${environment.apiUrl}${environment.endPoints.land_rent_service}`;

  constructor(private httpClient: HttpClient) {}

  public getRevenueSources() {
    return this.httpClient.get<any>(
      `${this.baseUrl}/api/v1/setup/revenue-source`
    );
  }

  public getRevenuePartner() {
    return this.httpClient.get<any>(
      `${this.baseUrl}/api/v1/setup/revenue_partner`
    );
  }

  public getLotUse() {
    return this.httpClient.get<any>(
      `${this.baseUrl}/api/v1/setup/lot-use`
    );
  }

  public getPaymentSchedule() {
    return this.httpClient.get<any>(
      `${this.baseUrl}/api/v1/setup/payment-schedule`
    );
  }

  public getLocation(pageNo:number,pageSize:number,adminAreaId:string) {
    return this.httpClient.get<any>(
      `${this.baseUrl}/api/v1/setup/location?adminAreaId=${adminAreaId}&pageNo=${pageNo}&pageSize=${pageSize}`
    );
  }

  public getPlotCharge(pageNo:number,pageSize:number,plotId:string) {
    return this.httpClient.get<any>(
      `${this.baseUrl}/api/v1/charge/plot?plotId=${plotId}&pageNo=${pageNo}&pageSize=${pageSize}`
    );
  }

  public getDashboardSummary() {
    return this.httpClient.get<any>(
      `${this.baseUrl}/api/v1/charge/dashboard-summary`
    );
  }

  /**
   * Get Land Plots and Charges
   */
  public getPlotWithCharge(adminAreaId:number,options:HttpParams ,pageNo:number,pageSize:number): Observable<any> {
    return this.httpClient.get(
      `${this.baseUrl}/api/v1/plot/all?administrativeAreaId=${adminAreaId}&pageNo=${pageNo}&pageSize=${pageSize}`,
      {
        params: options,
      }
    );
  }


  /**
   * Get Land Plots and Charges
   */
  public getPlotWithChargeMe(options:HttpParams ,pageNo:number,pageSize:number): Observable<any> {
    return this.httpClient.get(
      `${this.baseUrl}/api/v1/plot/me?pageNo=${pageNo}&pageSize=${pageSize}`,
      {
        params: options,
      }
    );
  }

  /**
   * Get Land Plots and Charges
   */
  public getChargeRates(search: any,pageNo:number,pageSize:number): Observable<any> {
    return this.httpClient.get(
      `${this.baseUrl}/api/v1/charge/rate?pageNo=${pageNo}&pageSize=${pageSize}&${search}`
    );
  }

  /**
   * create Revenue Source
   * @param payload
   * @returns
   */
  public createRevenueSource(payload: any): Observable<any> {
    return this.httpClient.post(
      `${this.baseUrl}/api/v1/setup/revenue-source`,
      payload
    );
  }


  /**
   * Get My Bill Summary
   */
  public getBillSummary(params:HttpParams=new HttpParams()): Observable<any> {
    return this.httpClient.get(
      `${this.baseUrl}/api/v1/charge/bill/me`,
      {
        params:params
      }
    );
  }

  /**
   * Get My Bill Summary
   */
  public getPlotBillSummary(options:any): Observable<any> {
    return this.httpClient.get(
      `${this.baseUrl}/api/v1/charge/bill/plot-summary`,
      {
        params:options
      }
    );
  }

  public getLandRentRates(options:any): Observable<any> {
    return this.httpClient.get(
      `${this.baseUrl}/api/v1/charge/location-rates`,
      {
        params:options
      }
    );
  }

  /**
   * Create Revenue Partner
   * @param payload
   * @returns
   */
  public createRevenuePartner(payload: any): Observable<any> {
    return this.httpClient.post(
      `${this.baseUrl}/api/v1/setup/revenue-partner`,
      payload
    );
  }

  /**
   * Create Revenue Partner
   * @param payload
   * @returns
   */
  public createRevenuePartnerWithSource(payload: any): Observable<any> {
    return this.httpClient.post(
      `${this.baseUrl}/api/v1/setup/revenue-partner-with-source`,
      payload
    );
  }

  /**
   * Create Payment Schedule
   * @param payload
   * @returns
   */
  public createPaymentSchedule(payload: any): Observable<any> {
    return this.httpClient.post(
      `${this.baseUrl}/api/v1/setup/payment-schedule`,
      payload
    );
  }

  /**
   * Map Revenue Partner to Revenue Source
   * @param payload
   * @returns
   */
  public mapPartnerToRevenueSource(payload: any): Observable<any> {
    return this.httpClient.post(
      `${this.baseUrl}/api/v1/setup/partner-revenue-source`,
      payload
    );
  }

  /**
   * Request Control Number
   * @param payload
   * @returns
   */
  public requestControlNumber(payload: any,params:HttpParams=new HttpParams()): Observable<any> {
    return this.httpClient.post(
      `${this.baseUrl}/api/v1/charge/compile`,
      payload,
      {
        params:params
      }
    );
  }

  /**
   * Get My Bill Summary
   */
  public getMyBillSummary(params:HttpParams=new HttpParams()): Observable<any> {
    return this.httpClient.get(
      `${this.baseUrl}/api/v1/charge/bill/me`,
      {
        params:params
      }
    );
  }

}
