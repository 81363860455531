import * as am5 from '@amcharts/amcharts5';

export default class CustomTheme extends am5.Theme {
  setupDefaultRules() {
    this.rule('Graphics', ['line', 'series', 'stroke']).setAll({
      strokeWidth: 2,
      strokeDasharray: [10, 5],
    });

    this.rule('Bullet').setup = (target) => {
      target.setAll({
        sprite: am5.Circle.new(target.root, {
          radius: 50,
          fill: am5.color(0xff0000),
          fillOpacity: 1,
        }),
      });
    };
    this.rule('Label').setup = (target) => {
      target.setAll({
        fill: am5.color(0x333333),
        fontFamily: 'American',
        fontSize: '0.9em',
      });
    };

    this.rule('ColorSet').set('colors', [
      am5.color(0xff9f1a),
      am5.color(0x10ac84),
      am5.color(0xee5253),
      am5.color(0x01a3a4),
      am5.color(0xf368e0),
    ]);
  }
}
