<div class="p-mx-1">
  <div class="p-py-3" fxLayout="row" fxLayoutAlign="start center">
    <i class="pi pi-exclamation-triangle alert-icon"></i>
    <div class="p-ml-3" *ngIf="payload?.type == 'nin'">
      <p class="p-mt-3">
        Sorry, we could not find this NIN <b>{{ payload?.nin }}</b>
        in our records, verify that it is correct, and click continue.
      </p>
    </div>

    <div class="p-ml-3" *ngIf="payload?.type == 'tin'">
      <p class="p-mt-3">
        Sorry, we could not find this TIN <b>{{ payload?.tin }}</b>
        in our records, verify that it is correct, and click continue.
      </p>
    </div>
  </div>
  <div class="row">
    <div fxLayoutAlign="end center">
      <div>
        <button
          (click)="cancel()"
          pButton
          class="p-button-text mx-3"
          label="OK"
          icon="pi pi-check"
        ></button>
        <button
          (click)="continue()"
          pButton
          class="p-button"
          label="Continue"
          iconPos="right"
          icon="pi pi-arrow-right"
        ></button>
      </div>
    </div>
  </div>
</div>
