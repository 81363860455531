<div class="dynamic-dialog">
  <div class="px-0">
    <div class="p-py-4" fxLayoutAlign="center">
      <div class="setup-header-title">
        <h2 style="color: #ee0c0c; font-weight: 600">
          {{ dialogHeader }}
        </h2>
      </div>
    </div>
  </div>
  <div style="background-color: #f1f1f1; border-radius: 1rem">
    <div class="p-4" [innerHtml]="termsCondition"></div>
  </div>
  <div class="p-mt-5 p-px-4">
    <div class="field-checkbox">
      <p-checkbox
        name="conditions"
        value="accepted"
        (onChange)="acceptHandle($event)"
        inputId="conditions"
        [binary]="true"
      ></p-checkbox>
      <label class="p-ml-3 p-mt-1 text-muted" for="conditions"
        >I have read and accepted the TIN required terms and conditions.</label
      >
    </div>
  </div>
  <div class="p-px-2 p-mt-3 p-py-3">
    <div class="p-mt-3 p-grid p-jc-between p-ai-center">
      <div>
        <button
          type="button"
          pButton
          iconPos="left"
          label="Cancel"
          class="p-button-text"
          (click)="closeDialog()"
        ></button>
      </div>

      <div>
        <button
          [disabled]="!accepted"
          type="button"
          label="Continue"
          iconPos="right"
          icon="pi pi-arrow-right"
          pButton
          class="p-button"
          (click)="searchTaxpayerTin()"
        ></button>
      </div>
    </div>
  </div>
</div>
