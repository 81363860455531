export class LoadAllUsers {
  static readonly type = '[User] Load a list of users';
  constructor(public payload: any) {}
}

export class LoadAllCouncils {
  static readonly type = '[Council] Load a list of councils';
  constructor(public payload: any) {}
}

export class LoadCurrentUser {
  static readonly type = '[User] Load current user';
  constructor(public payload: any) {}
}

export class LogoutCurrentUser {
  static readonly type = '[User] logout current user';
}

export class AddUser {
  static readonly type = '[User] Create a user';
  constructor(public payload: string) {}
}

export class LoadOnlineOffline {
  static readonly type = '[OnlineOffline] load online and offline POS';
}

export class LoadUsageSummary {
  static readonly type = '[POS] load pos usage summary';
}
