import { Component, OnInit } from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {UserStorageService} from "../../../shared/services/user-storage.service";
import {ConfirmationService} from "primeng/api";
import {SidenavService} from "../../service/side-nav-service";
import {LandSalesService} from "../../../shared/services/land-sales.service";
import {TranslateService} from "@ngx-translate/core";
import {FormBuilder} from "@angular/forms";
import {AuthService} from "../../../shared/services/auth.service";
import {TranslateConfigService} from "../../../shared/services/translate.service";
import {NavigationService} from "../../../shared/services/navigation.service";
import {DialogService} from "primeng/dynamicdialog";
import {AssignPlotComponent} from "../assign-plot/assign-plot.component";
import {TermsConditionsComponent} from "../../../shared/components/terms-conditions/terms-conditions.component";

@Component({
  selector: 'tausi-map-info',
  templateUrl: './map-info.component.html',
  styleUrls: ['./map-info.component.scss']
})
export class MapInfoComponent implements OnInit {
  public featureToDisplay:any[]=[
    { code: 'locality', name: 'Locality' },
    { code: 'block', name: 'Block' },
    { code: 'lotNumber', name: 'Lot Number' },
    { code: 'legalArea', name: 'Legal Area' },
    { code: 'unitOfMeasure', name: 'Unit Of Measure' },
    { code: 'lotType', name: 'Lot Type' },
    { code: 'zone', name: 'Zone' },
    { code: 'region', name: 'Region' },
    { code: 'district', name: 'District' },
    { code: 'administrativeAreaName', name: 'Council' },
    { code: 'plotStatus', name: 'Plot Status' },
    { code: 'lotUse', name: 'Lot Use' },
    { code: 'price', name: 'Price' },
  ];
  public selectedPlotInfo!:any;
  public userInfo!:any;
  private project!:any;
  private selectedProjectInfo!:any;
  public projectTypeCode!:string;
  public selectedProjectTypeCode!:string;
  constructor
  ( private _dialogSvc: DialogService,
    private _landSalesSvc: LandSalesService,
    private sidenavService: SidenavService,
    private _confirmationSvc: ConfirmationService,
    private confirmationService: ConfirmationService,
    public router: Router,) {
  }

  ngOnInit(): void {
    setTimeout(() => {
      this.sidenavService.selectedProjectInfo$.subscribe((value) => {
        this.selectedProjectInfo = value;
      });
      this.sidenavService.selectedProjectTypeCode$.subscribe((value) => {
        this.selectedProjectTypeCode = value;
      });
      this.sidenavService.selectedPlotInfo$.subscribe((value) => {
        this.selectedPlotInfo = value;
        // if(Object.keys(value).length > 0){
        //   this.fetchCouncilProject(value.administrativeAreaCode,0,500);
        // }
      });
    }, 0);
  }

  //Buy Plots
  buyPlot() {
    const ref = this._dialogSvc.open(AssignPlotComponent, {
      showHeader: false,
      width: '50%',
      baseZIndex: 10000,
      header:this.selectedProjectTypeCode=='CLS'?'Private Plots Sale':'Regularize Land Plot',
      styleClass:'plots',
      data: this.selectedPlotInfo
    });
    ref.onClose.subscribe((response) => {
      if (response) {
        this.processLandSales(response);
      }
    });
  }

  confirmApplication(payload: any) {
    this._landSalesSvc.createLandApplication(payload).subscribe((response) => {
      if (response.statusCode === '21000') {
        this.confirm(response.description, 'pi-info-circle info');
        this.router.navigate([`main/application`]).then();
      } else {
        this.confirm(response.description, 'pi-exclamation-triangle warning');
      }
    });
  }

  confirm(message: string, icon: string) {
    this._confirmationSvc.confirm({
      key: 'confirm',
      icon: `pi ${icon}`,
      header: 'Information',
      message: message,
      accept: () => {},
    });
  }

  toggleInfo(){
    let open = this.sidenavService.toggleRight();
    this.sidenavService.setInfo(open);
  }

  //Process Land Sales
  processLandSales(payload:any) {
    const massage = "Are Sure you want To sell Selected Plot To <b>" + payload.applicantName + "</b> According to Project's Terms and Condition?";
    this.confirmationService.confirm({
      message: massage,
      header:this.selectedProjectTypeCode=='CLS'?'Private Plots Sale Confirmation':'Regularize Land Plot Confirmation',
      icon: 'pi pi-info-circle',
      accept: () => {
        this.confirmApplication(payload);
      },
      key: "plot"
    });
  }

  /**
   * get Council With Projects
   */
  // fetchCouncilProject(adminCode:string,pageNo:number,pageSize:number): void {
  //   this._landSalesSvc.getCouncilProjects(adminCode,pageNo,pageSize).subscribe((response) => {
  //     if (response.statusCode === '21000') {
  //       let data:any[] = response.data.itemList;
  //       this.project = data.find(i => i.projectId == this.selectedPlotInfo.landProjectId);
  //     }
  //   });
  // }
}
