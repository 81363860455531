import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { financialYear } from 'src/app/council-management/models/common.model';
import {
  CouncilEntity,
  InstitutionEntity,
  VillageEntity,
} from 'src/app/council-management/models/entity.model';
import {
  MapGfsCode,
  MapGfsCodeUpdate,
} from 'src/app/council-management/models/revenue.source';
import { environment } from 'src/environments/environment';
import { AreaLevel, Council } from '../models/administrative.area.model';
import {
  PaymentMethod,
  Response,
  RevenueSource,
  UnitOfMeasurement,
} from '../models/revenue-source.model';
import { CouncilSignatory } from '../models/signatory.model';

@Injectable({
  providedIn: 'root',
})
export class CouncilManagementService {
  private baseAPI: string = `${environment.apiUrl}${environment.endPoints.council_management_service}`;

  constructor(private _httpClient: HttpClient) {}

  /**
   * fetches all councils
   * @param pageSize
   * @param sortBy
   * @returns Council[]
   */
  getRegions(pageSize: number = 100, sortBy: string = 'id'): Observable<any> {
    return this._httpClient.get<Council[]>(
      `${this.baseAPI}/api/v1/get-regions?pageSize=${pageSize}&sortBy=${sortBy}`
    );
  }

  /**
   *
   * @param pageSize
   * @param sortBy
   * @param regionId
   * @returns
   */
  getCouncilByRegion(
    pageSize: number = 100,
    sortBy: string = 'id',
    regionId: number
  ): Observable<any> {
    return this._httpClient.get<Council[]>(
      `${this.baseAPI}/api/v1/get-councils-by-region-id/${regionId}?pageSize=${pageSize}&sortBy=${sortBy}`
    );
  }

  /**
   * get council details for a given council id
   * @param councilId
   * @returns
   */
  getCounciDetails(councilId: number): Observable<any> {
    return this._httpClient.get<Council[]>(
      `${this.baseAPI}/api/v1/get-council-details/${councilId}`
    );
  }

  /**
   * get wards by a given council id
   * @param pageSize
   * @param sortBy
   * @param councilId
   * @returns
   */
  getWardsByCouncilId(
    pageSize: number = 10000,
    sortBy: string = 'id',
    councilId: number
  ): Observable<any> {
    return this._httpClient.get<Council[]>(
      `${this.baseAPI}/api/v1/get-wards-by-council-id/${councilId}?pageSize=${pageSize}&sortBy=${sortBy}`
    );
  }

  /**
   * get ward details by a given ward id
   * @param wardId
   * @returns
   */
  getWardDetails(wardId: number): Observable<any> {
    return this._httpClient.get<Council[]>(
      `${this.baseAPI}/api/v1/get-ward-details/${wardId}`
    );
  }

  /**
   * fetch villages by a given ward id
   * @param pageSize
   * @param sortBy
   * @param wardId
   * @returns
   */
  getVillagesByWardId(
    pageSize: number = 10000,
    sortBy: string = 'id',
    wardId: number
  ): Observable<any> {
    return this._httpClient.get<Council[]>(
      `${this.baseAPI}/api/v1/get-villages-by-ward-id/${wardId}?pageSize=${pageSize}&sortBy=${sortBy}`
    );
  }

  /**
   * get administrative area by ID
   * @param pageSize
   * @param sortBy
   * @param parentId
   * @returns
   */
  getAdministrativeAreaByParentId(
    pageSize: number = 300,
    sortBy: string = 'id',
    parentId: number
  ): Observable<any> {
    return this._httpClient.get<Council[]>(
      `${this.baseAPI}/api/v1/get-administrative-area-by-parent-id/${parentId}?pageSize=${pageSize}&sortBy=${sortBy}`
    );
  }

  /**
   * get village details by a given village Id
   * @param villageId
   * @returns
   */
  getVillageDetails(villageId: number): Observable<any> {
    return this._httpClient.get<Council[]>(
      `${this.baseAPI}/api/v1/get-village-details/${villageId}`
    );
  }

  /**
   * get a revenue source signatory
   * @param tin
   * @returns
   */
  getRevenueSourceSignatory(tin: string): Observable<any> {
    return this._httpClient.get<CouncilSignatory>(
      `${this.baseAPI}/api/v1/revenue-source-signatory/${tin}`
    );
  }

  /**
   * get a revenue source signatory
   * @param tin
   * @returns
   */
  getCouncilSignatory(tin: string): Observable<any> {
    return this._httpClient.get<CouncilSignatory>(
      `${this.baseAPI}/api/v1/get-council-signatory-by-tin/${tin}`
    );
  }

  /**
   * stores a council into a database
   * @param council
   * @returns
   */
  saveCouncilEntity(council: CouncilEntity): Observable<any> {
    return this._httpClient.post(`${this.baseAPI}/api/v1/councils`, council);
  }

  /**
   * stores a council into a database
   * @param council
   * @returns
   */
  updateCouncilEntity(
    payload: CouncilEntity,
    councilId: string
  ): Observable<any> {
    return this._httpClient.put(
      `${this.baseAPI}/api/v1/councils/${councilId}`,
      payload
    );
  }

  /**
   * stores an institution in database
   * @param institution
   * @returns
   */
  saveInstitutionEntity(institution: InstitutionEntity): Observable<any> {
    return this._httpClient.post(
      `${this.baseAPI}/api/v1/institutions`,
      institution
    );
  }

  /**
   * stores a village into a database
   * @param village
   * @returns
   */
  saveVillageEntity(village: VillageEntity): Observable<any> {
    return this._httpClient.post(`${this.baseAPI}/api/v1/villages`, village);
  }

  /**
   * stores council signatory
   * @param payload
   * @returns
   */
  saveCouncilSignatory(payload: CouncilSignatory): Observable<any> {
    return this._httpClient.post(
      `${this.baseAPI}/api/v1/council-signatories`,
      payload
    );
  }

  /**
   *
   * @param entity
   * @param tinNumber
   * @returns
   */
  saveRevenueSourceSignatory(payload: any): Observable<any> {
    return this._httpClient.post<any>(
      `${this.baseAPI}/api/v1/revenue-source-signatories`,
      payload
    );
  }

  /**
   * fetch all council signatories
   * @returns
   */
  getCouncilSignatories(): Observable<any> {
    return this._httpClient.get<any[]>(
      `${this.baseAPI}/api/v1/council-signatories`
    );
  }

  /**
   * fetch council signatory by council id
   * @param councilId
   * @returns
   */
  getCouncilSignatoriesByCouncil(councilId: string): Observable<any> {
    return this._httpClient.get<any[]>(
      `${this.baseAPI}/api/v1/get-signatories-by-council/${councilId}`
    );
  }

  /**
   * get council
   * @param councilCode
   * @returns
   */
  getCouncilAccountOfficerByCouncil(councilCode: string): Observable<any> {
    return this._httpClient.get<any>(
      `${this.baseAPI}/api/v1/get-signatories-by-council/${councilCode}`
    );
  }

  /**
   * fetch council signatry by TIn
   * @param tin
   * @returns
   */
  getCouncilSignatoriesByTin(tinNumber: string): Observable<any> {
    return this._httpClient.get<any[]>(
      `${this.baseAPI}/api/v1/get-council-signatory-by-tin/${tinNumber}`
    );
  }

  /**
   * fetch Area Levels
   * @param tin
   * @returns
   */
  getAreaLevels(): Observable<any> {
    return this._httpClient.get<AreaLevel[]>(
      `${this.baseAPI}/api/v1/area-levels`
    );
  }

  /**
   * fetch council revenue sources
   * @param tin
   * @returns
   */
  getRevenueSources(financialYear: string): Observable<any> {
    return this._httpClient.get<RevenueSource[]>(
      `${this.baseAPI}/api/v1/gfs-code/tausi?financialYear=${financialYear}&pageNo=0&pageSize=50&sortBy=gfsCode`
    );
  }

  /**
   * fetch council signatories
   * @param pageSize
   * @returns
   */
  fetchCouncilSignatories(pageSize: number = 100): Observable<any> {
    return this._httpClient.get<any[]>(
      `${this.baseAPI}/api/v1/council-signatories?pageSize=${pageSize}&sortBy=id`
    );
  }

  /**
   * fetch council signatories by NIN
   * @param nin
   * @param pageSize
   * @returns
   */
  fetchCouncilSignatoryByNIN(
    nin: string,
    pageSize: number = 100
  ): Observable<any> {
    return this._httpClient.get<any[]>(
      `${this.baseAPI}/api/v1/get-council-signatory-by-nin/${nin}`
    );
  }

  /**
   * get council sgnatories by council id
   * @param councilId
   * @param pageSize
   * @returns
   */
  fetchCouncilSignatoryByCouncil(
    councilId: string,
    pageSize: number = 100
  ): Observable<any> {
    return this._httpClient.get<any[]>(
      `${this.baseAPI}/api/v1/get-signatories-by-council/${councilId}`
    );
  }

  /**
   * return list of gfscodes with others or generic flag
   * @param payload
   * @returns
   */
  getOtherRevenueSource(payload: any): Observable<any> {
    let param = `financialYear=${payload.financialYear}`;
    return this._httpClient.get<any[]>(
      `${this.baseAPI}/api/v1/gfs-code/generic-configured-source?${param}`
    );
  }

  /*
   * fetch council by area type
   * @returns councils[]
   */
  fetchCouncilByAreaType(): Observable<any> {
    return this._httpClient.get<any[]>(
      `${this.baseAPI}/api/v1/councils/area-type`
    );
  }

  /**
   * fetch council detail by a given area code/id
   * @param areaCode
   * @returns object
   */
  fetchCouncilDetails(areaCode: number): Observable<any> {
    return this._httpClient.get<any>(
      `${this.baseAPI}/api/v1/council-detail/${areaCode}`
    );
  }

  /**
   * create new financial year
   * @param payload
   * @returns
   */
  createFinancialYear(payload: financialYear): Observable<any> {
    return this._httpClient.post(
      `${this.baseAPI}/api/v1/financial-year`,
      payload
    );
  }

  /**
   * get all financial year
   */
  fetchFinancialYear(): Observable<any> {
    return this._httpClient.get<any[]>(`${this.baseAPI}/api/v1/financial-year`);
  }

  /**
   * update financial year
   * @param payload
   * @returns
   */
  updateFinancialYear(payload: any): Observable<any> {
    const id = payload.id;
    return this._httpClient.put(
      `${this.baseAPI}/api/v1/financial-year/id/${id}`,
      payload
    );
  }

  /**
   * get the current financial year
   * @returns
   */
  fetchCurrentFinancialYear(): Observable<any> {
    return this._httpClient.get<any[]>(
      `${this.baseAPI}/api/v1/current-financial-year`
    );
  }

  /**
   * return list of unmapped level 2 gfscodes
   * @returns
   */
  fetchUnmappedGfsCode(financialYear: any): Observable<any> {
    return this._httpClient.get<any[]>(
      `${this.baseAPI}/api/v1/gfs-code/unmapped?financialYear=${financialYear}`
    );
  }

  /**
   * save level 3 gfscodes and do mapping
   * @param payload
   * @returns
   */
  saveMappedGfsCode(payload: MapGfsCode) {
    return this._httpClient.post<Response>(
      `${this.baseAPI}/api/v1/gfs-code`,
      payload
    );
  }

  /**
   * return list of level 2 mapped with level 3 gfscodes
   * @param financialYear
   * @returns
   */
  fetchMappedGfsCode(financialYear: string): Observable<any> {
    return this._httpClient.get<any[]>(
      `${this.baseAPI}/api/v1/gfs-code/mapped?financialYear=${financialYear}`
    );
  }

  updateMappedGfsCode(payload: MapGfsCodeUpdate) {
    return this._httpClient.put<Response>(
      `${this.baseAPI}/api/v1/gfs-code`,
      payload
    );
  }

  /**
   *
   * @param id
   * @returns​
   */
  councilSignatoriesActivation(id: string) {
    return this._httpClient.put<any>(
      `${this.baseAPI}/api/v1/council-signatories-activation/${id}`,
      id
    );
  }

  getCouncilBankDetails(): Observable<any> {
    return this._httpClient.get<any[]>(
      `${this.baseAPI}/api/v1/councils/bank-details`
    );
  }

  /**
   * save council bank details
   * @param payload
   * @returns
   */
  storeCouncilBankDetails(payload: any): Observable<any> {
    return this._httpClient.post<any>(
      `${this.baseAPI}/api/v1/councils/bank-details`,
      payload
    );
  }

  /**
   * get all banks
   * @returns
   */
  fetchBanks(): Observable<any> {
    return this._httpClient.get<any[]>(`${this.baseAPI}/api/v1/councils/bank`);
  }

  /**
   * store banks
   * @param payload
   * @returns
   */
  storeBank(payload: any): Observable<any> {
    return this._httpClient.post<any>(
      `${this.baseAPI}/api/v1/councils/bank`,
      payload
    );
  }

  /**
   *
   * @param pageSize
   * @param sortBy
   * @returns
   */
  getPaymentMethod(
    pageSize: number = 100,
    sortBy: string = 'id'
  ): Observable<any> {
    return this._httpClient.get<PaymentMethod>(
      `${this.baseAPI}/api/v1/payment-methods?pageSize=${pageSize}&sortBy=${sortBy}`
    );
  }

  /**
   *
   * @param pageSize
   * @param sortBy
   * @returns
   */
  getUnitOfMeasurement(
    pageSize: number = 100,
    sortBy: string = 'id'
  ): Observable<any> {
    return this._httpClient.get<UnitOfMeasurement>(
      `${this.baseAPI}/api/v1/unit-of-measurements?pageSize=${pageSize}&sortBy=${sortBy}`
    );
  }

  /**
   *
   * @param financialYear
   * @returns
   */
  cloneRevenueSources(financialYear: string): Observable<any> {
    return this._httpClient.get<any>(
      `${this.baseAPI}/api/v1/revenue-source/council-clone?financialYear=${financialYear}`
    );
  }

  /**
   * get revenue sources categories
   * @returns
   */
  getRevenueSourceCategories(): Observable<any> {
    return this._httpClient.get<any>(
      `${this.baseAPI}/api/v1/revenue-source-category`
    );
  }

  /**
   * save gfscode import
   * @param payload
   * @returns
   */
  saveGfsCodeImport(payload: any): Observable<any> {
    return this._httpClient.post<any>(
      `${this.baseAPI}/api/v1/gfs-code/import`,
      payload
    );
  }
}
