import { Injectable } from '@angular/core';
import { Action, NgxsOnInit, Selector, State, StateContext } from '@ngxs/store';
import {
  buildingsByType,
  clearState,
  currentMonthCollection,
  monthlyCollections,
  registeredBuilding,
  totalCollection,
  unpaidBills,
  wardCollection,
} from './property-dashboard.actions';
import { PropertyDashboardStateModel } from './property-dashboard.model';
import { PropertyTaxService } from 'src/app/shared/services/property-tax.service';

@State<PropertyDashboardStateModel>({
  name: 'propertyDashboard',
  defaults: {
    wardCollections: { data: [], lastUpdate: '' },
    loading: false,
    unpaidBills: { data: 0, lastUpdate: '' },
    registeredBuilding: { data: 0, lastUpdate: '' },
    monthlyCollections: { data: [], lastUpdate: '' },
    currentMonthCollection: { data: 0, lastUpdate: '' },
    totalCollection: { data: 0, lastUpdate: '' },
    buildingsByType: { data: [], lastUpdate: '' },
  },
})
@Injectable({
  providedIn: 'root',
})
export class PropertyDashboardState implements NgxsOnInit {
  constructor(private _propertySvc: PropertyTaxService) {}

  @Selector()
  static wardCollections(state: PropertyDashboardStateModel) {
    return state.wardCollections;
  }

  @Selector()
  static unpaidBills(state: PropertyDashboardStateModel) {
    return state.unpaidBills;
  }

  @Selector()
  static registeredBuilding(state: PropertyDashboardStateModel) {
    return state.registeredBuilding;
  }

  @Selector()
  static monthlyCollections(state: PropertyDashboardStateModel) {
    return state.monthlyCollections;
  }

  @Selector()
  static currentMonthCollection(state: PropertyDashboardStateModel) {
    return state.currentMonthCollection;
  }

  @Selector()
  static totalCollection(state: PropertyDashboardStateModel) {
    return state.totalCollection;
  }

  @Selector()
  static buildingsByType(state: PropertyDashboardStateModel) {
    return state.buildingsByType;
  }

  @Action(wardCollection)
  loadWardCollection(
    ctx: StateContext<PropertyDashboardStateModel>,
    action: wardCollection
  ) {
    const state = ctx.getState();
    if (state?.wardCollections?.lastUpdate != wardCollection.type) {
      this._propertySvc.getWardCollections().subscribe((response) => {
        if (response.statusCode === '21000') {
          const data = response.data.item;
          ctx.patchState({
            wardCollections: { data: data, lastUpdate: wardCollection?.type },
          });
        }
      });
    }
  }

  @Action(unpaidBills)
  loadUnpaidBills(
    ctx: StateContext<PropertyDashboardStateModel>,
    action: unpaidBills
  ) {
    const state = ctx.getState();
    if (state?.unpaidBills?.lastUpdate != unpaidBills.type) {
      this._propertySvc.getUnPaidBills().subscribe((response) => {
        if (response.statusCode === '21000') {
          const data = response.data.item;
          ctx.patchState({
            unpaidBills: { data: data, lastUpdate: unpaidBills?.type },
          });
        }
      });
    }
  }

  @Action(registeredBuilding)
  loadRegisteredBuildings(
    ctx: StateContext<PropertyDashboardStateModel>,
    action: registeredBuilding
  ) {
    const state = ctx.getState();
    if (state?.registeredBuilding?.lastUpdate != registeredBuilding.type) {
      this._propertySvc.getAllRegisteredBuilding().subscribe((response) => {
        if (response.statusCode === '21000') {
          const data = response.data.item;
          ctx.patchState({
            registeredBuilding: {
              data: data,
              lastUpdate: registeredBuilding?.type,
            },
          });
        }
      });
    }
  }

  @Action(monthlyCollections)
  loadMonthlyCollections(
    ctx: StateContext<PropertyDashboardStateModel>,
    action: monthlyCollections
  ) {
    const state = ctx.getState();
    if (state?.monthlyCollections?.lastUpdate != monthlyCollections.type) {
      this._propertySvc.getMonthlyCollections().subscribe((response) => {
        if (response.statusCode === '21000') {
          const data = response.data.item;
          ctx.patchState({
            monthlyCollections: {
              data: data,
              lastUpdate: monthlyCollections?.type,
            },
          });
        }
      });
    }
  }

  @Action(currentMonthCollection)
  loadCurrentMonthCollection(
    ctx: StateContext<PropertyDashboardStateModel>,
    action: currentMonthCollection
  ) {
    const state = ctx.getState();
    if (
      state?.currentMonthCollection?.lastUpdate != currentMonthCollection.type
    ) {
      this._propertySvc.getCurrentMonthCollection().subscribe((response) => {
        if (response.statusCode === '21000') {
          const data = response.data.item;
          ctx.patchState({
            currentMonthCollection: {
              data: data,
              lastUpdate: currentMonthCollection?.type,
            },
          });
        }
      });
    }
  }

  @Action(totalCollection)
  loadTotalCollection(
    ctx: StateContext<PropertyDashboardStateModel>,
    action: totalCollection
  ) {
    const state = ctx.getState();
    if (state?.totalCollection?.lastUpdate != totalCollection.type) {
      this._propertySvc.getTotalCollection().subscribe((response) => {
        if (response.statusCode === '21000') {
          const data = response.data.item;
          ctx.patchState({
            totalCollection: {
              data: data,
              lastUpdate: totalCollection?.type,
            },
          });
        }
      });
    }
  }

  @Action(buildingsByType)
  loadBuildingsByType(
    ctx: StateContext<PropertyDashboardStateModel>,
    action: buildingsByType
  ) {
    const state = ctx.getState();
    if (state?.buildingsByType?.lastUpdate != buildingsByType.type) {
      this._propertySvc.getRegisteredBuildings().subscribe((response) => {
        if (response.statusCode === '21000') {
          const data = response.data.item;
          ctx.patchState({
            buildingsByType: {
              data: data,
              lastUpdate: buildingsByType?.type,
            },
          });
        }
      });
    }
  }

  @Action(clearState)
  clearState(ctx: StateContext<PropertyDashboardStateModel>) {
    const state = ctx.getState();
    ctx.setState({
      wardCollections: { data: [], lastUpdate: '' },
      loading: false,
      unpaidBills: { data: 0, lastUpdate: '' },
      registeredBuilding: { data: 0, lastUpdate: '' },
      monthlyCollections: { data: [], lastUpdate: '' },
      currentMonthCollection: { data: 0, lastUpdate: '' },
      totalCollection: { data: 0, lastUpdate: '' },
      buildingsByType: { data: [], lastUpdate: '' },
    });
  }

  ngxsOnInit(ctx: StateContext<any>): void {}
}
