<div class="data-card" [ngClass]="[widget.boColor]">
  <div class="counter-status">
    <div class="counter-icon" [ngClass]="[widget.bgColor]">
      <mat-icon class="avatar-icon" [ngClass]="[widget.textColor]">{{
        widget.icon
      }}</mat-icon>
    </div>
    <div class="ml-auto card-details">
      <h2>{{ widget.title }}</h2>
      <h4 class="" [ngClass]="[widget.textColor]">
        <span *ngIf="widget.type == 'currency'">
          {{ widget.amount | numberWithPrefix : "TZS " }}</span
        >
        <span *ngIf="widget.type == 'number'">
          {{ widget.amount | number }}</span
        >
      </h4>
      <p class="text-muted">{{ widget.info }}</p>
    </div>
  </div>
</div>
