import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Pageable } from 'src/app/shared/models/pageable.interface';
import { environment } from 'src/environments/environment';

import {
  ChangeAccountOauthPayload,
  CreateNewUserPayload,
  ListOfRolesBasedOnLevelPayload,
  NINDetail,
  SearchUserArea,
} from '../models/user-uaa.interface';
import { OTPRequest } from 'src/app/shared/models/user.model';

@Injectable({
  providedIn: 'root',
})
export class UserUaaService {
  private apiUrlUAAService: string =
    environment.apiUrl + environment.endPoints.uaa_service;

  users$!: Observable<any[]>;
  userDetails$!: Observable<any[]>;
  ninDetail$!: Observable<NINDetail[]>;
  searchedArea$!: Observable<any[]>;
  createNewUser$!: Observable<any[]>;
  switchAccount$!: Observable<any[]>;

  listOfRolesBasedOnLevel$!: Observable<any[]>;
  listOfGroupsBasedOnLevel$!: Observable<any[]>;
  listOfUsersBasedOnLevel$!: Observable<any[]>;

  userPrincipleDetails$!: Observable<any[]>;

  getUserDetailsById$!: Observable<any[]>;

  changeAccountOauth$!: Observable<any[]>;
  changeAccountpassword$!: Observable<any[]>;
  addAccount$!: Observable<any[]>;
  removeAccount$!: Observable<any>;
  updateUser$!: Observable<any[]>;

  listOfUserTypes$!: Observable<any[]>;

  constructor(private httpClient: HttpClient) {}

  searchNINDetail(payload: NINDetail) {
    return (this.ninDetail$ = this.httpClient.post<NINDetail[]>(
      `${this.apiUrlUAAService}/api/v1/nin-detail`,
      payload
    ));
  }

  getUserDetails(userId: String) {
    return (this.userDetails$ = this.httpClient.get<any[]>(
      `${this.apiUrlUAAService}/api/v1/user/id/${userId}`
    ));
  }

  searchAdministrativeArea(payload: SearchUserArea) {
    return (this.searchedArea$ = this.httpClient.post<any[]>(
      `${this.apiUrlUAAService}/api/v1/search-administrative-area`,
      payload
    ));
  }

  listOfRolesBasedOnLevel(payload: ListOfRolesBasedOnLevelPayload) {
    let param = `administrativeAreaId=${payload?.administrativeAreaId}`;

    return (this.listOfRolesBasedOnLevel$ = this.httpClient.get<any[]>(
      `${this.apiUrlUAAService}/api/v1/roles-to-assign?${param}`
    ));
  }

  getGroupsListBasedOnUserAreaLevel(payload: any) {
    let param = `administrativeAreaId=${payload?.administrativeAreaId}`;
    return (this.listOfGroupsBasedOnLevel$ = this.httpClient.get<any[]>(
      `${this.apiUrlUAAService}/api/v1/groups-to-assign?${param}`
    ));
  }

  getRolesListBasedOnUserAreaLevel(payload: Pageable) {
    let param = `administrativeAreaId=${payload.administrativeAreaId}&pageNo=${payload.pageNo}&pageSize=${payload.pageSize}&sortBy=${payload.sortBy}&searchKey=${payload.searchKey}`;
    return (this.listOfRolesBasedOnLevel$ = this.httpClient.get<any[]>(
      `${this.apiUrlUAAService}/api/v1/roles?${param}`
    ));
  }

  getUsersListBasedOnUserAreaLevel(payload: Pageable) {
    let param = `administrativeAreaId=${payload.administrativeAreaId}&pageNo=${payload.pageNo}&pageSize=${payload.pageSize}&sortBy=${payload.sortBy}&searchKey=${payload.searchKey}`;
    return (this.listOfUsersBasedOnLevel$ = this.httpClient.get<any[]>(
      `${this.apiUrlUAAService}/api/v1/users?${param}`
    ));
  }

  getUsersAreaLevel(payload: Pageable) {
    return (this.listOfUsersBasedOnLevel$ = this.httpClient.get<any[]>(
      `${this.apiUrlUAAService}/api/v1/users?administrativeAreaId=${payload.administrativeAreaId}&pageNo=${payload.pageNo}&pageSize=${payload.pageSize}&sortBy=${payload.sortBy}&searchKey=${payload.searchKey}`
    ));
  }

  // user princile details
  getListOfUserTypes() {
    return (this.listOfUserTypes$ = this.httpClient.get<any[]>(
      `${this.apiUrlUAAService}/api/v1/user/user-types`
    ));
  }

  // user princile details
  getUserPrincipleDetails() {
    return (this.userPrincipleDetails$ = this.httpClient.get<any[]>(
      `${this.apiUrlUAAService}/api/v1/user/info`
    ));
  }

  // create user
  createNewUser(payload: CreateNewUserPayload) {
    return (this.createNewUser$ = this.httpClient.post<any[]>(
      `${this.apiUrlUAAService}/api/v1/user/create`,
      payload
    ));
  }

  // Switch account
  switchAccount(payload: any) {
    return (this.switchAccount$ = this.httpClient.post<any[]>(
      `${this.apiUrlUAAService}/api/v1/user/switch-account`,
      payload
    ));
  }

  // changeAccountOauth
  changeAccountOauth(payload: ChangeAccountOauthPayload) {
    return (this.changeAccountOauth$ = this.httpClient.post<any[]>(
      `${this.apiUrlUAAService}/api/v1/user/change-account-oauth`,
      payload
    ));
  }

  // changeAccountpassword
  changeAccountpassword(payload: any) {
    return (this.changeAccountpassword$ = this.httpClient.post<any[]>(
      `${this.apiUrlUAAService}/api/v1/user/change-account-password`,
      payload
    ));
  }

  // addAccount
  addAccount(payload: any) {
    return (this.addAccount$ = this.httpClient.post<any[]>(
      `${this.apiUrlUAAService}/api/v1/user/add-account`,
      payload
    ));
  }

  // removeAccount
  removeAccount(payload: any) {
    return (this.removeAccount$ = this.httpClient.post<any[]>(
      `${this.apiUrlUAAService}/api/v1/user/remove-account`,
      payload
    ));
  }

  // removeClientt
  removeClient(payload: any) {
    return (this.removeAccount$ = this.httpClient.post<any[]>(
      `${this.apiUrlUAAService}/api/v1/oauth-client/remove-user-client-id`,
      payload
    ));
  }

  listOfClientIds() {
    return (this.listOfRolesBasedOnLevel$ = this.httpClient.get<any[]>(
      `${this.apiUrlUAAService}/api/v1/oauth-client/list-client-id`
    ));
  }

  // addClientToUser
  addClientToUser(payload: any) {
    return (this.removeAccount$ = this.httpClient.post<any[]>(
      `${this.apiUrlUAAService}/api/v1/oauth-client/add-user-client-id`,
      payload
    ));
  }

  // updateUser
  updateUser(payload: any) {
    return (this.updateUser$ = this.httpClient.post<any[]>(
      `${this.apiUrlUAAService}/api/v1/user/update`,
      payload
    ));
  }

  // getUserDetailsById But already done up there
  getUserDetailsById(userId: string) {
    return (this.getUserDetailsById$ = this.httpClient.get<any[]>(
      `${this.apiUrlUAAService}/api/v1/user/id/${userId}`
    ));
  }
  //get registered accounts
  getUserAccounts(payload: any): Observable<any> {
    return (this.userPrincipleDetails$ = this.httpClient.get<any[]>(
      `${this.apiUrlUAAService}/api/v1/nin-tin?pageNo=${payload.pageNo}&pageSize=${payload.pageSize}&sortBy=${payload.sortBy}`
    ));
  }

  //get registered accounts
  getCompanyAccounts(payload: any): Observable<any> {
    return (this.userPrincipleDetails$ = this.httpClient.get<any[]>(
      `${this.apiUrlUAAService}/api/v1/nin-tin/category/${payload.category}?pageNo=${payload.pageNo}&pageSize=${payload.pageSize}&sortBy=${payload.sortBy}`
    ));
  }

  //register company user
  registerCompanyUser(payload: any): Observable<any> {
    return (this.updateUser$ = this.httpClient.post<any[]>(
      `${this.apiUrlUAAService}/api/v1/nin-tin-company-caretaker`,
      payload
    ));
  }

  updateCompanyUser(payload: any, nin: any, tin: any): Observable<any> {
    return (this.updateUser$ = this.httpClient.post<any[]>(
      `${this.apiUrlUAAService}/api/v1/nin-tin-company-caretaker/nin/${nin}/tin/${tin}`,
      payload
    ));
  }

  //register external agent caretaker
  registerAgentCaretaker(payload: any): Observable<any> {
    return (this.updateUser$ = this.httpClient.post<any[]>(
      `${this.apiUrlUAAService}/api/v1/nin-tin-external-agent`,
      payload
    ));
  }

  //register company caretaker
  registerCompanyCaretaker(payload: any): Observable<any> {
    return (this.updateUser$ = this.httpClient.post<any[]>(
      `${this.apiUrlUAAService}/api/v1/nin-tin-company-caretaker`,
      payload
    ));
  }

  /**
   * return list of terms and conditions
   * @param payload
   * @returns
   */
  getTermsCondition(payload: any): Observable<any> {
    return (this.userPrincipleDetails$ = this.httpClient.get<any[]>(
      `${this.apiUrlUAAService}/api/v1/nin-tin-terms-and-conditions`,
      { params: payload }
    ));
  }

  /**
   * post terms and condition
   * @param payload
   * @returns
   */
  saveTermsCondition(payload: any): Observable<any> {
    return (this.userPrincipleDetails$ = this.httpClient.post<any[]>(
      `${this.apiUrlUAAService}/api/v1/nin-tin-term-and-condition`,
      payload
    ));
  }

  updateTermsCondition(payload: any): Observable<any> {
    const data = {
      name: payload.name,
      description: payload.description,
      active: payload.active,
    };
    return (this.userPrincipleDetails$ = this.httpClient.post<any[]>(
      `${this.apiUrlUAAService}/api/v1/nin-tin-term-and-condition/id/${payload.id}`,
      data
    ));
  }

  /**
   *request password reset
   * @param payload
   * @returns
   */
  requestPasswordReset(payload: any): Observable<any> {
    return this.httpClient.post(
      `${this.apiUrlUAAService}/api/v1/resetting-password-nin/request-reset-password`,
      payload
    );
  }

  /**
   * verify request password reset
   * @param payload
   * @returns
   */
  verifyRequestPasswordReset(payload: any): Observable<any> {
    return this.httpClient.post(
      `${this.apiUrlUAAService}/api/v1/resetting-password-nin/verify-reset-password`,
      payload
    );
  }
  /**
   * reset password
   * @param payload
   * @returns
   */
  resetPassword(payload: any): Observable<any> {
    return this.httpClient.post(
      `${this.apiUrlUAAService}/api/v1/resetting-password-nin/reset-password`,
      payload
    );
  }

  getPassowrdPolicy(): Observable<any> {
    return this.httpClient.get<any[]>(
      `${this.apiUrlUAAService}/api/v1/resetting-password-nin/password-policy`
    );
  }

  /**
   * @payload
   * search nin tin
   */
  getNinTinByKeyword(payload: any): Observable<any> {
    return this.httpClient.get<any[]>(
      `${this.apiUrlUAAService}/api/v1/nin-tin`,
      { params: payload }
    );
  }

  searchNinTinByKeyword(payload: any): Observable<any> {
    return this.httpClient.get<any[]>(
      `${this.apiUrlUAAService}/api/v1/check-id-tin-mapped`,
      { params: payload }
    );
  }

  getCaretakerHistory(tin: string): Observable<any> {
    return this.httpClient.get<any[]>(
      `${this.apiUrlUAAService}/api/v1/company-care-takers/${tin}`
    );
  }

  getAttachment(payload: any): Observable<any> {
    return this.httpClient.get<any[]>(
      `${this.apiUrlUAAService}/api/v1/company-care-takers/attachment`,
      { params: payload }
    );
  }

  /**fix external agent */
  fixExternalAgent(payload: any): Observable<any> {
    return this.httpClient.post(
      `${this.apiUrlUAAService}/api/v1/nin-tin/fix-external-agent`,
      payload
    );
  }

  /** get OTP */
  getOTP(payload: OTPRequest): Observable<any> {
    return this.httpClient.post<any[]>(
      `${this.apiUrlUAAService}/api/v2/open-user/request-otp`,
      payload
    );
  }

  /** verify OTP */
  verifyOTP(payload: OTPRequest): Observable<any> {
    return this.httpClient.post<any[]>(
      `${this.apiUrlUAAService}/api/v2/open-user/verify-otp`,
      payload
    );
  }
}
