import {MatSidenav} from "@angular/material/sidenav";
import {Injectable} from "@angular/core";
import {BehaviorSubject} from "rxjs";

@Injectable({
  providedIn: 'root',
})
export class SidenavService {
    private sidenavLeft!: MatSidenav;
    private sidenavRight!: MatSidenav;

    private adminCode$ = new BehaviorSubject<any>({});
    selectedAdminCode$ = this.adminCode$.asObservable();

    private filter$ = new BehaviorSubject<any>({});
    selectedFilter$ = this.filter$.asObservable();

    private info$ = new BehaviorSubject<any>({});
    selectedInfo$ = this.info$.asObservable();

    private projectTypeCode$ = new BehaviorSubject<any>({});
    selectedProjectTypeCode$ = this.projectTypeCode$.asObservable();

    private projectId$ = new BehaviorSubject<any>({});
    selectedProjectId$ = this.projectId$.asObservable();

    private plotInfo$ = new BehaviorSubject<any>({});
    selectedPlotInfo$ = this.plotInfo$.asObservable();

    private projectInfo$ = new BehaviorSubject<any>({});
    selectedProjectInfo$ = this.projectInfo$.asObservable();

    private geoJson$ = new BehaviorSubject<any>({});
    selectedGeoJson$ = this.geoJson$.asObservable();

    public setAdminCode(adminCode$: any) {
      this.adminCode$.next(adminCode$);
    }

    public setProjectId(projectId$: any) {
      this.projectId$.next(projectId$);
    }

    public setFilter(filter$: boolean) {
      this.filter$.next(filter$);
    }

    public setProjectTypeCode(projectTypeCode$: string) {
      this.projectTypeCode$.next(projectTypeCode$);
    }

    public setInfo(info$: boolean) {
      this.info$.next(info$);
    }

    public setPlotInfo(plotInfo$: any) {
      this.plotInfo$.next(plotInfo$);
    }

  public setProjectInfo(projectInfo$: any) {
    this.projectInfo$.next(projectInfo$);
  }

  public setGeoJson(geoJson$: any) {
    this.geoJson$.next(geoJson$);
  }

    public setRightSidenav(sidenav: MatSidenav) {
        if(sidenav!== undefined){
          this.sidenavRight = sidenav;
        }
    }

    public setLeftSidenav(sidenav: MatSidenav) {
      if(sidenav!== undefined){
        this.sidenavLeft = sidenav;
      }
    }

    public openLeft() {
        return this.sidenavLeft.open();
    }


    public closeRight() {
        return this.sidenavRight.close();
    }

    public openRight() {
      return this.sidenavRight.open();
    }


    public closeLeft() {
      return this.sidenavLeft.close();
    }

      public toggleLeft() {
      this.sidenavLeft.toggle();
      return this.sidenavLeft.opened;
     }

      public toggleRight() {
        this.sidenavRight.toggle();
        return this.sidenavRight.opened;
      }

      public getSideNav(){
        return {
          left:this.sidenavLeft,
          right:this.sidenavRight
        }
      }

   }
