<!-- this is for pdf documents -->
<div *ngIf="isPdf" class="container-wrapper">
  <ngx-extended-pdf-viewer
    [src]="'assets/example.pdf'"
    backgroundColor="#616161"
    height="80vh"
    [zoom]="zoom"
    [useBrowserLocale]="true"
    [showZoomButtons]="false"
    [customFreeFloatingBar]="floatingZoombar"
    [customToolbar]="custom"
  >
    ></ngx-extended-pdf-viewer
  >
</div>

<!-- this is for base64 data strings -->
<div *ngIf="isBase64" class="container-wrapper">
  <ngx-extended-pdf-viewer
    [base64Src]="formatedBase64file"
    backgroundColor="#616161"
    [height]="'80vh'"
    [useBrowserLocale]="true"
    [zoom]="zoom"
    [useBrowserLocale]="true"
    [showZoomButtons]="false"
    [customFreeFloatingBar]="floatingZoombar"
    [customToolbar]="custom"
  >
  </ngx-extended-pdf-viewer>
</div>

<ng-template #custom>
  <div id="toolbarViewer" class="toolbar">
    <div id="toolbarViewerLeft">
      <pdf-toggle-sidebar></pdf-toggle-sidebar>
      <div class="toolbarButtonSpacer"></div>
      <pdf-find-button
        [showFindButton]="true"
        [textLayer]="true"
      ></pdf-find-button>
      <pdf-paging-area></pdf-paging-area>
    </div>
    <div id="toolbarViewerMiddle">
      <h3 class="text-600 viewer-title">
        {{ item?.name }} <span>{{ item?.contractNumber }}</span>
      </h3>
    </div>
    <div id="toolbarViewerRight">
      <pdf-open-file></pdf-open-file>
      <pdf-presentation-mode></pdf-presentation-mode>
      <pdf-print></pdf-print>
      <pdf-download></pdf-download>
      <pdf-bookmark></pdf-bookmark>
      <div
        class="close-button-container p-ml-4"
        (click)="closePreview()"
        pTooltip="Close Preview Window"
        tooltipPosition="left"
        tooltipTrigger="hover"
      >
        <i class="pi pi-times"></i>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #floatingZoombar>
  <div class="zoom-controls">
    <pdf-zoom-dropdown class="invisible"></pdf-zoom-dropdown>
    <div class="form-check form-check-inline p-mr-3">
      <input
        type="radio"
        id="zoom50"
        class="form-check-input"
        tabindex="94"
        (click)="zoom = '50%'"
        name="zoom"
        [checked]="zoom === '50%'"
      />
      <label class="form-check-label" for="zoom50">50%</label>
    </div>

    <div class="form-check form-check-inline p-mr-3">
      <input
        type="radio"
        id="zoom100"
        class="form-check-input"
        tabindex="95"
        (click)="zoom = '100%'"
        name="zoom"
        [checked]="zoom === '100%'"
      />
      <label class="form-check-label" for="zoom100">100%</label>
    </div>

    <div class="form-check form-check-inline p-mr-3">
      <input
        type="radio"
        id="zoom200"
        class="form-check-input"
        tabindex="96"
        (click)="zoom = '200%'"
        name="zoom"
        [checked]="zoom === '200%'"
      />
      <label class="form-check-label" for="zoom200">200%</label>
    </div>

    <div class="form-check form-check-inline">
      <input
        type="radio"
        id="zoom-auto"
        class="form-check-input"
        tabindex="97"
        (click)="zoom = 'auto'"
        name="zoom"
        [checked]="zoom === 'auto'"
      />
      <label class="form-check-label" for="zoom-auto">Auto</label>
    </div>
  </div>
</ng-template>
