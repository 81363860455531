import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ConfirmationService } from 'primeng/api';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { TaxpayerManagementService } from '../../services/taxpayer-management.service';

@Component({
  selector: 'tausi-one-time-password',
  templateUrl: './one-time-password.component.html',
  styleUrls: ['./one-time-password.component.scss'],
})
export class OneTimePasswordComponent implements OnInit {
  public categories: any;
  public code: any;
  public showOTP!: boolean;
  public otpform!: FormGroup;
  public otp: any;
  public isValid!: boolean;
  public phoneNumber: any;
  public nin: any;
  public listNins: any[] = [];
  public message: string;
  public isLoading!: boolean;

  constructor(
    private _taxpayerSvc: TaxpayerManagementService,
    private _formBuilder: FormBuilder,
    private _ref: DynamicDialogRef,
    private _config: DynamicDialogConfig,
    private _confirmationSvc: ConfirmationService
  ) {
    this.code = this._config.data.code;
    this.message = '';
    this.showOTP = false;
    this.isValid = false;
    this.isLoading = false;
  }

  ngOnInit(): void {
    this.otpform = this.initform();
    setTimeout(() => {
      this.fetchAllTaxpayerCategories();
    }, 0);
  }

  initform(): FormGroup {
    return this._formBuilder.group({
      phoneNumber: ['', Validators.required],
      nin: ['', Validators.required],
    });
  }

  /**
   * search taxpayer by nin
   * @param item
   */
  search(item: any): void {
    let nin = this.otpform.get('nin')?.value.split('-').join('');
    this.searchHandler(item);
  }

  /**
   * get one time password
   * @param payload
   */
  otpSearchHandler(item: any): void {
    let transformed = this.replaceNumbers(item.phoneNumber);
    let message = `We have have sent an OTP to ${transformed}.`;

    let payload = {
      nin: item.nin.split('-').join(''),
      phoneNumber: item.phoneNumber,
    };

    this.nin = item.nin;
    this.phoneNumber = item.phoneNumber;

    this._taxpayerSvc.getOneTimePassword(payload).subscribe((response) => {
      if (response.data.code === '060') {
        this.showOTP = true;
      } else if (response.data.code === '061') {
        this.message = response.data.message;
        this.confirm(this.message, 'pi-exclamation-triangle warning');
      } else {
        this.message = response.data.message;
        this.confirm(this.message, 'pi-exclamation-triangle warning');
      }
    });
  }

  ninDetailsHandler(): void {
    const payload = {
      nin: this.nin.split('-').join(''),
      phoneNumber: this.phoneNumber,
      otp: this.otp,
    };

    this._taxpayerSvc.getOneTimePassword(payload).subscribe((response) => {
      if (response.code === 21000) {
        if (response.data.code === '00') {
          this.saveTaxpayerHandle(response.data.data.nidaResponse);
        }
      }
    });
  }

  /**
   * replace numbers
   * @param phone
   * @returns
   */
  replaceNumbers(phone: any): void {
    let transformed;
    for (var i = 0; i <= 3; i++) {
      transformed = phone.replace(i, '*');
    }
    return transformed;
  }

  /**
   * handle change on form input
   * @param event
   */
  handleChange(event: any): void {
    if (event.length === 6) {
      this.otp = event;
      this.isValid = true;
      this.ninDetailsHandler();
    } else {
      this.otp = null;
      this.isValid = true;
    }
  }

  /**
   * search taxpayer internal by NIN
   * 19851223-27101-00001-14
   * @param nin
   */
  searchHandler(item: any): void {
    let nin = item.nin.split('-').join('');
    this._taxpayerSvc.searchTaxpayerByNIN(nin).subscribe((response) => {
      if (response.statusCode === '21000') {
        const payload = {
          nida: response.data.item,
          taxpayer: response.data.item,
          extras: this.categories[0],
        };
        this._ref.close(payload);
      } else if (response.statusCode === '21003') {
        this.otpSearchHandler(item);
      }
    });
  }

  /**
   * confirmation dialog
   * @param message
   * @param icon
   */
  confirm(message: string, icon: string) {
    this._confirmationSvc.confirm({
      header: 'Information',
      key: 'confirmation',
      message: `${message}`,
      icon: `pi ${icon}`,
      accept: () => {},
    });
  }

  /**
   * save taxpayer handler
   * @param item
   */
  saveTaxpayerHandle(item: any): void {
    this.isLoading = true;
    const payload = {
      tin: '',
      tradingname: '',
      firstname: item?.firstname,
      middlename: item?.middlename,
      surname: item?.surname,
      othernames: item?.othernames,
      sex: item?.sex,
      dateofbirth: item?.dateofbirth,
      placeofbirth: item?.placeofbirth,
      residentregion: item?.residentregion,
      residentdistrict: item?.residentdistrict,
      residentward: item?.residentward,
      residentvillage: item?.residentvillage,
      residentstreet: item?.residentstreet,
      residenthouseno: item?.residenthouseno,
      residentpostaladdress: item?.residentpostaladdress,
      residentpostcode: item?.residentpostcode,
      birthcountry: item?.birthcountry,
      birthregion: item?.birthregion,
      birthdistrict: item?.birthdistrict,
      birthward: item?.birthward,
      nationality: item?.nationality,
      photo: item?.photo,
      mobilenumber: item?.phonenumber === null ? '' : item?.phonenumber,
      telephonenumber: item?.phonenumber === null ? '' : item?.phonenumber,
      email: item?.email,
      nin: item?.nin,
      taxpayerCategoryId: this.categories[0].id,
    };
    this._taxpayerSvc.storeTaxpayer(payload).subscribe((response) => {
      if (response.statusCode === '21000') {
        const payload = {
          nida: item,
          collector: response.data.item,
          taxpayer: response.data.item,
          extras: this.categories[0],
        };
        setTimeout(() => {
          this.isLoading = false;
          this._ref.close(payload);
        }, 2000);
      } else {
        this.isLoading = false;
      }
    });
  }

  ngOnDestroy(): void {
    if (this._ref) {
      this._ref.close();
    }
  }

  close(): void {
    this._ref.close();
  }

  previous(): void {
    if (this.showOTP) {
      this.showOTP = false;
    }
  }

  fetchAllTaxpayerCategories(): void {
    this._taxpayerSvc.fetchTaxpayerCategories().subscribe((response) => {
      if (response.statusCode === '21000') {
        this.categories = response.data.itemList.filter(
          (item: any) => item.code === 'T100200400'
        );
      }
    });
  }
}
