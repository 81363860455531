import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'tausi-info-cards',
  templateUrl: './info-cards.component.html',
  styleUrls: ['./info-cards.component.scss'],
})
export class InfoCardsComponent implements OnInit {
  @Input() widget: any;
  constructor() {}

  ngOnInit(): void {}
}
