import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'tausi-collection-point-map',
  templateUrl: './collection-point-map.component.html',
  styleUrls: ['./collection-point-map.component.scss'],
})
export class CollectionPointMapComponent implements OnInit {
  /**
   *Map Viewer
   */
  public options: any;
  public overlays!: any[];
  constructor() {}

  ngOnInit() {
    this.options = {
      center: { lat: 36.890257, lng: 30.707417 },
      zoom: 12,
    };
  }
}
