import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import {
  LockoutPolicy,
  PasswordPolicy,
} from '../models/password-and-lockout-policy.interface';

@Injectable({
  providedIn: 'root',
})
export class PasswordAndLockoutPolicyService {
  private apiUrlUAAService: string =
    environment.apiUrl + environment.endPoints.uaa_service;

  lockoutPolicy$!: Observable<LockoutPolicy>;
  passwordPolicy$!: Observable<PasswordPolicy>;

  constructor(private httpClient: HttpClient) {}

  // policy
  getLockoutPolicy() {
    return (this.lockoutPolicy$ = this.httpClient.get<LockoutPolicy>(
      `${this.apiUrlUAAService}/api/v1/lockout-policy`
    ));
  }

  // remember to make it PUT
  putLockoutPolicy(id: String, payload: LockoutPolicy) {
    return (this.lockoutPolicy$ = this.httpClient.post<LockoutPolicy>(
      `${this.apiUrlUAAService}/api/v1/lockout-policy/${id}`,
      payload
    ));
  }

  // password
  getPasswordPolicy() {
    return (this.passwordPolicy$ = this.httpClient.get<PasswordPolicy>(
      `${this.apiUrlUAAService}/api/v1/password-policy`
    ));
  }

  // remember to make it PUT
  putPasswordPolicy(id: String, payload: PasswordPolicy) {
    return (this.passwordPolicy$ = this.httpClient.post<PasswordPolicy>(
      `${this.apiUrlUAAService}/api/v1/password-policy/${id}`,
      payload
    ));
  }
}
