import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'numberWithPrefix' })
export class NumberWithPrefixPipe implements PipeTransform {
  transform(value: number, prefix: string = ''): string {
    if (isNaN(value)) {
      return '';
    }
    const number = Math.floor(value);

    const formatter = new Intl.NumberFormat('en-US');
    const formattedNumber = formatter.format(number);
    return prefix + formattedNumber;
  }
}
