<div class="px-2 py-2">
  <div class="px-0 mt-2">
    <div class="px-2">
      <form [formGroup]="validateform" autocomplete="off" novalidate>
        <div class="p-grid p-fluid">
          <div class="p-col-12">
            <div class="p-formgroup-inline">
              <h6 class="text-muted" style="margin-right: 2rem">
                Choose Taxpayer Type
              </h6>
              <div
                class="p-field-checkbox"
                *ngFor="let category of taxpayer_categories"
              >
                <p-radioButton
                  formControlName="taxpayer_category"
                  name="taxpayer_category"
                  value="{{ category.code }}"
                  inputId="{{ category.code }}"
                ></p-radioButton>
                <label for="{{ category.code }}">{{
                  category.description
                }}</label>
              </div>
            </div>
          </div>
        </div>

        <div class="p-grid p-fluid">
          <div class="p-field p-col-12">
            <span class="p-float-label" *ngIf="isLegal">
              <p-inputMask
                pTooltip="Enter TIN number correctly"
                tooltipPosition="top"
                id="tinInput"
                mask="999-999-999"
                name="tin"
                formControlName="tinNumber"
              ></p-inputMask>
              <label for="tinInput">TIN Number</label>
            </span>

            <span class="p-float-label" *ngIf="isIndividual">
              <p-inputMask
                pTooltip="Enter NIN number correctly"
                tooltipPosition="top"
                id="ninInput"
                mask="99999999-99999-99999-99"
                name="nin"
                formControlName="ninNumber"
              ></p-inputMask>
              <label for="ninInput">NIN Number</label>
            </span>
          </div>
        </div>
      </form>
    </div>
  </div>

  <div fxLayout="row" fxLayoutAlign="end center">
    <div fxLayout="row" fxLAyoutAlign="space-between center">
      <button
        [disabled]="validateform.invalid"
        type="button"
        (click)="search()"
        label="Search"
        icon="pi pi-search"
        iconPos="left"
        pButton
      ></button>
    </div>
  </div>
</div>
