<div class="page-wrapper">
  <div class="d-flex justify-content-end align-items-center p-3">
    <i class="pi pi-times pointer" (click)="close()"></i>
  </div>
  <div class="p-p-md-5">
    <div
      class="d-flex flex-column justify-content-center align-items-center mb-md-3"
    >
      <div class="d-flex justify-content-center">
        <img
          src="./assets/imgs/tausi_logo.svg"
          alt=""
          width="50%"
          class="img-fluid"
        />
      </div>
    </div>

    <h2 class="text-center title-text">{{ header }}</h2>
    <p class="text-center text-muted">
      {{ title }}
    </p>

    <div class="p-grid p-fluid" *ngIf="otpVerification">
      <div class="p-col-12 d-flex justify-content-center align-items-center">
        <ng-otp-input
          (onInputChange)="handleChange($event)"
          [config]="{ length: 6 }"
        ></ng-otp-input>
      </div>
      <div class="p-col-12">
        <div class="d-flex justify-content-center align-items-center">
          <div class="sub-form-reset">
            <span class="text-muted mb-3">Didn't receive code? </span>
            <span class="reset-link p-link" (click)="resend()" translate>
              Resend
            </span>
          </div>
        </div>
      </div>
    </div>

    <div *ngIf="!otpVerification">
      <form class="" [formGroup]="otpForm" novalidate autocomplete="off">
        <div class="p-grid gap-3 p-fluid p-formgrid">
          <div class="p-field p-col-12">
            <span class="p-float-label">
              <p-inputMask
                formControlName="nin"
                [required]="true"
                mask="99999999-99999-99999-99"
                [disabled]="data?.nin != undefined"
              >
              </p-inputMask>
              <label for="inputUsername">National Identification Number</label>
            </span>
          </div>

          <div class="p-field p-col-12">
            <p-inputMask
              name="phoneNumber"
              id="phoneNumber"
              mask="9999999999"
              formControlName="phoneNumber"
              placeholder="0777777777"
            ></p-inputMask>
          </div>
        </div>
      </form>
    </div>

    <div class="p-grid p-fluid">
      <div class="p-col-12">
        <div *ngIf="errorMessage" class="flex bg-c-warning p-3 my-2">
          <div
            class="flex-initial flex align-items-start justify-content-center m-2"
          >
            <i
              class="pi pi-exclamation-triangle text-third"
              style="font-size: 2rem"
            ></i>
          </div>
          <div
            class="flex-initial text-third flex align-items-start justify-content-center"
          >
            {{ errorMessage }}
          </div>
        </div>
      </div>
      <div class="p-col-12 p-mt-1">
        <div *ngIf="!otpVerification">
          <button
            pButton
            class="p-button medium-btn"
            (click)="submitNotification(otpForm.value)"
            label="{{ 'pri.login.next' | translate }}"
            [disabled]="otpForm.invalid || submitting"
          ></button>
        </div>
        <div *ngIf="otpVerification">
          <button
            pButton
            class="p-button medium-btn"
            (click)="submitOtp()"
            label="Submit"
            [disabled]="!isValid || submitting"
          ></button>
        </div>
      </div>
    </div>
  </div>
</div>
