import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'tausi-default-page',
  templateUrl: './default-page.component.html',
  styleUrls: ['./default-page.component.scss'],
})
export class DefaultPageComponent implements OnInit {
  @Input() title?: string;
  @Input() description?: string;

  constructor(private _router: Router) {}

  ngOnInit(): void {}

  public navigate(): void {
    this._router.navigateByUrl('/');
  }
}
