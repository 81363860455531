import { Component, OnInit } from '@angular/core';
import Map from 'ol/Map';
import View from 'ol/View';
import { Tile as TileLayer } from 'ol/layer';
import { Vector } from 'ol/source';
import { OSM } from 'ol/source';
import { MenuItem } from 'primeng/api';
import VectorLayer from 'ol/layer/Vector';

@Component({
  selector: 'tausi-pos-locations',
  templateUrl: './pos-locations.component.html',
  styleUrls: ['./pos-locations.component.scss'],
})
export class PosLocationsComponent implements OnInit {
  public map!: Map;
  public items!: MenuItem[];
  public baseLayer: any;
  public featureLayer: any;
  public drawLayer: any;
  public drawSource: any;

  constructor() {}

  ngOnInit(): void {
    this.initializeLayers();
    this.initializeMap();

    this.items = [
      {
        label: 'Chart Options',
        items: [
          {
            label: 'Download',
            icon: 'pi pi-download',
          },
          {
            label: 'Share',
            icon: 'pi pi-share-alt',
          },
        ],
      },
    ];
  }

  initializeMap() {
    this.map = new Map({
      layers: [this.baseLayer, this.featureLayer, this.drawLayer],
      target: 'map',
      view: new View({
        center: [36.719, -3.379],
        zoom: 13,
        projection: 'EPSG:4326',
      }),
    });
  }

  initializeLayers() {
    this.featureLayer = new TileLayer({
      source: this.drawSource,
    });
    this.drawSource = new Vector();

    this.drawLayer = new VectorLayer({
      source: this.drawSource,
    });

    this.baseLayer = new TileLayer({
      source: new OSM({
        attributions: 'TAUSI GIS Application',
        wrapX: true,
      }),
    });
  }
}
