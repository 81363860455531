import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HTTP_INTERCEPTORS,
} from '@angular/common/http';
import { ClassProvider, Injectable } from '@angular/core';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { TausiHttpError } from '../models/auth.interface';
import { AuthService } from '../services/auth.service';
import { TausiMessagingService } from '../services/tausi-messaging.service';
import { ToastService } from '../services/toast.service';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  urlsToNotUse!: Array<string>;


  constructor(
    private _messageService: ToastService,
    private _authService: AuthService,
    private _tausiMessagingService: TausiMessagingService,

  ) {
    this.urlsToNotUse = ['user/logout'];
  }



  private isValidRequestForInterceptor(requestUrl: string): boolean {
    let positionIndicator: string = 'api/v1/';
    let position = requestUrl.indexOf(positionIndicator);
    if (position > 0) {
      let destination: string = requestUrl.substr(
        position + positionIndicator.length
      );
      for (let address of this.urlsToNotUse) {
        if (new RegExp(address).test(destination)) {
          return false;
        }
      }
    }
    return true;
  }

  /**
   * Intercepts an outgoing HTTP request, executes it and handles any error that could be triggered in execution.
   * @see HttpInterceptor
   * @param req the outgoing HTTP request
   * @param next a HTTP request handler
   */
  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      catchError((errorReponse) => {
        let errMsg: string;
        if (errorReponse instanceof HttpErrorResponse) {
          const err =
            errorReponse.message || JSON.stringify(errorReponse.error);
          errMsg = `${errorReponse.status} - ${errorReponse.statusText || ''}`;
        } else {
          errMsg = errorReponse.message
            ? errorReponse.message
            : errorReponse.toString();


        }

        if (errorReponse.status === 401) {
          errMsg = errorReponse?.error?.error_description;
          this._authService.logoutLocal();
          if (this.isValidRequestForInterceptor(request.url)) {
          }
        }

        if (
          errorReponse.status === 400 &&
          !this._authService.isAuthenticated()
        ) {
          errMsg = errorReponse?.error?.error_description;
          this._authService.logoutLocal();
          this._authService.logout();
        }
        // this._messageService.error(errMsg);

        let tausiError: TausiHttpError = {
          status: true,
          message: errMsg,
          errorReponse: errorReponse

        }
        this._tausiMessagingService.changeTausiHttpError(tausiError);


        return throwError(errMsg);
      })
    );
  }
}

/**
 * Provider POJO for the interceptor
 */
export const ErrorInterceptorProvider: ClassProvider = {
  provide: HTTP_INTERCEPTORS,
  useClass: ErrorInterceptor,
  multi: true,
};
