import { Injectable } from '@angular/core';
import { Action, NgxsOnInit, Selector, State, StateContext } from '@ngxs/store';
import { dashboardData } from './landrent-dashboard.actions';
import {LandRentDashboardStateModel} from './landrent-dashboard.model';
import {LandRentService} from "../../shared/services/land-rent.service";

@State<LandRentDashboardStateModel>({
  name: 'landRentDashboard',
  defaults: {
    data: {
      data: {
        currentCollection: null,
        unPaidRevenue: null,
        currentRevenue: null,
        totalPlots: null,
        monthlyCollection: []
      },
      lastUpdate: '',
    },
    loading: false,
  },
})
@Injectable({
  providedIn: 'root',
})
export class LandRentDashboardState implements NgxsOnInit {
  constructor(private _landRentService: LandRentService) {}

  @Selector()
  static loadData(state: LandRentDashboardStateModel) {
    return state.data;
  }

  @Action(dashboardData)
  loadData(
    ctx: StateContext<LandRentDashboardStateModel>,
    action: dashboardData
  ) {
    const state = ctx.getState();

    this._landRentService.getDashboardSummary().subscribe((response) => {
      if (response.statusCode === '21000') {
        const data = response.data;
        ctx.patchState({
          data: { data: data, lastUpdate: dashboardData?.type },
        });
      }
    });
  }

  ngxsOnInit(ctx: StateContext<any>): void {}
}
