<div class="tabss-container">
  <ul
    ngbNav
    #nav="ngbNav"
    class="nav-tabs d-flex justify-content-center align-items-center"
  >
    <li ngbNavItem>
      <a ngbNavLink>Why TAUSI</a>
      <ng-template ngbNavContent>
        <div class="p-px-4 p-py-4">
          Lorem ipsum dolor sit amet consectetur adipisicing elit. Sunt facere,
          quam officiis excepturi voluptatem amet magni dolorum distinctio alias
          eos reiciendis ullam mollitia iste atque doloribus voluptates omnis.
          Quasi, suscipit?
        </div>
      </ng-template>
    </li>
    <li ngbNavItem>
      <a ngbNavLink>About</a>
      <ng-template ngbNavContent>
        <div class="p-px-4 p-py-4">
          Lorem ipsum dolor sit amet consectetur adipisicing elit. Sunt facere,
          quam officiis excepturi voluptatem amet magni dolorum distinctio alias
          eos reiciendis ullam mollitia iste atque doloribus voluptates omnis.
          Quasi, suscipit?
        </div>
      </ng-template>
    </li>
  </ul>
  <div [ngbNavOutlet]="nav" class="full-height"></div>
</div>
