import { HttpClient, HttpContext } from '@angular/common/http';
import {
  Agent,
  Bank,
  CollectionArea,
  CollectionPoint,
  CollectionPointResponse,
  Collector,
  CollectorType,
} from 'src/app/agent-management/models/taxpayer';
import {
  Device,
  PoSStatus,
  PoSVersion,
  PosStatusChange,
  PosStatusReview,
  RequestApproval,
  RequestChange,
} from 'src/app/pos-management/models/device.model';

import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ContractReview } from 'src/app/agent-management/models/contract';
import { BYPASS_LOADER } from 'src/app/interceptors/loader.interceptor';
import { environment } from 'src/environments/environment';
import { AdjustmentRequestReview } from '../../agent-management/models/adjustment';
import { PosAgentAssignment } from '../../agent-management/models/taxpayer';

@Injectable({
  providedIn: 'root',
})
export class PosManagementService {
  private base_uri: string = `${environment.apiUrl}${environment.endPoints.pos_service}`;
  constructor(private _httpSvc: HttpClient) {}

  /**
   * registers a new device
   * @param device
   * @returns
   */
  registerDevice(device: Device): Observable<any> {
    return this._httpSvc.post<Device>(
      `${this.base_uri}/api/v1/pos-staging`,
      device
    );
  }

  /**
   * delete a device
   * @param deviceId
   * @returns
   */
  deleteDevice(deviceId: string): Observable<any> {
    return this._httpSvc.delete<Device>(
      `${this.base_uri}/api/v1/pos-staging/id/${deviceId}`
    );
  }

  /**
   * update device details
   * @param deviceId
   * @param payload
   * @returns
   */
  updateDevice(deviceId: string, payload: any): Observable<any> {
    return this._httpSvc.put<Device>(
      `${this.base_uri}/api/v1/pos-staging/id/${deviceId}`,
      payload
    );
  }

  /**
   * fetch all registered devices area code
   * @param areaCode
   * @param status
   * @returns
   */
  fetchAllDevicesByAreaCode(): Observable<any> {
    return this._httpSvc.get<Device>(
      `${this.base_uri}/api/v1/pos-staging/area-code`
    );
  }

  /**
   * agent registration
   * @param payload
   * @returns
   */
  storeAgent(payload: Agent): Observable<any> {
    return this._httpSvc.post<Agent>(`${this.base_uri}/api/v1/agent`, payload);
  }

  createAgent(payload: any): Observable<any> {
    return this._httpSvc.post<any>(
      `${this.base_uri}/api/v1/agent/detail`,
      payload
    );
  }

  /**
   * assigns pos to agent
   * @param payload
   * @returns
   */
  assignPosToAgent(payload: PosAgentAssignment): Observable<any> {
    return this._httpSvc.post<PosAgentAssignment>(
      `${this.base_uri}/api/v1/pos-agent`,
      payload
    );
  }

  /**
   * fetch
   * @param pageSize
   * @param sortBy
   * @returns
   */
  getAllAgents(
    approved: boolean,
    pageSize: number = 100,
    pageNo: number = 0,
    sortBy: string = 'id'
  ): Observable<any> {
    return this._httpSvc.get<Agent[]>(
      `${this.base_uri}/api/v1/agent?approved=${approved}&pageSize=${pageSize}&pageNo=${pageNo}&sortBy=${sortBy}`
    );
  }

  /**
   * fetch
   * @param pageSize
   * @param sortBy
   * @returns
   */
  getAllContract(
    areaCode: number,
    pageSize: number = 100,
    sortBy: string = 'id'
  ): Observable<any> {
    return this._httpSvc.get<Agent[]>(
      `${this.base_uri}/api/v1/agent-contract?administrativeAreaId=${areaCode}&pageSize=${pageSize}&sortBy=${sortBy}`
    );
  }

  /**
   * get agent by NIN
   * @param nin
   * @returns
   */
  getAgentByNIN(agent_nin: any): Observable<any> {
    return this._httpSvc.get<Agent>(
      `${this.base_uri}/api/v1/agent?nin=${agent_nin}`
    );
  }

  /**
   * get agent by NIN
   * @param nin
   * @returns
   */
  getAgentById(agent_id: string): Observable<any> {
    return this._httpSvc.get<Agent>(
      `${this.base_uri}/api/v1/agent?id=${agent_id}`
    );
  }

  /**
   * get all collectors by agent Id
   * @param agent_id
   * @returns
   */
  getCollectorsByAgentId(agent_id: string): Observable<any> {
    return this._httpSvc.get<Agent>(
      `${this.base_uri}/api/v1/collector-collection-area-revenue-source-contract/agent/id/${agent_id}`
    );
  }

  /**
   * remove a collection area revenue source record
   * by supplying a unique id
   * @param id
   * @returns
   */
  unAssignCollectorCollectionAreaRevenueSourceContract(
    payload: any
  ): Observable<any> {
    return this._httpSvc.post<any>(
      `${this.base_uri}/api/v1/un-assign-collector-collection-area-revenue-source-contract`,
      payload
    );
  }

  /**
   * store contract
   * @param payload
   * @returns
   */
  storeContract(payload: any): Observable<any> {
    return this._httpSvc.post<any>(
      `${this.base_uri}/api/v1/agent-contract`,
      payload
    );
  }

  /**
   * update contract, number of POS
   * @param payload
   * @returns
   */
  updateContract(payload: any, contractId: string): Observable<any> {
    return this._httpSvc.put<any>(
      `${this.base_uri}/api/v1/agent-contract?id=${contractId}`,
      payload
    );
  }

  /**
   * store contract review-application store
   * @param payload
   * @returns
   */
  storeContractReview(payload: ContractReview): Observable<any> {
    return this._httpSvc.post<ContractReview>(
      `${this.base_uri}/api/v1/contract-review`,
      payload
    );
  }

  /**
   * upload contract document by contract id
   * @param payload
   * @param contractId
   * @returns
   */
  uploadContractDocument(payload: any, contractId: string): Observable<any> {
    return this._httpSvc.post<any>(
      `${this.base_uri}/api/v1/agent-contract/id/${contractId}`,
      payload
    );
  }

  /**
   * get contract review-application
   * @returns
   */
  getContractReview(contractId: string): Observable<any> {
    return this._httpSvc.get<ContractReview[]>(
      `${this.base_uri}/api/v1/contract-review?contract=${contractId}`
    );
  }

  /**
   * fetch all statuses
   * @returns
   */
  fetchAllStatus(): Observable<any> {
    return this._httpSvc.get<any[]>(`${this.base_uri}/api/v1/setup/status`);
  }

  /**
   * save collection area
   * @param payload
   * @returns
   */
  storeRevenueSource(payload: CollectionArea[]): Observable<any> {
    return this._httpSvc.post<CollectionArea[]>(
      `${this.base_uri}/api/v1/collection-area`,
      payload
    );
  }

  getCollectionAreaRevenueSource(
    financialYear: string,
    param = { pageSize: 10, pageNo: 0 }
  ): Observable<any> {
    return this._httpSvc.get<any[]>(
      `${this.base_uri}/api/v1/collection-area-revenue-source?financialYear=${financialYear}`,
      { params: param }
    );
  }

  storeCollectionAreaRevenueSource(payload: any): Observable<any> {
    return this._httpSvc.post<any>(
      `${this.base_uri}/api/v1/collection-area-revenue-source`,
      payload
    );
  }

  /**
   * fetch collection area by contract id
   * @param contractId
   * @returns
   */
  fetchCollectionAreaByContract(contractId: string): Observable<any> {
    return this._httpSvc.get<any[]>(
      `${this.base_uri}/api/v1/collection-area?contract=${contractId}`
    );
  }

  /**
   * get contracts by id
   * @param contractId
   * @returns
   */
  getContractById(contractId: string): Observable<any> {
    return this._httpSvc.get<any[]>(
      `${this.base_uri}/api/v1/contract/id/${contractId}`
    );
  }

  /**
   * store back
   * @param payload
   * @returns
   */
  storeBank(payload: Bank): Observable<any> {
    return this._httpSvc.post<Bank>(`${this.base_uri}/api/v1/bank`, payload);
  }

  /**
   * get all agent-types
   * @returns
   */
  fetchAgentType(): Observable<any> {
    return this._httpSvc.get<any[]>(`${this.base_uri}/api/v1/setup/agent-type`);
  }

  /**
   *
   * fetch all banks
   * @returns
   */
  fetchAllBanks(): Observable<any> {
    return this._httpSvc.get<Bank[]>(`${this.base_uri}/api/v1/setup/bank`);
  }

  /**
   * add collection area
   * @param payload
   * @returns
   */
  addCollectionPoint(payload: CollectionPoint[]): Observable<any> {
    return this._httpSvc.post<CollectionPoint[]>(
      `${this.base_uri}/api/v1/collection-point`,
      payload
    );
  }

  /**
   * experimental endpoints
   * @param payload
   * @returns
   */
  addCollectionArea(payload: CollectionPoint[]): Observable<any> {
    return this._httpSvc.post<CollectionPoint[]>(
      `${this.base_uri}/api/v1/collection-area`,
      payload
    );
  }

  /**
   * deletes a collection point
   * @param pointId
   * @returns
   */
  deleteCollectionPoint(areaId: string): Observable<any> {
    return this._httpSvc.delete<any[]>(
      `${this.base_uri}/api/v1/collection-area?id=${areaId}`
    );
  }

  /**
   * fetch all collection point
   * @returns
   */
  fetchCollectionPoint(pageSize: number = 200): Observable<any> {
    return this._httpSvc.get<CollectionPointResponse[]>(
      `${this.base_uri}/api/v1/collection-area?pageSize=${pageSize}&sortBy=name`
    );
  }

  /**
   * fetch collection point by ward id
   * @param wardId
   * @returns
   */
  fetchCollectionPointByWardCode(wardCode: number): Observable<any> {
    return this._httpSvc.get<CollectionPointResponse[]>(
      `${this.base_uri}/api/v1/setup/collection-point?wardCode=${wardCode}`
    );
  }

  /**
   * store a collector type
   * @param payload
   * @returns
   */
  storeCollectorType(payload: CollectorType): Observable<any> {
    return this._httpSvc.post<CollectorType>(
      `${this.base_uri}/api/v1/setup/collector-type`,
      payload
    );
  }

  /**
   * store collector type
   * @param payload
   * @returns
   */
  storeCollector(payload: Collector): Observable<any> {
    return this._httpSvc.post<Collector>(
      `${this.base_uri}/api/v1/collector`,
      payload
    );
  }

  getCollectors(payload: any): Observable<any> {
    return this._httpSvc.get(`${this.base_uri}/api/v1/collector`, {
      params: payload,
    });
  }

  /**
   * search collector by agent and NIN
   * @param agentId
   * @param nin
   * @returns
   */
  searchCollectorByAgentAndNIN(nin: string): Observable<any> {
    return this._httpSvc.get(
      `${this.base_uri}/api/v1/collector/by-agent-id-and-nin?nin=${nin}`
    );
  }

  /**
   * fetch all collector types
   * @returns
   */
  fetchCollectorType(): Observable<any> {
    return this._httpSvc.get<Collector[]>(
      `${this.base_uri}/api/v1/setup/collector-type`
    );
  }

  /**
   * update a collector type
   * @param payload
   * @returns
   */
  updateCollectorType(payload: Collector): Observable<any> {
    return this._httpSvc.put<Collector>(
      `${this.base_uri}/api/v1/setup/collector-type`,
      payload
    );
  }

  /**
   * fetches and returns a list of collector types
   * @returns
   */
  getAllCollectorType(): Observable<any> {
    return this._httpSvc.get<Collector[]>(
      `${this.base_uri}/api/v1/setup/collector-type`
    );
  }

  /**
   * assign po to collector
   * @param payload
   * @returns
   */
  assignPosCollector(payload: any): Observable<any> {
    return this._httpSvc.post<any[]>(
      `${this.base_uri}/api/v1/pos-agent-collector`,
      payload
    );
  }

  /**
   * return list of subgfscode and their rates
   * @param tausiGfsCode
   * @returns
   */
  getSubgfscodeGeneric(
    tausiGfsCode: string,
    financial_year: string
  ): Observable<any> {
    return this._httpSvc.get(
      `${this.base_uri}/api/v1/other-source-rate/tausi-gfs-code/${tausiGfsCode}`,
      { params: { tausiGfsCode: tausiGfsCode, financialYear: financial_year } }
    );
  }

  /**
   * store other source rate approval
   * @param payload
   * @param sourceId
   * @returns
   */
  storeOtherSourceRateApproval(
    payload: any,
    sourceId: string
  ): Observable<any> {
    return this._httpSvc.post<any>(
      `${this.base_uri}/api/v1/other-source-rate/approval/id/${sourceId}`,
      payload
    );
  }

  /**
   * get other source rates by status
   * @param isApproved
   * @returns
   */
  getOtherSourceRateApproval(
    isApproved: boolean,
    financialYear: string
  ): Observable<any> {
    return this._httpSvc.get<any[]>(
      `${this.base_uri}/api/v1/other-source-rate/is-approved/${isApproved}?financialYear=${financialYear}`
    );
  }

  fetchGeometryTypes(): any[] {
    return [
      {
        name: 'Point',
        value: 'Point',
      },
      {
        name: 'Line String',
        value: 'LineString',
      },
      {
        name: 'Polygon',
        value: 'Polygon',
      },
      {
        name: 'None',
        value: 'None',
      },
    ];
  }
  /**
   * fetch all pos status registered
   * @returns
   */
  fetchPoSStatus(): Observable<any> {
    return this._httpSvc.get<PoSStatus>(`${this.base_uri}/api/v1/pos-status`);
  }

  /**
   * register pos status
   * @param payload
   * @returns
   */
  addPoSStatus(payload: PoSStatus): Observable<any> {
    return this._httpSvc.post<PoSStatus>(
      `${this.base_uri}/api/v1/pos-status`,
      payload
    );
  }

  /**
   * update registered pos status
   * @param payload
   * @returns
   */
  updatePoSStatus(payload: PoSStatus): Observable<any> {
    const id = payload.id;
    return this._httpSvc.put<PoSStatus>(
      `${this.base_uri}/api/v1/pos-status/id/${id}`,
      payload
    );
  }

  /**
   * fetch all pos versions registered
   * @returns
   */
  fetchPoSVersion(): Observable<any> {
    return this._httpSvc.get<PoSVersion>(
      `${this.base_uri}/api/v1/pos-app-versions`
    );
  }

  /**
   * register pos version
   * @param payload
   * @returns
   */
  addPoSVersion(payload: any): Observable<any> {
    return this._httpSvc.post<any>(
      `${this.base_uri}/api/v1/pos-app-version`,
      payload
    );
  }

  /**
   * update registered pos version
   * @param payload
   * @returns
   */
  updatePoSVersion(payload: PoSVersion): Observable<any> {
    const id = payload.id;
    return this._httpSvc.put<PoSVersion>(
      `${this.base_uri}/api/v1/pos-app-version/id/${id}`,
      payload
    );
  }

  /**
   * fetch all registered POS
   * @returns
   */
  fetchPos(): Observable<any> {
    // this api request must be updated to comply with the details needed to be displayed
    return this._httpSvc.get(`${this.base_uri}/api/v1/pos`);
  }

  /**
   * add pos status change request
   * @param payload
   * @returns
   */
  addPoSChangeStatus(payload: PosStatusChange): Observable<any> {
    return this._httpSvc.post<PosStatusChange>(
      `${this.base_uri}/api/v1/change-status-requests`,
      payload
    );
  }

  /**
   * pos re-registration request
   * @param payload
   * @returns
   */
  posRegistration(payload: any): Observable<any> {
    return this._httpSvc.post<any>(
      `${this.base_uri}/api/v1/pos-registration-request`,
      payload
    );
  }

  /**
   * fetch all PoS status change requests submitted for logged in user admin area
   * @returns
   */

  fetchChangeStatusRequest(): Observable<any> {
    let status = false;
    //must return all registered pos for a specific council
    return this._httpSvc.get(
      `${this.base_uri}/api/v1/change-status-requests/status/${status}`
    );
  }

  /**
   * add pos change status decision
   * @param payload
   * @returns
   */
  addPoSChangeStatusReview(payload: PosStatusReview): Observable<any> {
    return this._httpSvc.post<PosStatusReview>(
      `${this.base_uri}/api/v1/change-status-review`,
      payload
    );
  }

  /**
   * generate generic bill
   * @param payload
   * @returns
   */
  generateBill(payload: any): Observable<any> {
    return this._httpSvc.post<PosStatusReview>(
      `${this.base_uri}/api/v1/charge/generic-bill`,
      payload
    );
  }

  fetchPosDashboardData(): Observable<any> {
    return this._httpSvc.get<any>(`${this.base_uri}/api/v1/pos/dashboard`);
  }

  /**
   * fetch all agents pos by contract id
   * @param contractId
   * @returns
   */
  getAllPoSByAgentContractId(contractId: string): Observable<any> {
    return this._httpSvc.get<any>(
      `${this.base_uri}/api/v1/pos-agent/contract/id/${contractId}`
    );
  }

  /**
   * retrieve all  pos not assigned to any any agent
   * @param isAssigned
   * @returns
   */
  getAllUnassignedPos(isAssigned: boolean = false): Observable<any> {
    return this._httpSvc.get<any>(
      `${this.base_uri}/api/v1/pos/is-assigned-to-agent/${isAssigned}`
    );
  }

  /**
   * retrieve all pos assigned to an agent
   * @param isAssigned
   * @returns
   */
  getPOSAssignedToAgent(isAssigned: boolean = true): Observable<any> {
    return this._httpSvc.get<any>(
      `${this.base_uri}/api/v1/pos/is-assigned-to-agent/${isAssigned}`
    );
  }

  /**
   *
   * @param contractId
   * @returns
   */
  getPOSByContractId(contractId: string): Observable<any> {
    return this._httpSvc.get<any>(
      `${this.base_uri}/api/v1/pos/contract/id/${contractId}`
    );
  }

  getAllAssignedPOSToAgent(status: boolean, params: any): Observable<any> {
    return this._httpSvc.get<any>(
      `${this.base_uri}/api/v1/pos-agent/is-assigned/${status}?pageNo=${params.pageNo}&pageSize=${params.pageSize}&sortBy=${params.sortBy}`
    );
  }

  /**
   * get a all pos assigned to a collector
   * @param contractId
   * @param isAssigned
   * @returns
   */
  getPosByContractIdAndCollectorAssignedStatus(
    contractId: string,
    isAssigned: boolean
  ): Observable<any> {
    return this._httpSvc.get<any>(
      `${this.base_uri}/api/v1/pos-agent/contract/id/${contractId}/is-assigned-to-collector/${isAssigned}`
    );
  }

  /**
   * get pos configuration request change
   * @param status
   * @returns
   */
  getPosConfigurationRequestStatusChange(status: boolean): Observable<any> {
    return this._httpSvc.get(
      `${this.base_uri}/api/v1/pos-configuration-request-change/status/${status}`
    );
  }

  /**
   * store pos configuration request change
   * @param payload
   * @returns
   */
  storePosConfigurationRequestChange(payload: RequestChange): Observable<any> {
    return this._httpSvc.post(
      `${this.base_uri}/api/v1/pos-configuration-request-change`,
      payload
    );
  }

  /**
   * store pos configuration request change
   * approval
   * @param payload
   * @returns
   */
  storePosConfigurationRequestApproval(
    payload: RequestApproval
  ): Observable<any> {
    return this._httpSvc.post(
      `${this.base_uri}/api/v1/pos-configuration-request-change-approval`,
      payload
    );
  }

  /**
   * return re-registration requests
   * @param status
   * @returns
   */
  getRegistrationRequests(status: boolean = false): Observable<any> {
    return this._httpSvc.get(
      `${this.base_uri}/api/v1/pos-registration-request/review-status/${status}`
    );
  }

  approvePoSRegistration(payload: any): Observable<any> {
    return this._httpSvc.post(
      `${this.base_uri}/api/v1/pos-registration-approval`,
      payload
    );
  }

  /**
   * return list of pos requested for re-registration
   */
  getPoSReregistration(): Observable<any> {
    return this._httpSvc.get(
      `${this.base_uri}/api/v1/pos/registration/attempts`
    );
  }

  /**
   * compile transactions
   * @param collectorId
   * @param financialYear
   * @returns
   */
  compileTransactions(
    collectorId: string,
    financialYear: string
  ): Observable<any> {
    return this._httpSvc.get<any>(
      `${this.base_uri}/api/v1/transaction/compile?collectorId=${collectorId}&financialYear=${financialYear}`
    );
  }

  /**
   * return un billed transaction
   * @param param
   * @returns
   */
  getUnBilledPosTransaction(param: any): Observable<any> {
    return this._httpSvc.get(`${this.base_uri}/api/v1/transaction/un-billed`, {
      params: param,
    });
  }
  /**
   * return list of un billed batches by collector id
   * @param param
   * @returns
   */

  getUnBilledBatches(param: any): Observable<any> {
    return this._httpSvc.get(
      `${this.base_uri}/api/v1/transaction/un-billed-batches`,
      {
        params: param,
      }
    );
  }

  /**
   * return transactions compiled/ with batch
   * @param batch
   * @returns
   */
  getTransactionsByBatch(batch: number): Observable<any> {
    return this._httpSvc.get<any>(
      `${this.base_uri}/api/v1/transaction/batch/${batch}`
    );
  }

  /**
   * generate bill of the collector including bill compilation for uncompiled transctions
   * @param payload
   * @returns
   */
  generateCharge(payload: any): Observable<any> {
    return this._httpSvc.post(`${this.base_uri}/api/v1/charge`, payload);
  }

  /**
   * return list of transactions by collector
   * @param payload
   * @returns
   */
  getBatchTransactions(payload: any): Observable<any> {
    let param = `collectorId=${payload.collectorId}&pageNo=${payload.pageNo}&pageSize=${payload.pageSize}`;
    if (payload.batchNumber != null) {
      param += `&batchNumber=${payload.batchNumber}`;
    }
    return this._httpSvc.get<any>(
      `${this.base_uri}/api/v1/transactions-by-collector-and-batch?${param}`
    );
  }

  /**
   * save transaction adjustmenr request
   * @param payload
   * @returns
   */
  storeTransactionAdjustment(payload: any): Observable<any> {
    return this._httpSvc.post<any>(
      `${this.base_uri}/api/v1/adjustment-request`,
      payload
    );
  }

  /**
   * return list of adjustment requests
   * @param approvalStatus
   * @returns
   */
  getTransactionAdjustmentRequest(
    approvalStatus: boolean,
    param: any
  ): Observable<any> {
    return this._httpSvc.get<any[]>(
      `${this.base_uri}/api/v1/adjustment-request/reviewed/${approvalStatus}`,
      { params: param }
    );
  }

  /**
   * save adjustment approval
   * @param payload
   * @returns
   */
  storeTransactionAdjustmentReview(payload: any): Observable<any> {
    return this._httpSvc.post<AdjustmentRequestReview>(
      `${this.base_uri}/api/v1/adjustment-request-review`,
      payload
    );
  }

  /**
   * return list of failed reconciliation attempts
   * @returns
   */
  getFailedReconciliation(param: any): Observable<any> {
    return this._httpSvc.get<any[]>(
      `${this.base_uri}/api/v1/pos/failed-reconciliations?pageNo=${param.pageNo}&pageSize=${param.pageSize}`
    );
  }

  /**
   * return list of failed reconciliation  transactions
   * @param param
   * @returns
   */

  getUnreconciledTransactions(param: any): Observable<any> {
    return this._httpSvc.get(
      `${this.base_uri}/api/v1/transactions-by-pos-and-recon-status?posId=${param.posId}&pageNo=${param.pageNo}&pageSize=${param.pageSize}`
    );
  }

  /**
   * reconcile pos transactions
   * @param payload
   * @returns
   */
  postPosReconciliation(payload: any): Observable<any> {
    return this._httpSvc.post<AdjustmentRequestReview>(
      `${this.base_uri}/api/v1/transactions-by-pos-and-recon-status`,
      payload
    );
  }

  /**
   * pass name of the bucket and file name as saved in the db, api will return base64 attachment
   * @param payload (name, file)
   * @returns
   */
  getAttachment(payload: any): Observable<any> {
    let name = payload.name;
    let path = payload.file;
    return this._httpSvc.get(
      `${this.base_uri}/api/v1/attachment/name/${name}/path/${path}`,
      payload
    );
  }

  readAttachment(bucketName: string, path: string): Observable<any> {
    return this._httpSvc.get(
      `${this.base_uri}/api/v1/attachment/name/${bucketName}/path/${path}`
    );
  }

  /**
   * import tausi revenue sources from council
   * @param payload
   * @returns
   */
  importTausiRevenueSourcesFromCouncil(payload: any): Observable<any> {
    return this._httpSvc.post<any[]>(
      `${this.base_uri}/api/v1/revenue-sources`,
      payload
    );
  }

  /**
   * assigns revenue sources to agent
   * @param payload
   * @returns
   */
  saveCollectionAreaRevenueSourceContract(payload: any): Observable<any> {
    return this._httpSvc.post<any>(
      `${this.base_uri}/api/v1/collection-area-revenue-source-contract`,
      payload
    );
  }

  /**
   * save collector collection area revenue source contract
   * @param payload
   * @returns
   */
  saveCollectorCollectionAreaRevenueSourceContract(
    payload: any
  ): Observable<any> {
    return this._httpSvc.post<any>(
      `${this.base_uri}/api/v1/collector-collection-area-revenue-source-contract`,
      payload
    );
  }

  /**
   * get collection area revenue sources by congtract
   * @param contractId
   * @returns
   */
  getCollectionAreaRevenueSourceContract(
    contractId: string,
    financialYear: string,
    pageable: any
  ): Observable<any> {
    return this._httpSvc.get<any>(
      `${this.base_uri}/api/v1/collection-area-revenue-source-contract/contract/id/${contractId}?financialYear=${financialYear}&pageNo=${pageable.pageNo}&pageSize=${pageable.pageSize}&sortBy=${pageable.sortBy}`
    );
  }

  /**
   * get collector by agent id
   * @param agentId
   * @returns
   */
  getCollectorsByAgent(
    pageSize: number,
    pageNumber: number,
    sortBy: string
  ): Observable<any> {
    return this._httpSvc.get(
      `${this.base_uri}/api/v1/collector?pageSize=${pageSize}&pageNo=${pageNumber}&sortBy=${sortBy}`
    );
  }

  /**
   * get collection area revenue sources contract agent
   * @param agentId
   * @returns
   */
  getCollectionAreaRevenueSourceContractAgent(
    agentId: string
  ): Observable<any> {
    return this._httpSvc.get<any>(
      `${this.base_uri}/api/v1/collection-area-revenue-source-contract/agent/id/${agentId}`
    );
  }

  /** edit number of POS assigned to agent*/
  updateNumberOfPos(payload: any, id: string) {
    return this._httpSvc.post<any>(
      `${this.base_uri}/api/v1/agent-contract/${id}/pos`,
      payload
    );
  }

  /** get unassigned revenue sources to a contract */
  getUnassignedSources(
    contractId: string,
    revenueSource: string,
    payload: any
  ): Observable<any> {
    return this._httpSvc.get<any>(
      `${this.base_uri}/api/v1/un-assigned-collection-area-revenue-source-contract/contract/id/${contractId}`,
      { params: payload }
    );
  }

  /** unassign revenue source from contract */
  unassignSourceContract(payload: any) {
    return this._httpSvc.post<any>(
      `${this.base_uri}/api/v1/unassign-collection-area-revenue-source-contract`,
      payload
    );
  }

  /**
   * get float transactions
   * @param payload
   * @returns
   */
  getFloatTransaction(payload: any): Observable<any> {
    return this._httpSvc.get(
      `${this.base_uri}/api/v1/pos-float-transactions?posCode=${payload?.posCode}&pageSize=${payload?.pageSize}&pageNo=${payload?.pageNo}&sortBy=${payload?.sortBy}`,
      payload
    );
  }
  /**
   * get assigned collection area to a collector
   * by collector id
   * @param collector
   * @returns
   */
  assignedCollectorRevenueSourceCollectionArea(
    collector: string,
    financialYear: string,
    pageable: any
  ): Observable<any> {
    return this._httpSvc.get<any[]>(
      `${this.base_uri}/api/v1/collection-area-revenue-source-contract/collector/id/${collector}?financialYear=${financialYear}&pageNo=${pageable.pageNo}&pageSize=${pageable.pageSize}&sortBy=${pageable.sortBy}`
    );
  }

  /**
   * get agent revenue source collection area
   * by agent and unassigned to collector.
   * @param agent
   * @param collector
   * @returns
   */
  agentRevenueSourceCollectionUnassignedToCollector(
    agent: string,
    collector: string,
    financialYear: string,
    pageable: any
  ): Observable<any> {
    return this._httpSvc.get<any[]>(
      `${this.base_uri}/api/v1/collection-area-revenue-source-contract/agent/id/${agent}/collector/${collector}?financialYear=${financialYear}`,
      { params: pageable }
    );
  }

  /**
   * pos dashboard API
   */
  fetchCashOnHand(): Observable<any> {
    return this._httpSvc.get<any>(
      `${this.base_uri}/api/v1/dashboard/total-cash-on-hand`,
      { context: new HttpContext().set(BYPASS_LOADER, true) }
    );
  }

  fetchPOSLastSeen(): Observable<any> {
    return this._httpSvc.get<any>(
      `${this.base_uri}/api/v1/dashboard/top-ten-last-seen-pos`,
      { context: new HttpContext().set(BYPASS_LOADER, true) }
    );
  }

  fetchPOSLastSeenFull(pageSize: number, pageNumber: number): Observable<any> {
    return this._httpSvc.get<any>(
      `${this.base_uri}/api/v1/dashboard/top-ten-last-seen-pos?pageSize=${pageSize}&pageNumber=${pageNumber}`
    );
  }

  fetchCollectorCashOnHand(payload: any): Observable<any> {
    return this._httpSvc.get<any>(
      `${this.base_uri}/api/v1/dashboard/top-collectors-with-cash-on-hand`,
      { params: payload }
    );
  }

  fetchCollectorCashOnHandFull(
    financialYear: string,
    pageSize: number,
    pageNumber: number
  ): Observable<any> {
    return this._httpSvc.get<any>(
      `${this.base_uri}/api/v1/dashboard/top-collectors-with-cash-on-hand?financialYear=${financialYear}&pageSize=${pageSize}&pageNumber=${pageNumber}`
    );
  }

  fetchPoSRegistrationSummary(): Observable<any> {
    return this._httpSvc.get<any>(
      `${this.base_uri}/api/v1/dashboard/pos-registration-summary`,
      { context: new HttpContext().set(BYPASS_LOADER, true) }
    );
  }

  fetchPoSOnlineStatus(): Observable<any> {
    return this._httpSvc.get<any>(
      `${this.base_uri}/api/v1/dashboard/pos-online-status-summary`,
      { context: new HttpContext().set(BYPASS_LOADER, true) }
    );
  }

  fetchPoSActiveSummary(): Observable<any> {
    return this._httpSvc.get<any>(
      `${this.base_uri}/api/v1/dashboard/pos-active-status-summary`,
      { context: new HttpContext().set(BYPASS_LOADER, true) }
    );
  }
  /** PoS national summary  */
  fetchPoSTotalCollection(financial_year: any): Observable<any> {
    return this._httpSvc.get<any>(
      `${this.base_uri}/api/v1/dashboard/national-total-pos-collection?financialYear=${financial_year}`,
      { context: new HttpContext().set(BYPASS_LOADER, true) }
    );
  }

  fetchTotalCashOnHand(financial_year: any): Observable<any> {
    return this._httpSvc.get<any>(
      `${this.base_uri}/api/v1/dashboard/national-total-cash-on-hand?financialYear=${financial_year}`,
      { context: new HttpContext().set(BYPASS_LOADER, true) }
    );
  }

  fetchPoSRegistration(): Observable<any> {
    return this._httpSvc.get<any>(
      `${this.base_uri}/api/v1/dashboard/national-pos-registration-summary`
    );
  }

  fetchPoSOnlineStatusSummary(): Observable<any> {
    return this._httpSvc.get<any>(
      `${this.base_uri}/api/v1/dashboard/national-pos-online-status-summary`
    );
  }

  fetchPoSActiveStatusSummary(): Observable<any> {
    return this._httpSvc.get<any>(
      `${this.base_uri}/api/v1/dashboard/national-pos-active-status-summary`
    );
  }

  /**
    POS Regional Dashboard Summary
   */

  fetchPoSRegionalTotalCollection(financial_year: any): Observable<any> {
    return this._httpSvc.get<any>(
      `${this.base_uri}/api/v1/dashboard/regional-total-pos-collection?financialYear=${financial_year}`,
      { context: new HttpContext().set(BYPASS_LOADER, true) }
    );
  }

  fetchRegionalTotalCashOnHand(financial_year: any): Observable<any> {
    return this._httpSvc.get<any>(
      `${this.base_uri}/api/v1/dashboard/regional-total-cash-on-hand?financialYear=${financial_year}`,
      { context: new HttpContext().set(BYPASS_LOADER, true) }
    );
  }

  fetchRegionalPoSRegistration(): Observable<any> {
    return this._httpSvc.get<any>(
      `${this.base_uri}/api/v1/dashboard/regional-pos-registration-summary`
    );
  }

  fetchRegionalPoSOnlineStatusSummary(): Observable<any> {
    return this._httpSvc.get<any>(
      `${this.base_uri}/api/v1/dashboard/regional-pos-online-status-summary`
    );
  }

  fetchRegionalPoSActiveStatusSummary(): Observable<any> {
    return this._httpSvc.get<any>(
      `${this.base_uri}/api/v1/dashboard/regional-pos-active-status-summary`
    );
  }

  fetchRegionalCouncilCashCollection(params: any): Observable<any> {
    return this._httpSvc.get<any>(
      `${this.base_uri}/api/v1/dashboard/top-regional-councils-with-pos-collection`,
      { params: params }
    );
  }
  /** -----end regional dashboard ----- */

  fetchCouncilCashCollection(params: any): Observable<any> {
    return this._httpSvc.get<any>(
      `${this.base_uri}/api/v1/dashboard/top-councils-with-pos-collection`,
      { params: params }
    );
  }

  //fetch pos report
  fetchPoSReport(payload: any): Observable<any> {
    return this._httpSvc.post<any>(
      `${this.base_uri}/api/v1/reports/collections`,
      payload
    );
  }

  fetchNationalAppVersionReport(payload: any): Observable<any> {
    return this._httpSvc.post<any>(
      `${this.base_uri}/api/v1/reports/national-app-versions`,
      payload
    );
  }

  fetchCouncilAppVersionReport(payload: any): Observable<any> {
    return this._httpSvc.post<any>(
      `${this.base_uri}/api/v1/reports/council-app-versions`,
      payload
    );
  }

  fetchTotalPoSCollection(payload: any): Observable<any> {
    return this._httpSvc.get<any>(
      `${this.base_uri}/api/v1/dashboard/council-total-pos-collection`,
      { params: payload }
    );
  }

  /**
   * block collector
   * @param payload
   * @returns
   */
  blockCollector(payload: any): Observable<any> {
    return this._httpSvc.post<any>(
      `${this.base_uri}/api/v1/collector-blocking-requests`,
      payload
    );
  }

  /**
   * view collector contracts
   * @param contractId
   * @returns
   */
  viewCollectorContracts(contractId: string): Observable<any> {
    return this._httpSvc.get<any>(
      `${this.base_uri}/api/v1/collector-contracts?id=${contractId}`
    );
  }

  /**
   * add a contract to a collector
   * @param payload
   * @returns
   */
  addContractToCollector(payload: any): Observable<any> {
    return this._httpSvc.post<any>(
      `${this.base_uri}/api/v1/collector-contracts`,
      payload
    );
  }

  /**
   * return list of adjustment requests
   * @param approvalStatus
   * @returns
   */
  getApprovedAdjustments(approvalStatus: boolean, param: any): Observable<any> {
    return this._httpSvc.get<any[]>(
      `${this.base_uri}/api/v1/adjustment-request/approved/${approvalStatus}`,
      { params: param }
    );
  }

  /**
   *
   * @param payload
   * @param adminAreaCode
   * @returns
   */
  fetchCollectionReportSummary(
    payload: any,
    adminAreaCode?: any
  ): Observable<any> {
    if (adminAreaCode) {
      return this._httpSvc.post(
        `${this.base_uri}/api/v1/reports/national-level-collections?adminAreaCode=${adminAreaCode}`,
        payload
      );
    } else {
      return this._httpSvc.post(
        `${this.base_uri}/api/v1/reports/national-level-collections`,
        payload
      );
    }
  }

  /**
   * return list of float requested fired by PoS devices
   * @param payload
   * @returns
   */
  posFloatChangeRequest(payload: any): Observable<any> {
    return this._httpSvc.get(
      `${this.base_uri}/api/v1/pos-float-recharge-requests?reviewed=false`,
      { params: payload }
    );
  }

  getApplicationIds(): Observable<any> {
    return this._httpSvc.get(`${this.base_uri}/api/v1//pos-app-ids`);
  }

  /** general dashboard */
  getCashOnHandByLevel(level: number, payload: any): Observable<any> {
    if (level == 4) {
      return this._httpSvc.get<any>(
        `${this.base_uri}/api/v1/dashboard/council-total-pos-collection`,
        { params: payload }
      );
    }
    if (level == 1) {
      return this._httpSvc.get<any>(
        `${this.base_uri}/api/v1/dashboard/national-total-pos-collection`,
        { context: new HttpContext().set(BYPASS_LOADER, true), params: payload }
      );
    }
    return this._httpSvc.get<any>(
      `${this.base_uri}/api/v1/dashboard/regional-total-pos-collection`,
      { context: new HttpContext().set(BYPASS_LOADER, true), params: payload }
    );
  }

  getTopCashOnHandByLevel(level: number, payload: any): Observable<any> {
    if (level == 4) {
      return this._httpSvc.get<any>(
        `${this.base_uri}/api/v1/dashboard/top-collectors-with-cash-on-hand`,
        { params: payload }
      );
    }
    if (level == 1) {
      return this._httpSvc.get<any>(
        `${this.base_uri}/api/v1/dashboard/top-councils-with-pos-collection`,
        { params: payload }
      );
    }

    return this._httpSvc.get<any>(
      `${this.base_uri}/api/v1/dashboard/top-regional-councils-with-pos-collection`,
      { params: payload }
    );
  }

  getTotalPos(level: number): Observable<any> {
    if (level == 1) {
      return this._httpSvc.get<any>(
        `${this.base_uri}/api/v1/dashboard/national-pos-registration-summary`
      );
    }
    if (level == 4) {
      return this._httpSvc.get<any>(
        `${this.base_uri}/api/v1/dashboard/pos-registration-summary`,
        { context: new HttpContext().set(BYPASS_LOADER, true) }
      );
    }
    return this._httpSvc.get<any>(
      `${this.base_uri}/api/v1/dashboard/regional-pos-registration-summary`
    );
  }

  getPoSStatusUse(level: number): Observable<any> {
    if (level == 1) {
      return this._httpSvc.get<any>(
        `${this.base_uri}/api/v1/dashboard/national-pos-active-status-summary`
      );
    }
    if (level == 4) {
      return this._httpSvc.get<any>(
        `${this.base_uri}/api/v1/dashboard/pos-active-status-summary`,
        { context: new HttpContext().set(BYPASS_LOADER, true) }
      );
    }

    return this._httpSvc.get<any>(
      `${this.base_uri}/api/v1/dashboard/regional-pos-active-status-summary`
    );
  }
}
