import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MenuItem } from 'primeng/api';

/**
 * @title Data table with sorting, pagination, and filtering.
 */

@Component({
  selector: 'tausi-table-data',
  templateUrl: './table-data.component.html',
  styleUrls: ['./table-data.component.scss'],
})
export class TableDataComponent implements OnInit {
  @Input() data: any[] = [];
  @Input() cols: any[] = [];
  @Input() category: string = '';
  @Input() header: string = 'Default Header';
  @Input() height: string = '470px';
  @Input() referenceTime: string = '';
  @Input() paginator: boolean = false;

  public items!: MenuItem[];
  public nColumns: any;

  constructor(private _routerSvc: Router) {}

  ngOnInit() {
    this.items = [
      {
        label: 'Chart Options',
        items: [
          {
            label: 'Download',
            icon: 'pi pi-download',
          },
          {
            label: 'Share',
            icon: 'pi pi-share-alt',
          },
        ],
      },
    ];
    this.nColumns = this.cols.length + 1;
  }

  viewAllReport(): void {
    this._routerSvc.navigate([
      `/main/pos-management/list-pos-report`,
      this.category,
    ]);
  }
}
