<div class="p-px-4 p-pt-3">
  <div class="row">
    <div class="col-md-4 col-12">
      <div class="nin-otp">
        <img src="assets/icons/nin-otp.png" />
      </div>
    </div>

    <div class="col-md-8 col-12">
      <div class="">
        <div class="col-12">
          <!-- <h6 class="text-muted" translate>pri.login.reset_info</h6> -->
          <h6 class="text-muted">{{ message}}</h6>


        </div>
        <div class="nin-otp-form p-mt-5">
          <form [formGroup]="resetForm" autocomplete="off" novalidate>
            <div class="p-fluid p-formgrid p-grid">
              <div class="p-field p-col-12">
                <span class="p-float-label">
                  <p-password
                    [feedback]="true"
                    [toggleMask]="true"
                    inputId="password11"
                    formControlName="password"
                    (ngModelChange)="passwordModelChanged($event)"
                  ></p-password>

                  <label for="password11">Password</label>
                </span>
              </div>
            </div>
            <div class="p-fluid p-formgrid p-grid">
              <div class="p-field p-col-12">
                <span class="p-float-label">
                  <p-password
                    [feedback]="true"
                    [toggleMask]="true"
                    inputId="confirmPassword11"
                    formControlName="confirmPassword"

                  ></p-password>
                  <label for="confirmPassword11">Confirm Password</label>
                </span>
              </div>
            </div>

            <div class="p-fluid p-formgrid p-grid">
              <div class="p-field p-col-12">

                <p *ngFor="let row of passwordPolicyArray; let i = index;" style="margin-top: -10px;">

                  <i class="pi pi-check-circle" style=" color: green;"  *ngIf="row?.status == true"></i>
                  <i class="pi pi-minus-circle" style=" color: red;"  *ngIf="row?.status == false"></i>



                  {{ row?.name}}

                </p>
              </div>
            </div>

            <!-- {{ passwordPolicyArray | json}} -->
          </form>
        </div>
      </div>
    </div>
  </div>
  <div class="p-fluid p-grid p-jc-between p-ai-center p-px-2 p-py-2 p-mt-4">
    <div class="">
      <button
        (click)="close()"
        class="p-button-text"
        label="{{ 'pri.otp.btn_close' | translate }}"
        iconPos="left"
        pButton
      ></button>
    </div>

    <div class="">
      <button
        (click)="changePassword(resetForm.value)"
        [disabled]="resetForm.invalid && validatePasswordPolictIfOkay()"
        class="p-button"
        label="Change password"
        iconPos="left"
        pButton
      ></button>
    </div>
  </div>
</div>
