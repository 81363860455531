<div class="skeleton">
  <div class="icon">
    <p-skeleton shape="circle" size="6rem" styleClass="mr-5"></p-skeleton>
  </div>
  <div style="display: flex; flex-direction: column; align-items: flex-end">
    <p-skeleton width="6rem" height="1rem" styleClass="p-mb-3"></p-skeleton>
    <p-skeleton width="13rem" height="1rem" styleClass="p-mb-2"></p-skeleton>
    <p-skeleton width="5rem" height="1rem" styleClass=""></p-skeleton>
  </div>
</div>
