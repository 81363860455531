<div class="details p-fluid p-grid p-formgrid p-mb-2">
  <div class="p-field p-col-2 p-d-flex p-flex-column p-jc-start p-ai-center">
    <div class="logo-container p-pt-3 text-center">
      <img src="/assets/imgs/placeholder.jpg" alt="Council logo" class="logo" />
    </div>
  </div>
  <div class="p-field p-col-10">
    <div class="p-px-4">
      <div class="heading p-mb-5">
        <h4>Taxpayer Details</h4>
        <p>
          The information below pertains to
          <strong>{{ tin.taxpayerName }}</strong> as a legitimate Taxpayer with
          taxpayer identification number <strong>{{ tinNumber }}</strong
          >. Please the information attached to this TIN is strictly for
          internal use only and it is trictly prohibited to use it otherwise.
        </p>
      </div>
      <table class="table">
        <tr>
          <th>Number of Employees</th>
          <td>{{ tin.tin }}</td>
        </tr>
        <tr>
          <th>Number of Employees</th>
          <td>{{ tin.numberOfEmployees }}</td>
        </tr>
        <tr>
          <th>Date Of Registration</th>
          <td>{{ tin.dateOfRegistration }}</td>
        </tr>
        <tr>
          <th>Taxpayer ID</th>
          <td>{{ tin.traTaxpayerId }}</td>
        </tr>
        <tr>
          <th>Taxpayer Name</th>
          <td>{{ tin.taxpayerName }}</td>
        </tr>
        <tr>
          <th>Region</th>
          <td>{{ tin.residentregion }}</td>
        </tr>
        <tr>
          <th>District</th>
          <td>{{ tin.residentdistrict }}</td>
        </tr>
        <tr>
          <th>Postal Address</th>
          <td>{{ tin.residentpostaladdress }}</td>
        </tr>
        <tr>
          <th>Postal City</th>
          <td>{{ tin.postalCity }}</td>
        </tr>
        <tr>
          <th>Street</th>
          <td>{{ tin.residentstreet }}</td>
        </tr>
        <tr>
          <th>Email Address</th>
          <td>{{ tin.email }}</td>
        </tr>
        <tr>
          <th>Telephone Number</th>
          <td>{{ tin.telephone }}</td>
        </tr>
        <tr>
          <th>Fax Number</th>
          <td>{{ tin.telephone }}</td>
        </tr>
      </table>
    </div>
  </div>
</div>
<div class="p-d-flex p-flex-row p-jc-between p-align-center">
  <button
    (click)="close()"
    pButton
    label="Cancel"
    icon="pi pi-times"
    iconPos="right"
    class="p-button-text"
  ></button>

  <button
    (click)="continue()"
    pButton
    label="Continue"
    icon="pi pi-arrow-right"
    iconPos="right"
    class="p-button"
  ></button>
</div>
