<div class="map-section" fxLayout="row" fxLayoutAlign="space-between center">
  <div fxFlex="20%" class="left-panel">
    <div>
      <h4 class="mb-3">Map Controls</h4>
      <p class="text-muted">
        Lorem ipsum dolor sit amet consectetur adipisicing elit. Alias odio
        impedit velit ratione minima eos natus similique sit quis eveniet
        inventore tenetur, perspiciatis, nisi rem quibusdam? Totam quod delectus
        dicta.
      </p>
    </div>
    <div fxLayout="row" fxLayoutAlign="start center">
      <button
        pButton
        label="Close"
        (click)="cancel()"
        class="p-button-outlined"
      ></button>
    </div>
  </div>
  <div fxFlex="80%" class="" id="ol-map" class="p-shadow-20"></div>
</div>
