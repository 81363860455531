import { Component, OnInit } from '@angular/core';
import { MenuItem } from 'primeng/api';

@Component({
  selector: 'tausi-doughnut-chart',
  templateUrl: './doughnut-chart.component.html',
  styleUrls: ['./doughnut-chart.component.scss'],
})
export class DoughnutChartComponent implements OnInit {
  public data: any;
  public options: any;
  public items!: MenuItem[];
  constructor() {}

  ngOnInit(): void {
    this.items = [
      {
        label: 'Chart Options',
        items: [
          {
            label: 'Download',
            icon: 'pi pi-download',
          },
          {
            label: 'Share',
            icon: 'pi pi-share-alt',
          },
        ],
      },
    ];
    this.data = {
      labels: ['Defaulters', 'Collection', 'Projections'],
      datasets: [
        {
          data: [300, 50, 100],
          backgroundColor: ['#FF6384', '#36A2EB', '#FFCE56'],
          hoverBackgroundColor: ['#FF6384', '#36A2EB', '#FFCE56'],
        },
      ],
    };

    this.options = {
      title: {
        display: true,
        text: 'Defaulters',
        fontSize: 16,
      },
      legend: {
        display: false,
        position: 'bottom',
      },
    };
  }
}
