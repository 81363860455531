// import { Component, OnInit } from '@angular/core';

// @Component({
//   selector: 'tausi-form-reset-password',
//   templateUrl: './form-reset-password.component.html',
//   styleUrls: ['./form-reset-password.component.scss']
// })
// export class FormResetPasswordComponent implements OnInit {

//   constructor() { }

//   ngOnInit(): void {
//   }

// }


import { Component, OnInit } from '@angular/core';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { InteractionService } from '../../services/interaction.service';
import { UserUaaService } from 'src/app/user-management/services/user-uaa.service';


export interface PasswordPolicy {
  id: string;
  name: string;
  status: boolean;
  value: number;

}

@Component({
  selector: 'tausi-form-reset-password',
  templateUrl: './form-reset-password.component.html',
  styleUrls: ['./form-reset-password.component.scss'],
})
export class FormResetPasswordComponent implements OnInit {
  public resetForm!: FormGroup;
  public nin: any;
  public token: any;
  public message: any;
  public resetToken!: string;

  public passwordPolicyArray!: any[];


  constructor(
    private _formBuilder: FormBuilder,
    private _ref: DynamicDialogRef,
    private _config: DynamicDialogConfig,
    private _uaaSvc: UserUaaService,
    private _interactionSvc: InteractionService
  ) {
    this.nin = this._config.data.nin;
    this.token = this._config.data.token;
    this.message = this._config.data.message;
  }

  ngOnInit(): void {
    this.resetForm = this.initForm();

    // setTimeout(() => {
    //   this.verifyResetPasswords();
    // }, 0);

    this.getPassowrdPolicy()
  }

  initForm(): FormGroup {
    return this._formBuilder.group({
      password: ['', Validators.required],
      confirmPassword: ['', Validators.required],
    });
  }

  // /**
  //  * verify reset password
  //  */
  // verifyResetPasswords(): void {
  //   const payload = {
  //     nin: this.nin,
  //     token: this.token,
  //   };

  //   this._uaaSvc.verifyRequestPasswordReset(payload).subscribe((response) => {
  //     if (response.statusCode === '21000') {
  //       this.resetToken = response.data.item.resetPasswordToken;
  //     }
  //   });
  // }

  getPassowrdPolicy(): void {

    this.passwordPolicyArray = [];
    this._uaaSvc.getPassowrdPolicy().subscribe((response) => {

      console.log("getPassowrdPolicy response ", response);

      if (response.statusCode === '21000') {


        let resObj = response?.data?.item;

        // let passwordPolicyArray = [];

        // maxLength
        let passwordPolicyData1 = {} as PasswordPolicy;

        passwordPolicyData1.id = "maxLength";
        passwordPolicyData1.status = false;
        passwordPolicyData1.value = resObj?.maxLength;
        passwordPolicyData1.name = "Maximum length of "+resObj?.maxLength ;
      this.passwordPolicyArray.push(passwordPolicyData1);

      // minLength
      let passwordPolicyData2 = {} as PasswordPolicy;
      passwordPolicyData2.id = "minLength";
      passwordPolicyData2.value = resObj?.minLength;
      passwordPolicyData2.status = false;
      passwordPolicyData2.name = "Minimum length of "+resObj?.minLength ;
      this.passwordPolicyArray.push(passwordPolicyData2);
      // specialCharacters
      let passwordPolicyData3 = {} as PasswordPolicy;
      passwordPolicyData3.id = "specialCharacters";
      passwordPolicyData3.value = resObj?.specialCharacters;
      passwordPolicyData3.status = false;
      passwordPolicyData3.name = "Contain atleast "+resObj?.specialCharacters+" special characters" ;
      this.passwordPolicyArray.push(passwordPolicyData3);

      // alphabeticCharacters
      let passwordPolicyData4 = {} as PasswordPolicy;
      passwordPolicyData4.id = "alphabeticCharacters";
      passwordPolicyData4.value = resObj?.alphabeticCharacters;
      passwordPolicyData4.status = false;
      passwordPolicyData4.name = "Contain atleast "+resObj?.alphabeticCharacters+" alphabetic characters" ;
      this.passwordPolicyArray.push(passwordPolicyData4);
      // uppercaseCharacters
      let passwordPolicyData5 = {} as PasswordPolicy;
      passwordPolicyData5.id = "uppercaseCharacters";
      passwordPolicyData5.value = resObj?.uppercaseCharacters;
      passwordPolicyData5.status = false;
      passwordPolicyData5.name = "Contain atleast "+resObj?.uppercaseCharacters+" uppercase characters" ;
      this.passwordPolicyArray.push(passwordPolicyData5);
      // lowercaseCharacters
      let passwordPolicyData6 = {} as PasswordPolicy;
      passwordPolicyData6.id = "lowercaseCharacters";
      passwordPolicyData6.value = resObj?.lowercaseCharacters;
      passwordPolicyData6.status = false;
      passwordPolicyData6.name = "Contain atleast "+resObj?.lowercaseCharacters+" lowercase characters" ;
      this.passwordPolicyArray.push(passwordPolicyData6);
      // identicalAdjacentCharacters
      let passwordPolicyData7 = {} as PasswordPolicy;
      passwordPolicyData7.id = "identicalAdjacentCharacters";
      passwordPolicyData7.value = resObj?.identicalAdjacentCharacters;
      passwordPolicyData7.status = false;
      passwordPolicyData7.name = "Contain atleast "+resObj?.identicalAdjacentCharacters+" identical adjacent characters" ;
      this.passwordPolicyArray.push(passwordPolicyData7);


      }else{
        this._interactionSvc.dialog(
          `Error getting Reset password policy, ${response?.description}`,
          'pi-info-circle waring'
        );
      }
    });
  }

  passwordModelChanged(newPass: any) {
    // do something with new value

// maxLength
let maxLengthObjIndex : any= this.passwordPolicyArray.findIndex((obj => obj.id == "maxLength"));
if(newPass?.length < this.passwordPolicyArray[maxLengthObjIndex].value){
  this.passwordPolicyArray[maxLengthObjIndex].status = true
}else{
  this.passwordPolicyArray[maxLengthObjIndex].status = false
}


// minLength

let minLengthObjIndex : any= this.passwordPolicyArray.findIndex((obj => obj.id == "minLength"));
if(newPass?.length > this.passwordPolicyArray[minLengthObjIndex].value){
  this.passwordPolicyArray[minLengthObjIndex].status = true
}else{
  this.passwordPolicyArray[minLengthObjIndex].status = false
}

// specialCharacters

let specialCharactersObjIndex : any= this.passwordPolicyArray.findIndex((obj => obj.id == "specialCharacters"));
let specialCharactersFounnd = newPass.match(/[@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/g);
if(specialCharactersFounnd?.length >= this.passwordPolicyArray[specialCharactersObjIndex].value){
  this.passwordPolicyArray[specialCharactersObjIndex].status = true
}else{
  this.passwordPolicyArray[specialCharactersObjIndex].status = false
}


// alphabeticCharacters

let alphabeticCharactersObjIndex : any= this.passwordPolicyArray.findIndex((obj => obj.id == "alphabeticCharacters"));
let alphabeticCharactersFounnd = newPass.match(/[a-zA-Z]/g)
if(alphabeticCharactersFounnd?.length >= this.passwordPolicyArray[alphabeticCharactersObjIndex].value){
  this.passwordPolicyArray[alphabeticCharactersObjIndex].status = true
}else{
  this.passwordPolicyArray[alphabeticCharactersObjIndex].status = false
}

// uppercaseCharacters

let uppercaseCharactersObjIndex : any= this.passwordPolicyArray.findIndex((obj => obj.id == "uppercaseCharacters"));
let uppercaseCharactersFounnd = newPass.match(/[A-Z]/g)
if(uppercaseCharactersFounnd?.length >= this.passwordPolicyArray[uppercaseCharactersObjIndex].value){
  this.passwordPolicyArray[uppercaseCharactersObjIndex].status = true
}else{
  this.passwordPolicyArray[uppercaseCharactersObjIndex].status = false
}
// lowercaseCharacters

let lowercaseCharactersObjIndex : any= this.passwordPolicyArray.findIndex((obj => obj.id == "lowercaseCharacters"));
let lowercaseCharactersFounnd = newPass.match(/[a-z]/g)
if(lowercaseCharactersFounnd?.length >= this.passwordPolicyArray[lowercaseCharactersObjIndex].value){
  this.passwordPolicyArray[lowercaseCharactersObjIndex].status = true
}else{
  this.passwordPolicyArray[lowercaseCharactersObjIndex].status = false
}

// identicalAdjacentCharacters
let identicalAdjacentCharactersObjIndex : any= this.passwordPolicyArray.findIndex((obj => obj.id == "identicalAdjacentCharacters"));

let remIdentAdjCharString = this.removeIdenticalAdjacentCharacters(newPass)

let CountDifferenceInValue: number = (newPass?.length - remIdentAdjCharString?.length)/2

if(CountDifferenceInValue >= this.passwordPolicyArray[identicalAdjacentCharactersObjIndex].value){
  this.passwordPolicyArray[identicalAdjacentCharactersObjIndex].status = true
}else{
  this.passwordPolicyArray[identicalAdjacentCharactersObjIndex].status = false
}


}


 removeIdenticalAdjacentCharacters(newPassword: any): String
{

    var st = [];

    var i = 0;

    while (i < newPassword.length)
    {

        if (st.length==0 || newPassword[i] != st[st.length-1])
        {
            st.push(newPassword[i]);
            i++;
        }

        else
        {
            st.pop();
            i++;
        }
    }

    if (st.length==0)
    {
        return (newPassword);
    }

    else
    {
        var short_string = "";
        while(st.length!=0)
        {
            short_string = st[st.length-1] +
                           short_string;
            st.pop();
        }
        return (short_string);
    }
}

validatePasswordPolictIfOkay(): boolean{

  for(let i=0; i<this.passwordPolicyArray.length; i++){

    if(this.passwordPolicyArray[i].status === false){

      return true;

    }

  }




return false;
}
  /**
   * change password
   * @param item
   */
  changePassword(item: any): void {
    let _value = this.resetForm.value;
    if (_value.password !== _value.confirmPassword) {
      console.log('the password do not match');

      this._interactionSvc.dialog(
        `Error Changing Password, the password and Confirm Password do not match`,
        'pi-info-circle waring'
      );
      return;
    }

    const payload = {
      nin: this.nin,
      newPassword: item.password,
      token: this.token,
    };

    this._uaaSvc.resetPassword(payload).subscribe((response) => {
      if (response.statusCode === '21000') {
        this.close();
        this._interactionSvc.dialog(
          'Successfully changed your password, please log in using the new password you just created',
          'pi-info-circle success'
        );
      }else{
        this._interactionSvc.dialog(
          `Error Changing Password, ${response?.description}`,
          'pi-info-circle waring'
        );
      }
    });
  }

  ngOnDestroy(): void {
    if (this._ref) {
      this._ref.close();
    }
  }

  close(): void {
    this._ref.close();
  }
}
