import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'tausi-slider',
  templateUrl: './slider.component.html',
  styleUrls: ['./slider.component.scss'],
})
export class SliderComponent implements OnInit {
  slides: string[] = ['assets/imgs/tausi.jpeg'];

  constructor() {}

  ngOnInit() {}
}
