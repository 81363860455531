<div class="px-2 py-2">
  <div *ngIf="isQuestion">
    <div class="inner-card">
      <p>
        You are required to answer correctly three (3) or more questions out of
        five (5) questions
      </p>
    </div>
    <div class="answer-status" *ngIf="answeredQuestions.length > 0">
      <div class="answer-contents" *ngFor="let item of answeredQuestions">
        <div class="answer">{{ item.answer }}</div>
        <i *ngIf="item.status" class="pi pi-check-circle icon-lg success"></i>
        <i *ngIf="!item.status" class="pi pi-times-circle icon-lg danger"></i>
      </div>
    </div>
    <div class="form-container">
      <h5 class="mb-4 text-muted">Questions</h5>
      <div *ngIf="isQuestion" class="mb-4">
        <h6>{{ en_nidaQuestion }}</h6>
        <h6 class="text-muted">{{ sw_nidaQuestion }}</h6>
      </div>
      <form [formGroup]="questionform" autocomplete="off">
        <div class="p-grid p-fluid">
          <div class="p-field p-col-12">
            <span class="p-float-label">
              <input
                pTooltip="{{ en_nidaQuestion }}"
                tooltipPosition="top"
                pInputText
                id="answerDetailInput"
                name="tin"
                formControlName="answerDetail"
              />
              <label for="answerDetailInput">Question One</label>
            </span>
          </div>
        </div>
      </form>
    </div>
  </div>

  <div class="px-0 mt-2" *ngIf="!isQuestion">
    <div class="px-2">
      <form [formGroup]="validateform" autocomplete="off" novalidate>
        <div class="p-grid p-fluid">
          <div class="p-col-12">
            <div class="p-formgroup-inline">
              <h5 class="text-muted p-mb-2" style="margin-right: 2rem">
                Choose Taxpayer Type
              </h5>
              <div class="p-field-checkbox" *ngFor="let category of categories">
                <p-radioButton
                  formControlName="taxpayer_category"
                  name="taxpayer_category"
                  value="{{ category.code }}"
                  inputId="{{ category.code }}"
                  (click)="switchCategory(category.code)"
                ></p-radioButton>
                <label for="{{ category.code }}">{{
                  category.description
                }}</label>
              </div>
            </div>
          </div>
        </div>

        <div class="p-grid p-fluid">
          <div class="p-field p-col-12" *ngIf="errorMessage.length > 0">
            <p-messages
              [(value)]="errorMessage"
              [enableService]="false"
              [closable]="true"
            ></p-messages>
          </div>
          <div class="p-field p-col-12">
            <span class="p-float-label" *ngIf="isLegal">
              <p-inputMask
                pTooltip="Enter TIN number correctly"
                tooltipPosition="top"
                id="tinInput"
                mask="999-999-999"
                name="tin"
                formControlName="tinNumber"
              ></p-inputMask>
              <label for="tinInput">TIN Number</label>
            </span>

            <span class="p-float-label" *ngIf="isIndividual">
              <p-inputMask
                pTooltip="Enter NIN number correctly"
                tooltipPosition="top"
                id="ninInput"
                mask="99999999-99999-99999-99"
                name="nin"
                formControlName="ninNumber"
              ></p-inputMask>
              <label for="ninInput">NIN Number</label>
            </span>
          </div>
        </div>
      </form>
    </div>
  </div>

  <div fxLayout="row" fxLayoutAlign="end center">
    <div fxLayout="row" fxLAyoutAlign="space-between center">
      <button
        *ngIf="isQuestion"
        (click)="close()"
        pButton
        class="p-button-text mx-3"
        icon="pi pi-arrow-left"
        iconPos="left"
        label="Go Back"
      ></button>
      <button
        [disabled]="questionform.invalid"
        *ngIf="isQuestion"
        (click)="questionResponse(questionform.value)"
        pButton
        class="p-button"
        icon="pi pi-arrow-right"
        iconPos="right"
        label="Next"
      ></button>

      <button
        *ngIf="!isQuestion"
        [disabled]="validateform.invalid"
        type="button"
        [loading]="isLoading"
        (click)="search()"
        label="Search"
        icon="pi pi-search"
        iconPos="left"
        pButton
      ></button>
    </div>
  </div>
</div>
