import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import {
  TaxPayer,
  TaxpayerAddress,
} from '../../agent-management/models/taxpayer';

@Injectable({
  providedIn: 'root',
})
export class TaxpayerManagementService {
  /**
   *
   */
  private _uri: string = `${environment.apiUrl}${environment.endPoints.tausi_integration}`;
  private base_uri: string = `${environment.apiUrl}${environment.endPoints.tax_payer_service}`;
  constructor(private _httpSvc: HttpClient) {}

  /**
   * taxpayer registration
   * @param payload
   * @returns
   */
  storeTaxpayer(payload: TaxPayer): Observable<any> {
    return this._httpSvc.post<TaxPayer>(`${this.base_uri}/api/v1`, payload);
  }

  /**
   * update taxpayer details
   * @param id
   * @param payload
   * @returns
   */
  updateTaxpayer(id: number, payload: TaxPayer): Observable<any> {
    return this._httpSvc.put<TaxPayer>(
      `${this.base_uri}/api/v1?id=${id}`,
      payload
    );
  }

  /**
   * store taxpayer address
   * @param payload
   * @returns
   */
  storeAddress(payload: TaxpayerAddress): Observable<any> {
    return this._httpSvc.post<TaxPayer>(
      `${this.base_uri}/api/v1/address`,
      payload
    );
  }

  /**
   * get all categories
   * @returns
   */
  fetchTaxpayerCategories(): Observable<any> {
    return this._httpSvc.get<any[]>(
      `${this.base_uri}/api/v1/setup/taxpayer-category`
    );
  }

  /**
   * search taxpayer by nin
   * @param nin
   * @returns
   */
  searchTaxpayerByNIN(nin: string): Observable<any> {
    return this._httpSvc.get<TaxPayer>(`${this.base_uri}/api/v1?nin=${nin}`);
  }

  /**
   * search taxpayer by tin
   * @param tin
   * @returns
   */
  searchTaxpayerByTIN(tin: string): Observable<any> {
    return this._httpSvc.get<TaxPayer>(`${this.base_uri}/api/v1?tin=${tin}`);
  }

  /**
   * get OTP
   */
  getOneTimePassword(payload: any): Observable<any> {
    return this._httpSvc.post<any>(`${this._uri}/api/v1/nin-otp`, payload);
  }
}
