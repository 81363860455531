import { Action, NgxsOnInit, Selector, State, StateContext } from '@ngxs/store';
import {
  LoadAllCouncils,
  LoadAllUsers,
  LoadCurrentUser,
  LoadOnlineOffline,
  LoadUsageSummary,
  LogoutCurrentUser,
} from './root.actions';

import { Injectable } from '@angular/core';
import { tap } from 'rxjs/operators';
import { PosManagementService } from '../shared/services/pos-management.service';
import { UserUaaService } from '../user-management/services/user-uaa.service';
import { RootStateModel } from './root.models';

@State<RootStateModel>({
  name: 'root',
  defaults: {
    currentFinancialYear: '',
    administrativeArea: '',
    currentUser: null,
    username: '',
    loading: false,
    users: [],
    cashOnHand: {},
    lastSeen: [],
    collectorCashOnHand: {},
    posRegistration: {},
    posUsageSummary: [],
    onlineOffline: [],
    posCollection: {},
  },
})
@Injectable({
  providedIn: 'root',
})
export class RootState implements NgxsOnInit {
  constructor(
    private _userSvc: UserUaaService,
    private _posSvc: PosManagementService
  ) {}

  @Selector()
  static username(state: RootStateModel) {
    return state.username;
  }

  @Selector()
  static currentUser(state: RootStateModel) {
    return state.currentUser;
  }

  @Selector()
  static onlineOffline(state: RootStateModel) {
    return state.onlineOffline;
  }

  @Selector()
  static usageSummary(state: RootStateModel) {
    return state.posUsageSummary;
  }

  @Selector()
  static currentFinancialYear(state: RootStateModel) {
    return state.currentFinancialYear;
  }

  @Selector()
  static loadUsers(state: RootStateModel) {
    return state.users;
  }

  @Action(LoadAllCouncils)
  getAllCouncils(ctx: StateContext<RootStateModel>, action: LoadAllCouncils) {
    const state = ctx.getState();
    this._userSvc.getUsersListBasedOnUserAreaLevel(action.payload).pipe(
      tap((response) => {
        console.log(response);
      })
    );
  }

  @Action(LoadAllUsers)
  getUsers(ctx: StateContext<RootStateModel>, action: LoadAllUsers) {
    const state = ctx.getState();
    this._userSvc
      .getUsersListBasedOnUserAreaLevel(action.payload)
      .pipe(tap((response) => {}));
  }

  @Action(LoadCurrentUser)
  loadCurrentUser(ctx: StateContext<RootStateModel>, action: LoadCurrentUser) {
    ctx.dispatch(new LoadOnlineOffline());
    ctx.dispatch(new LoadUsageSummary());
    ctx.patchState({
      username: action.payload.username,
      currentUser: action.payload,
      currentFinancialYear: action.payload.currentFinancialYear,
    });
  }

  @Action(LogoutCurrentUser)
  logoutCurrentUser(ctx: StateContext<RootStateModel>) {
    const state = ctx.getState();
    ctx.setState({
      ...state,
      currentUser: {},
      currentFinancialYear: '',
    });
  }

  @Action(LoadOnlineOffline)
  loadOnlineOffline(ctx: StateContext<RootStateModel>) {
    this._posSvc.fetchPoSOnlineStatus().subscribe((response) => {
      const state = ctx.getState();
      let chartdata = [];
      if (response.statusCode === '21000') {
        const items = response.data.item;
        for (let property in items) {
          chartdata.push({
            description: property,
            number: items[property],
          });
        }
        ctx.patchState({
          onlineOffline: chartdata,
        });
      }
    });
  }

  @Action(LoadUsageSummary)
  loadusageSummary(ctx: StateContext<RootStateModel>) {
    this._posSvc.fetchPoSActiveSummary().subscribe((response) => {
      if (response.statusCode === '21000') {
        ctx.patchState({
          posUsageSummary: response.data.item,
        });
      }
    });
  }

  ngxsOnInit(ctx: StateContext<any>): void {}
}
