<p-panel>
  <p-header>
    <h2>
      {{ header }}
      <span *ngIf="referenceTime != ''">( As of {{ referenceTime }})</span>
    </h2>
  </p-header>
  <div class="p-p-0" [ngStyle]="{ height: height }">
    <p-table
      [paginator]="paginator"
      [rows]="10"
      [columns]="cols"
      [value]="data"
      responsiveLayout="scroll"
      styleClass="p-datatable-striped"
    >
      <ng-template pTemplate="header" let-columns>
        <tr>
          <th style="width: 3%"></th>
          <th *ngFor="let column of columns" class="{{ column.align }}">
            {{ column.header }}
          </th>
        </tr>
      </ng-template>
      <ng-template
        pTemplate="body"
        let-rowData
        let-columns="columns"
        let-index="rowIndex"
      >
        <tr>
          <td style="width: 3%">{{ index + 1 }}</td>
          <td *ngFor="let column of columns" class="{{ column.align }}">
            <span *ngIf="column?.type == 'number'">{{
              rowData[column.field] | number
            }}</span>
            <span *ngIf="column?.type != 'number'">
              {{
                column.data
                  ? (rowData[column.field] | date : column.format)
                  : rowData[column.field]
              }}
            </span>
          </td>
        </tr>
      </ng-template>
      <ng-template
        pTemplate="footer"
        let-columns="columns"
        *ngIf="category != ''"
      >
        <tr>
          <td [attr.colspan]="nColumns">
            <button
              pButton
              class="p-button-text p-button-sm p-py-0"
              label="View All"
              icon="pi pi-eye"
              iconPos="right"
              (click)="viewAllReport()"
            ></button>
          </td>
        </tr>
      </ng-template>
    </p-table>
  </div>
</p-panel>
