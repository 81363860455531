import { Injectable } from '@angular/core';
import { NgxPermissionsService } from 'ngx-permissions';
import { UserStorageService } from './user-storage.service';

@Injectable({
  providedIn: 'root',
})
export class PermissionFilteringService {
  public allUserPermissions: string[] = [];
  public itemsFiltered: any[] = [];

  constructor(
    private _userStorageService: UserStorageService,
    private _permissionsService: NgxPermissionsService
  ) {}

  filterByNodes(objToFilter: any) {
    // load permissions
    this.allUserPermissions = this._userStorageService.getPermissions();
    this._permissionsService.loadPermissions(this.allUserPermissions);
    const tausiMenuItemFilter = (objToFilter: any) =>
      objToFilter
        .map((tausiMenuItem: any) =>
          tausiMenuItem.items
            ? {
                ...tausiMenuItem,
                items: tausiMenuItemFilter(tausiMenuItem.items),
              }
            : tausiMenuItem
        )
        .filter((tausiMenuItem: any) => {
          if (
            tausiMenuItem?.permissions &&
            Array.isArray(tausiMenuItem?.permissions) &&
            this.findCommonElement(
              this.allUserPermissions,
              tausiMenuItem?.permissions
            )
          ) {
            return true;
          } else {
            return false;
          }
        });

    this.itemsFiltered = tausiMenuItemFilter(objToFilter);

    return this.itemsFiltered;
  }

  // Function definition with passing two arrays
  findCommonElement(array1: any, array2: any) {
    for (let i = 0; i < array1.length; i++) {
      for (let j = 0; j < array2.length; j++) {
        if (array1[i] === array2[j]) {
          return true;
        }
      }
    }
    return false;
  }
}
