import { Component, OnInit } from '@angular/core';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {TranslateConfigService} from "../../../shared/services/translate.service";
import {TranslateService} from "@ngx-translate/core";
import {UserStorageService} from "../../../shared/services/user-storage.service";
import {LandSalesService} from "../../../shared/services/land-sales.service";
import {DynamicDialogConfig, DynamicDialogRef} from "primeng/dynamicdialog";
import {TaxpayerManagementService} from "../../../shared/services/taxpayer-management.service";
import {InteractionService} from "../../../shared/services/interaction.service";
import {ConfirmationService} from "primeng/api";
import {UserService} from "../../../shared/services/user.service";

@Component({
  selector: 'tausi-assign-plot',
  templateUrl: './assign-plot.component.html',
  styleUrls: ['./assign-plot.component.scss']
})
export class AssignPlotComponent implements OnInit {
  public dialogHeader!: string;
  public assigmentForm!: FormGroup;
  public buttonLabel: string="Assign Plot";
  public plotInfo!: any;
  public selectedNin: string='';
  public selectedTaxPayer!:any;
  public selectedCategory:string = 'OC001';

  categories: any[] = [];

  constructor(
    private _translateSvc: TranslateConfigService,
    private _formBuilder: FormBuilder,
    private _translate: TranslateService,
    private _storageSvc: UserStorageService,
    private _landSalesSvc: LandSalesService,
    private _config: DynamicDialogConfig,
    public _ref: DynamicDialogRef,
    public taxpayerManagementService: TaxpayerManagementService,
    public userService:UserService,
    private _interactionSvc: InteractionService,
    private _confirmationSvc: ConfirmationService,
  ) {
    this.plotInfo = this._config.data;
    this.dialogHeader = this._config.header!;
  }

  ngOnInit(): void {
    this.assigmentForm = this.initForm();
    setTimeout(() => {
      this.fetchClientCategory();
    }, 0);
  }

  initForm(): FormGroup {
    return this._formBuilder.group({
      name:['',Validators.required],
      phone_number:['',[Validators.required, Validators.pattern("^[0-9]*$"),Validators.minLength(10), Validators.maxLength(10)]],
      currentAddress:['',Validators.required],
      email:['',]
    });
  }

  keyPress(event: any) {
    const pattern = /[0-9\+\-\ ]/;
    let inputChar = String.fromCharCode(event.charCode);
    if (event.keyCode != 8 && !pattern.test(inputChar)) {
      event.preventDefault();
    }
  }

  close(){
    this._ref.close();
  }

  assign(){
    let  payload = {
      applicantId: this.validateText(this.selectedNin),
      applicantName: this.assigmentForm.get('name')?.value,
      phoneNumber: this.assigmentForm.get('phone_number')?.value,
      email: this.assigmentForm.get('email')?.value,
      currentAddress:this.assigmentForm.get('currentAddress')?.value,
      occupierTypeCode: this.selectedCategory,
      landPlots: [this.plotInfo].map(p => (p.landPlotId))
    };
    this._ref.close(payload);
  }

  searchClientDetails(){
    this.selectedTaxPayer = undefined;
    this.assigmentForm.reset();
    if(this.selectedCategory == 'OC001'){
      this.searchByNin();
    }else{
      this.searchByTin();
    }
  }

  searchByNin(){
    this.taxpayerManagementService.searchTaxpayerByNIN(this.validateText(this.selectedNin)).subscribe((response) => {
      if (response.statusCode === '21000') {
        this.selectedTaxPayer = response.data.item;
        let name = this.selectedTaxPayer.firstname + ' ' + this.selectedTaxPayer.middlename + ' '+  this.selectedTaxPayer.surname;
        this.assigmentForm.get('name')?.setValue(name);
      } else {
        this._interactionSvc.dialog(
          response.description,
          'pi-exclamation-triangle warning'
        );
      }
    });
  }

  searchByTin(){
    const payload = { tin: this.validateText(this.selectedNin) };
    //search tin
    this.userService.taxpayerByTin(payload).subscribe((response) => {
      if (response.statusCode === '21000') {
        this.selectedTaxPayer = response.data.item;
      } else {
        this._interactionSvc.dialog(
          response.description,
          'pi-exclamation-triangle warning'
        );
      }
    });
  }

  /**
   * get Occupy Types
   */
  fetchClientCategory(): void {
    this._landSalesSvc.getOccupyTypes().subscribe((response) => {
      if (response.statusCode === '21000') {
        this.categories = response.data.itemList;
        console.log(this.categories);
      }
    });
  }

  onSearchChange(){
    this.selectedTaxPayer = undefined;
    this.assigmentForm.reset();
  }

  clearData(action:any){
    this.selectedTaxPayer = undefined;
    this.assigmentForm.reset();
    this.selectedNin = '';
  }

  confirm(message: string, icon: string) {
    this._confirmationSvc.confirm({
      key: 'confirm',
      icon: `pi ${icon}`,
      header: 'Information',
      message: message,
      accept: () => {},
    });
  }
  validateText(nin:string){
    return nin.replace(/-/g, '');
  }
}
