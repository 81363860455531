<div *ngIf="data.length > 0">
  <div class="p-grid p-jc-center">
    <div class="p-col">
      <div class="header">
        Tittle: {{ reportTitle }} <br />
        <span
          >From {{ startDate | date: "mediumDate" }} To
          {{ endDate | date: "mediumDate" }}</span
        >
      </div>
    </div>
  </div>

  <div class="p-grid p-jc-start" *ngIf="subTitle.length > 0">
    <div class="p-col-2">
      <div class="sub-header" *ngFor="let i of subTitle">
        {{ i.field }} :
        <span>{{ i.value | uppercase }}</span>
      </div>
    </div>
  </div>

  <p-table [columns]="cols" [value]="data" responsiveLayout="scroll">
    <ng-template pTemplate="header" let-columns>
      <tr>
        <th style="width: 5%">SN</th>
        <th *ngFor="let col of columns" class="{{ col.align }}">
          {{ col.name | uppercase }}
        </th>
      </tr>
    </ng-template>
    <ng-template
      pTemplate="body"
      let-rowData
      let-columns="columns"
      let-index="rowIndex"
    >
      <tr>
        <td>{{ index + 1 }}</td>
        <td *ngFor="let col of columns" class="{{ col.align }}">
          {{ rowData[col.field] | uppercase }}
        </td>
      </tr>
    </ng-template>
    <ng-template pTemplate="footer" let-columns>
      <tr>
        <td>&nbsp;</td>
        <td *ngFor="let col of columns" class="{{ col.align }}">
          <span *ngIf="rowFooter">
            {{ rowFooter[col.field] | uppercase }}
          </span>
        </td>
      </tr>
    </ng-template>
  </p-table>
</div>
