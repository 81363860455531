import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { Component, OnInit } from '@angular/core';
import { pdfDefaultOptions } from 'ngx-extended-pdf-viewer';
@Component({
  selector: 'tausi-pdf-viewer',
  templateUrl: './pdf-viewer.component.html',
  styleUrls: ['./pdf-viewer.component.scss'],
})
export class PdfViewerComponent implements OnInit {
  public init!: string;
  public base64Data!: string;
  public formatedBase64file!: string;
  public item: any;
  public fileType: any;
  public zoom: any;
  public isPdf!: boolean;
  public isBase64!: boolean;
  public attachmentFile!: string;

  constructor(
    private _config: DynamicDialogConfig,
    private _ref: DynamicDialogRef
  ) {
    pdfDefaultOptions.assetsFolder = 'bleeding-edge';

    this.isPdf = false;
    this.isBase64 = false;
    this.zoom = '100%';
    this.init = 'data:image/png;base64,';
    this.item = this._config.data.contract;
    this.attachmentFile = this._config.data.attachment;
    this.fileType = this._config.data.type;
  }

  ngOnInit(): void {
    if (this.fileType === 'base64') {
      this.isPdf = false;
      this.isBase64 = true;
      this.formatedBase64file = `${this.attachmentFile}`;
    } else {
      this.isPdf = true;
      this.isBase64 = false;
    }
  }

  closePreview(): void {
    this._ref.close();
  }
}
