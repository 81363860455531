import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'tausi-agent-details',
  templateUrl: './agent-details.component.html',
  styleUrls: ['./agent-details.component.scss'],
})
export class AgentDetailsComponent implements OnInit {
  @Input() nida!: any;
  constructor() {}

  ngOnInit(): void {
    console.log(this.nida);
  }
}
