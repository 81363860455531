<mat-list-item
  routerLink="{{ item.route }}"
  routerLinkActive="['active']"
  [ngStyle]="{ 'padding-left': depth * 12 + 'px' }"
  (click)="onItemSelected(item)"
  [ngClass]="{
    active: item.route ? router.isActive(item.route, true) : false,
    expanded: expanded
  }"
  class="menu-list-item"
>
  <mat-icon class="routeIcon">{{ item.iconName }}</mat-icon>
  <span translate>{{ item.displayName }}</span>
  <span
    fxFlex
    fxLayout="row"
    fxLayoutAlign="space-between center"
    *ngIf="item.children && item.children.length"
  >
    <span fxFlex></span>
    <mat-icon [@indicatorRotate]="expanded ? 'expanded' : 'collapsed'">
      expand_more
    </mat-icon>
  </span>
</mat-list-item>
<div *ngIf="expanded">
  <tausi-menu-item
    class="child-item"
    *ngFor="let child of item.children"
    [item]="child"
    [depth]="depth + 1"
  >
  </tausi-menu-item>
</div>
