import { Component, OnInit } from '@angular/core';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';

@Component({
  selector: 'tausi-registration-type',
  templateUrl: './registration-type.component.html',
  styleUrls: ['./registration-type.component.scss'],
})
export class RegistrationTypeComponent implements OnInit {
  public dialogHeader!: any;

  constructor(
    public _ref: DynamicDialogRef,
    private _config: DynamicDialogConfig
  ) {
    this.dialogHeader = this._config.header;
  }

  ngOnInit(): void {}

  ngOnDestroy(): void {
    if (this._ref) {
      this._ref.close();
    }
  }

  /**
   * see
   * @param code
   */
  chooseMethod(code: number): void {
    this._ref.close({ code: code, status: true });
  }

  previous(): void {
    this.ngOnDestroy();
  }

  close(): void {
    this.ngOnDestroy();
  }
}
