import { Injectable } from '@angular/core';
import { MessageService } from 'primeng/api';

@Injectable({
  providedIn: 'root',
})
export class ToastService {
  constructor(private _messageSvc: MessageService) {}

  info(message?: string) {
    this._messageSvc.add({
      key: 'toast',
      severity: 'info',
      summary: 'Message',
      detail: `${message}`,
    });
  }

  success(message?: string) {
    this._messageSvc.add({
      key: 'toast',
      severity: 'success',
      summary: 'Message',
      detail: `${message}`,
    });
  }

  warning(message?: string) {
    this._messageSvc.add({
      key: 'toast',
      severity: 'warn',
      summary: 'Message',
      detail: `${message}`,
    });
  }

  error(message?: string) {
    this._messageSvc.add({
      key: 'toast',
      severity: 'error',
      summary: 'Message',
      detail: `${message}`,
    });
  }
}
