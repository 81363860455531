<p-panel header="{{ options.title.text }}" [toggleable]="true">
  <ng-template pTemplate="icons">
    <button
      mat-icon-button
      class="p-panel-header-icon"
      (click)="menu.toggle($event)"
    >
      <mat-icon class="pi pi-ellipsis-h"></mat-icon>
    </button>
    <p-menu
      appendTo="body"
      #menu
      id="config_menu"
      [model]="items"
      [popup]="true"
    ></p-menu>
  </ng-template>
  <div class="p-p-4">
    <p-chart
      [options]="options"
      type="doughnut"
      [data]="data"
      width="100%"
      height="30vh"
    ></p-chart>
  </div>
</p-panel>
