import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { SignInOauthToken } from '../models/auth.interface';
import { Store } from '@ngxs/store';
import { clearState } from 'src/app/property-tax/store/property-dashboard.actions';

@Injectable({
  providedIn: 'root',
})
export class TokenStorageService {
  private tokenKey: string = environment.authStorageConfig.tokenKey;
  private userKey: string = environment.authStorageConfig.userKey;
  private rootState: string = environment.authStorageConfig.rootState;

  constructor(private _store: Store) {}

  signOut() {
    this.clearStorage();
    this.clearState();
  }

  public saveTokenObject(token: SignInOauthToken) {
    localStorage.removeItem(this.rootState);
    localStorage.removeItem(this.tokenKey);
    localStorage.setItem(this.tokenKey, JSON.stringify(token));
  }

  public getTokenObject(): SignInOauthToken {
    let retrievedSignInOauthTokenObj = localStorage.getItem(this.tokenKey)!;
    return JSON.parse(retrievedSignInOauthTokenObj);
  }

  public removeToken(): boolean {
    if (localStorage.getItem(this.tokenKey) === null) return false;
    localStorage.removeItem(this.tokenKey);
    return true;
  }

  public clearStorage(): void {
    localStorage.clear();
  }

  // more implementation for user
  clearState() {
    this._store.dispatch(new clearState());
  }
}
