import { Injectable } from '@angular/core';
import { CanLoad, Router } from '@angular/router';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root',
})
export class AuthLazyGuardService implements CanLoad {
  constructor(public _authService: AuthService, public router: Router) {}

  /**
   *
   * @returns boolean
   */
  canLoad(): boolean {
    if (!this._authService.isAuthenticated()) {
      this.router.navigate(['signin']);
      return false;
    }
    return true;
  }
}
