<div>
  <div class="row mb-3">
    <div class="col-md-12">
      <div class="top-section">
        <div class="heading">
          <p class="section-title">Personal Information</p>
        </div>
        <div class="section-content">
          <div class="details bg">
            <table>
              <tr>
                <td class="text-600">NIN Number</td>
                <td class="text-500"><span>:</span>{{ nida?.nin }}</td>
              </tr>
              <tr>
                <td class="text-600">First Name</td>
                <td class="text-500"><span>:</span>{{ nida?.firstname }}</td>
              </tr>
              <tr>
                <td class="text-600">Middle Name</td>
                <td class="text-500"><span>:</span>{{ nida?.middlename }}</td>
              </tr>
              <tr>
                <td class="text-600">Last Name</td>
                <td class="text-500"><span>:</span> {{ nida?.surname }}</td>
              </tr>
              <tr>
                <td class="text-600">Birth Date</td>
                <td class="text-500"><span>:</span> {{ nida?.dateofbirth }}</td>
              </tr>
              <tr>
                <td class="text-600">Gender</td>
                <td class="text-500"><span>:</span> {{ nida?.sex }}</td>
              </tr>
            </table>
          </div>

          <div class="profile-img">
            <img
              *ngIf="nida != null"
              src="data:image/png;base64,{{ nida?.photo }}"
              alt=""
            />
            <img
              *ngIf="nida == null"
              src="assets/imgs/placeholder.jpg"
              alt=""
            />
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="row mt-2">
    <div class="col-md-6">
      <div class="heading">
        <p class="section-title">Contact Information</p>
        <div class="details bg">
          <table>
            <tr>
              <td class="text-600">Mobile Number</td>
              <td class="text-500"><span>:</span>{{ nida?.mobilephone }}</td>
            </tr>
            <tr>
              <td class="text-600">Postal Address</td>
              <td class="text-500"><span>:</span>{{ nida?.postaladdress }}</td>
            </tr>
            <tr>
              <td class="text-600">Postal Code</td>
              <td class="text-500">
                <span>:</span>{{ nida?.residentpostcode }}
              </td>
            </tr>
            <tr>
              <td class="text-600">Fax Number</td>
              <td class="text-500"><span>:</span> {{ nida?.fax }}</td>
            </tr>
            <tr>
              <td class="text-600">Email address</td>
              <td class="text-500"><span>:</span> {{ nida?.emailaddress }}</td>
            </tr>
          </table>
        </div>
      </div>
    </div>

    <div class="col-md-6">
      <div class="heading">
        <p class="section-title">Address Information</p>
        <div class="details bg" fxFill>
          <table>
            <tr>
              <td class="text-600">Region</td>
              <td class="text-500"><span>:</span>{{ nida?.residentregion }}</td>
            </tr>
            <tr>
              <td class="text-600">District</td>
              <td class="text-500">
                <span>:</span>{{ nida?.residentdistrict }}
              </td>
            </tr>
            <tr>
              <td class="text-600">Ward</td>
              <td class="text-500"><span>:</span>{{ nida?.residentward }}</td>
            </tr>
            <tr>
              <td class="text-600">Street</td>
              <td class="text-500">
                <span>:</span> {{ nida?.residentstreet }}
              </td>
            </tr>
            <tr>
              <td class="text-600">Village</td>
              <td class="text-500">
                <span>:</span> {{ nida?.residentvillage }}
              </td>
            </tr>
          </table>
        </div>
      </div>
    </div>
  </div>
</div>
