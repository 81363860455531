import { Component, OnDestroy, OnInit } from '@angular/core';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';

@Component({
  selector: 'tausi-tin-info',
  templateUrl: './tin-info.component.html',
  styleUrls: ['./tin-info.component.scss'],
})
export class TinInfoComponent implements OnInit, OnDestroy {
  public tin: any;
  public tinNumber!: string;

  constructor(
    private _ref: DynamicDialogRef,
    private _config: DynamicDialogConfig
  ) {
    this.tinNumber = this._config.data.tinNumber;
    this.tin = this._config.data.payload;
  }

  ngOnInit(): void {}

  continue(): void {
    this._ref.close({ tin: this.tinNumber });
  }

  close(): void {
    this.ngOnDestroy();
  }

  ngOnDestroy(): void {
    if (this._ref) {
      this._ref.close();
    }
  }
}
