import { Component, OnInit } from '@angular/core';
import {
  DialogService,
  DynamicDialogConfig,
  DynamicDialogRef,
} from 'primeng/dynamicdialog';

@Component({
  selector: 'tausi-terms-conditions',
  templateUrl: './terms-conditions.component.html',
  styleUrls: ['./terms-conditions.component.scss'],
})
export class TermsConditionsComponent implements OnInit {
  public termsCondition: any;
  public dialogHeader!: any;
  public tinDetail!: any;
  public accepted!: boolean;

  constructor(
    public _ref: DynamicDialogRef,
    private _config: DynamicDialogConfig,
    public _dialogSvc: DialogService
  ) {
    this.dialogHeader = this._config.header;
    this.termsCondition = this._config.data.termsCondition;
  }

  ngOnInit(): void {}

  closeDialog() {
    this._ref.close();
  }

  acceptHandle(event: any): void {
    this.accepted = event.checked;
  }
  searchTaxpayerTin(): void {
    this._ref.close({ accept: true });
  }

  addIndividual() {
    const payload = {
      nin: this.tinDetail.nin,
      tin: String(this.tinDetail.tin),
      ninName: this.tinDetail.lastName,
      tinName: this.tinDetail.taxpayerName,
      displayName: this.tinDetail.taxpayerName,
      category: 'Individual',
      phone: this.tinDetail.mobile,
      email: this.tinDetail.email,
      default: false,
      tinDetail: this.tinDetail,
    };
  }
}
