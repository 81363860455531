import { Component, Input, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'tausi-profile-photo',
  templateUrl: './profile-photo.component.html',
  styleUrls: ['./profile-photo.component.scss'],
})
export class ProfilePhotoComponent implements OnInit {
  /**
   * profile image
   * default height and width for profile image
   */
  @Input() urlData?: any;
  @Input() width!: string;
  @Input() height!: string;

  public sourcefile!: any;

  constructor(private _sanitizer: DomSanitizer) {}

  ngOnInit(): void {
    // this.sourcefile = `data:image/png;base64,${
    //   (this._sanitizer.bypassSecurityTrustResourceUrl(this.image) as any)
    //     .changingThisBreaksApplicationSecurity
    // }`;
    this.sourcefile = `data:image/png;base64,${this.urlData}`;
  }
}
