<div class="outer-container">
  <div class="inner-container">
    <div class="heading p-pt-5">
      <!-- <h5 translate>pri.choice.title</h5> -->
    </div>
    <div class="wrapper-tiles p-px-5">
      <div class="tile-box" (click)="chooseMethod(100100)">
        <div class="tile-details">
          <img src="assets/icons/questions.png" />
          <h5 translate>pri.choice.question_title</h5>
          <p translate>pri.choice.question_info</p>
        </div>
      </div>

      <div class="tile-box" (click)="chooseMethod(100200)">
        <div class="tile-details">
          <img src="assets/icons/otp.png" />
          <h5 translate>pri.choice.otp_title</h5>
          <p translate>pri.choice.otp_info</p>
        </div>
      </div>
    </div>
    <div class="p-px-5 p-pb-4 p-d-flex p-flex-row p-jc-between p-ai-center">
      <div>
        <button
          (click)="previous()"
          pButton
          class="p-button-text text-white"
          icon="pi pi-arrow-left"
          iconPos="left"
          label="{{ 'pri.choice.btn_previous' | translate }}"
        ></button>
      </div>
      <div>
        <button
          (click)="close()"
          pButton
          class="p-button-text"
          label="{{ 'pri.choice.btn_close' | translate }}"
        ></button>
      </div>
    </div>
  </div>
</div>
