import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import * as data from './data/tausi.json';

@Injectable({
  providedIn: 'root',
})
export class TausiService {
  public configuration: any = (data as any).default;
  constructor(private _httpClient: HttpClient) {}

  public loadConfiguration() {
    return this.configuration;
  }
}
