<p-panel header="{{ options?.title?.text }}" [toggleable]="true">
  <ng-template pTemplate="icons">
    <button
      mat-icon-button
      class="p-panel-header-icon"
      (click)="menu.toggle($event)"
    >
      <mat-icon class="pi pi-cog"></mat-icon>
    </button>
    <p-menu
      appendTo="body"
      #menu
      id="config_menu"
      [model]="items"
      [popup]="true"
    ></p-menu>
  </ng-template>

  <div class="p-p-0">
    <p-chart
      type="pie"
      [data]="data"
      [options]="options"
      width="100%"
      height="{{ height }}"
    >
    </p-chart>
  </div>
</p-panel>
