<div class="widget-card" [ngClass]="[widget?.bgColor]">
  <h1>
    <span *ngIf="widget?.type == 'currency'">
      {{ widget?.value | numberWithPrefix : "TZS " }}</span
    >
    <span *ngIf="widget?.type == 'number'"> {{ widget?.value | number }}</span>
  </h1>
  <div class="content-wrapper">
    <div class="icon">
      <img [src]="widget?.img" />
    </div>
    <div class="info">
      <h4 class="text-muted">{{ widget?.info }}</h4>
      <h5 class="default-text">{{ widget?.financialYear }}</h5>
    </div>
  </div>
</div>
